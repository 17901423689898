import { createSlice } from "@reduxjs/toolkit";
import { CheckEmail } from "store/middlewares/session/auth";

export const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState: { error: {} },
  reducers: {
    setErrorMessage: (state, { payload }) => {
      let message = payload.message;
      let field = payload.field;
      let tempObj = {};
      tempObj[`${field}`] = message;
      state.error = { ...state.error, ...tempObj };
    },
    resetErrorMessage: (state, action) => {
      if (action?.payload?.field) {
        let tempObj = state.error;
        delete tempObj[action.payload.field];
        state.error = tempObj;
      } else {
        state.error = {};
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(CheckEmail.rejected, (state, action) => {
        let message = action.payload.message;
        let field = action.payload.field;
        let tempObj = {};
        tempObj[`${field}`] = message;

        state.error = { ...state.error, ...tempObj };
      })
      .addCase(CheckEmail.fulfilled, (state, action) => {
        let tempObj = state.error;
        delete tempObj[action.payload.field];
        state.error = tempObj;
      });
  },
});

export const { setErrorMessage, resetErrorMessage } = errorMessageSlice.actions;

export default errorMessageSlice.reducer;
