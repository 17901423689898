import {
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "assets/images/SVG/search-normal.svg";
import MuiBox from "../MuiBox";

const Input = ({
  autoFocus = false,
  onChange,
  onBlur,
  type = "text",
  value,
  error,
  errorMsg,
  placeholder = "Search here",
  name,
  fullWidth = true,
  disabled = false,
  variant = "plain",
  onClick,
  bgColor = "#FFFFFF",
  icon,
}) => {
  return (
    <>
      <MuiBox
        component={"form"}
        onSubmit={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
            return false;
          }
        }}
      >
        <OutlinedInput
          autoFocus={autoFocus}
          className="searchInput"
          id={name}
          fullWidth={fullWidth}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          value={value}
          error={error}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          sx={{ backgroundColor: bgColor }}
          startAdornment={
            <>
              {icon && (
                <InputAdornment position="start">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      height: "43px",
                    }}
                  >
                    {icon}
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      ligh
                      flexItem
                      sx={{ backgroundColor: "inputBorder.main" }}
                    />
                  </div>
                </InputAdornment>
              )}
            </>
          }
          endAdornment={
            <>
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  sx={{
                    height: "45px",
                    width: "45px",
                    position: "relative",
                    right: "4px",
                    zIndex: "10",
                    backgroundColor: "background.dark",
                    borderRadius: "12px",
                    mr: "-15px",
                    "&:hover": { bgcolor: "#0e617e" },
                  }}
                  onClick={onClick}
                  edge="end"
                >
                  <img src={SearchIcon} alt={""} />
                </IconButton>
              </InputAdornment>
            </>
          }
          variant={variant}
        />
      </MuiBox>
      {error && (
        <Typography variant="baseFont" fontWeight={"bold"} color="error">
          {errorMsg}
        </Typography>
      )}
    </>
  );
};

export default Input;
