import { MuiBox } from "components/atoms";
import React from "react";

function FullScreenCustomLoader() {
  return (
    <MuiBox
      sx={{
        bgcolor: "#000000BF",
        height: "100%",
        width: "100%",
        zIndex: "999999999",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {/* <CustomLoader /> */}
      <MuiBox sx={{ mt: "20px", width: "100%", height: "100%" }}>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            height: "100%",
          }}
        >
          <MuiBox
            sx={{
              display: "inline-flex",
              width: "20px",
              height: "20px",
              bgcolor: "#ffffff",
              borderRadius: "15px",
            }}
            className="animate-bounce delay-1"
          ></MuiBox>
          <MuiBox
            sx={{
              display: "inline-flex",
              width: "20px",
              height: "20px",
              bgcolor: "#ffffff",
              borderRadius: "15px",
            }}
            className="animate-bounce delay-2"
          ></MuiBox>
          <MuiBox
            sx={{
              display: "inline-flex",
              width: "20px",
              height: "20px",
              bgcolor: "#ffffff",
              borderRadius: "15px",
            }}
            className="animate-bounce delay-3"
          ></MuiBox>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
}

export default FullScreenCustomLoader;
