import React from "react";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError.js";
import {
  changePasswordWeb,
  emailCheck,
  forgotPassword,
  forgotPasswordWeb,
  loginAdmin,
  loginWeb,
  logOutAdmin,
  logOutWeb,
  register,
  resetPassword,
  resetPasswordWeb,
  sendOtpWeb,
  validateToken,
  verifyOtp,
} from "store/services/session/auth.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "store/slices/common/toast.slice.js";
import LocalstorageService from "utils/localstorage-services.js";
import { closeDrawer, openDrawer } from "store/slices/common/drawer.slice";
import { SuccessModal } from "components/molecules";
import { uploadFile } from "store/services/utilities";
import {
  resetErrorMessage,
  setErrorMessage,
} from "store/slices/common/errorMessage.slice";

export const LoginAdminAction = createAsyncThunk(
  "login/loginAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await loginAdmin(payload);
      if (res.status === 1) {
        const { response } = res;
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ response });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const LoginWebAction = createAsyncThunk(
  "login/loginWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await loginWeb(payload);
      if (res.status === 1) {
        const { response } = res;
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(resetErrorMessage());
        return fulfillWithValue({ response });
      }
      if (res?.message === "Email does not exists") {
        dispatch(setErrorMessage({ field: "email", message: res?.message }));
      } else if (res?.message === "Invalid password!") {
        dispatch(setErrorMessage({ field: "password", message: res?.message }));
      } else {
        dispatch(openToast({ type: "error", message: res?.message }));
      }
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const LogOutAdminAction = createAsyncThunk(
  "login/logOutAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await logOutAdmin();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ response: res });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const LogOutWebAction = createAsyncThunk(
  "login/logOutWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await logOutWeb();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ response: res });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

export const ForgotPasswordAction = createAsyncThunk(
  "login/forgotPassword",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await forgotPassword(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ response: res });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const ResetPasswordAction = createAsyncThunk(
  "login/resetPassword",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await resetPassword(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const RegisterWebAction = createAsyncThunk(
  "login/registerWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      let profileImage = "";
      const uploadFilesResponse = await uploadFile({
        file,
        uploadFor: "profile",
      });
      if (uploadFilesResponse.status === 1) {
        profileImage = uploadFilesResponse?.response?.urls?.[0];
        const res = await register({ ...rest, profileImage: profileImage });
        if (res.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          dispatch(
            openDrawer({
              content: (
                <SuccessModal
                  goToLogin={true}
                  showCancel={false}
                  title={"Congratulations"}
                  btnText={"Done"}
                  message={"Your account has been successfully Complete"}
                />
              ),
            })
          );
          return fulfillWithValue(res);
        }
        dispatch(openToast({ type: "error", message: res?.message }));
        return rejectWithValue({ message: res?.message });
      }
      dispatch(
        openToast({ type: "error", message: uploadFilesResponse?.message })
      );
      return rejectWithValue({ message: uploadFilesResponse?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const ValidateTokenAdmin = createAsyncThunk(
  "auth/validateTokenAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await validateToken(payload);
      if (res?.response?.isTokenExpired === false) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      LocalstorageService.removeFromLocalStorage("aboutcare-admin");
      window.location = "/admin";
      return rejectWithValue({ message: res.message });
    } catch (error) {
      LocalstorageService.removeFromLocalStorage("aboutcare-admin");
      window.location = "/admin";
      return rejectWithValue(error);
    }
  }
);

export const CheckEmail = createAsyncThunk(
  "auth/checkEmail",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await emailCheck(payload);
      if (res?.status === 1) {
        let fieldtoReset = "";
        if (payload.duns) {
          fieldtoReset = "dunsNumber";
        }
        if (payload.email) {
          fieldtoReset = "email";
        }
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(resetErrorMessage());
        return fulfillWithValue({ field: fieldtoReset });
      }
      let field = "";
      if (res.response.isDunsNumberExists) {
        field = "dunsNumber";
      }
      if (res.response.isEmailExists) {
        field = "email";
      }
      return rejectWithValue({ field: field, message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateTokenWeb = createAsyncThunk(
  "login/validateTokenWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await validateToken(payload);
      if (res?.response?.isTokenExpired === false) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      LocalstorageService.removeFromLocalStorage("aboutcare-web");
      window.location = "/";
      return rejectWithValue({ message: res.message });
    } catch (error) {
      LocalstorageService.removeFromLocalStorage("aboutcare-web");
      window.location = "/";
      return rejectWithValue(error);
    }
  }
);

export const SendOtpAction = createAsyncThunk(
  "forgotPassword/sendOtp",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let tempObj = {
        email: payload.email,
        type: payload.type,
      };
      if (payload.phone) {
        tempObj = {
          ...tempObj,
          phone: payload.phone,
          countryCode: payload.countryCode,
        };
      }
      const res = await sendOtpWeb(tempObj);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        // dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res.message });
    } catch (error) {
      dispatch(
        openToast({ type: "error", message: error?.response?.data?.message })
      );
      return rejectWithValue(error);
    }
  }
);

export const VerifyOtpAction = createAsyncThunk(
  "forgotPassword/verifyOtp",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { type, otp, ...rest } = payload;
      let tempObj = {
        otp: otp,
        email: payload.email,
        type: type,
      };
      if (payload.phone) {
        tempObj = {
          ...tempObj,
          phone: payload.phone,
          countryCode: payload.countryCode,
        };
      }
      const res = await verifyOtp(tempObj);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (payload.type === "verification") {
          delete rest["isAccepted"];
          dispatch(
            RegisterWebAction({
              ...rest,
              deviceName: "Computer",
              deviceType: "system",
              deviceModel: "system",
              os: "chrome",
              fcmToken: "webFcm",
            })
          );
        }
        // dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res.message });
    } catch (error) {
      dispatch(
        openToast({ type: "error", message: error?.response?.data?.message })
      );
      return rejectWithValue(error);
    }
  }
);

export const ForgotPasswordWebAction = createAsyncThunk(
  "login/forgotPasswordWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await forgotPasswordWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        dispatch(SendOtpAction({ ...payload, type: "forgotPassword" }));
        dispatch(resetErrorMessage());
        return fulfillWithValue(res);
      }
      if (res?.message === "Email not exists!") {
        dispatch(setErrorMessage({ field: "email", message: res?.message }));
      }
      // dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const ResetPasswordWebAction = createAsyncThunk(
  "login/resetPasswordWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await resetPasswordWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                goToLogin={true}
                showCancel={false}
                title={"Congratulations"}
                btnText={"Go To Login"}
                message={"Your password has been successfully reset "}
              />
            ),
          })
        );
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const ChangePasswordWebAction = createAsyncThunk(
  "profile/changePasswordWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await changePasswordWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));

        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res.message });
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      dispatch(
        openToast({
          type: "success",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      return rejectWithValue(error);
    }
  }
);
