import { IconButton, MenuItem } from "@mui/material";
import {
  AdminMessageItemWeb,
  MuiBox,
  MuiTypography,
  StyledMenu,
  UserMessageItemWeb,
  WebInput,
} from "components/atoms";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddMessageForWebAction,
  GetUserMessagesForWebAction,
} from "store/middlewares/HelpAndSupport";
import Microphone from "assets/images/SVG/microphoneBtn.svg";
import Attachment from "assets/images/SVG/AttachedBtn.svg";
import SendBtn from "assets/images/SVG/sendBtn.svg";
import Gallery from "assets/images/SVG/galleryBtn.svg";
import VideoPlay from "assets/images/SVG/video-playBtn.svg";
import Document from "assets/images/SVG/documentBtn.svg";

const HelpAndSupportChatMessages = ({ chatSelected }) => {
  const chatMenuBtn = [
    { title: "Images", icon: Gallery },
    { title: "Video", icon: VideoPlay },
    { title: "Document", icon: Document },
  ];
  const dispatch = useDispatch();

  const [chatMenu, setChatMenu] = useState(null);

  const chatMenuOpen = Boolean(chatMenu);
  const chatMenuClick = (event) => {
    setChatMenu(event.currentTarget);
  };

  const chatMenuClose = () => {
    setChatMenu(null);
  };

  const {
    helpAndSupport: { getUserMessages, isChatFinished },
  } = useSelector((store) => store);

  const intervalRef = useRef(null);

  useEffect(() => {
    if (chatSelected) {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (getUserMessages.length > 0 && isChatFinished === false) {
        dispatch(GetUserMessagesForWebAction({ id: chatSelected }));
        // Set new interval for fetching messages
        intervalRef.current = setInterval(() => {
          dispatch(GetUserMessagesForWebAction({ id: chatSelected }));
        }, 3000);
      }

      // Cleanup on component unmount or when selectedUser changes
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }
  }, [dispatch, chatSelected, isChatFinished]);

  const handleSubmit = (values) => {
    if (formik.values.message) {
      dispatch(
        AddMessageForWebAction({
          chatId: chatSelected,
          message: values?.message,
        })
      );
      formik.setFieldValue("message", "");
    }
  };

  const formik = useFormik({
    initialValues: { message: "" },
    enableReinitialize: true,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <MuiBox sx={{ bgcolor: "#F4FCFF", p: "10px" }}>
        <MuiBox
          sx={{
            bgcolor: "#F4FCFF",
            borderRadius: "12px",
            height: { xs: "60dvh", md: "74dvh" },
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {chatSelected && (
            <>
              <MuiBox
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
                className="no-scrollbar"
              >
                {getUserMessages?.map((item) => {
                  if (item.messageType === "sendByAdmin") {
                    return (
                      <AdminMessageItemWeb
                        key={item?.id}
                        item={item}
                        chatSelected={chatSelected}
                      />
                    );
                  } else {
                    return <UserMessageItemWeb key={item?.id} item={item} />;
                  }
                })}
              </MuiBox>
              {!isChatFinished && (
                <MuiBox
                  sx={{
                    p: {
                      xs: "5px 15px 15px 15px",
                      md: "5px 10px 10px 10px",
                    },
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <MuiBox
                      sx={{
                        bgcolor: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MuiBox sx={{ width: "fit-content", flexGrow: 1 }}>
                        <WebInput
                          placeholder={"Write text here"}
                          variant="plain"
                          className={"message-input"}
                          required={false}
                          name={"message"}
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </MuiBox>
                      <MuiBox
                        sx={{
                          width: "fit-content",
                          display: "flex",
                          pr: "10px",
                        }}
                      >
                        <IconButton>
                          <img src={Microphone} />
                        </IconButton>
                        <IconButton onClick={chatMenuClick} sx={{ mr: 1 }}>
                          <img src={Attachment} />
                        </IconButton>
                        <IconButton
                          type="submit"
                          sx={{
                            borderRadius: "12px",
                            bgcolor: "#0CA8DF",
                            "&:hover": { bgcolor: "#287d9b" },
                          }}
                        >
                          <img src={SendBtn} />
                        </IconButton>
                      </MuiBox>
                    </MuiBox>
                  </form>
                </MuiBox>
              )}
            </>
          )}
          {(!chatSelected || chatSelected === "") && (
            <MuiTypography align="center" variant="body1">
              No Chat Selected
            </MuiTypography>
          )}
        </MuiBox>
      </MuiBox>
      {/* CHAT MENU -----Start */}
      <StyledMenu
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={chatMenu}
        open={chatMenuOpen}
        onClose={chatMenuClose}
      >
        {chatMenuBtn.map((item, index) => (
          <MenuItem key={index} onClick={chatMenuClose} sx={{ gap: 1 }}>
            <img src={item.icon} />
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
      {/* CHAT MENU -----End */}
    </>
  );
};

export default HelpAndSupportChatMessages;
