import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addFAQ,
  deleteFAQ,
  getAllEmail,
  getFAQ,
  getFaqVotes,
  updateFAQ,
  updateVisibilityFAQ,
  getAllChatList,
  updateStatus,
  addChat,
  getMessages,
  addMessage,
  deleteMessage,
  finishChat,
  getFAQWeb,
  voteFAQWeb,
  sendEmailWeb,
  getAllChatListWeb,
  addChatForWeb,
  getMessagesForWebByChatId,
  addMessageForWeb,
  addReviewToChatForWeb,
} from "store/services/HelpAndSupport";
import { setEmailSentStatus } from "store/slices/admin/helpAndSupportManagement";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

// For Fetch All FAQs
export const GetAllFaqsAction = createAsyncThunk(
  "faqs/getAllFaqs",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getFAQ(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Fetch All FAQs Web
export const GetAllFaqsWebAction = createAsyncThunk(
  "faqs/getAllFaqsWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getFAQWeb(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// Vote FAQs Web
export const VoteFaqWebAction = createAsyncThunk(
  "faqs/voteFaqWebAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await voteFAQWeb(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Fetch All FAQ Votes
export const GetAllFaqsVotesAction = createAsyncThunk(
  "faqs/getAllFaqsVotes",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getFaqVotes(payload);
      if (res.status === 1) {
        return fulfillWithValue(res.response);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Add FAQ
export const AddFaqAction = createAsyncThunk(
  "faqs/addFaqs",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addFAQ(payload);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Update FAQ
export const UpdateFaqAction = createAsyncThunk(
  "faqs/updateFaqs",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateFAQ(payload);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Delete FAQ
export const DeleteFaqAction = createAsyncThunk(
  "faqs/deleteFaqs",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteFAQ(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Update Visibility
export const UpdateVisiblityFaqAction = createAsyncThunk(
  "faqs/updateVisiblityFaqAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisibilityFAQ(payload);
      if (res.status === 1) {
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Fetch Emails
export const GetAllEmailsAction = createAsyncThunk(
  "helpandsupport/emails",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getAllEmail(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Fetch Emails
export const SendEmailWeb = createAsyncThunk(
  "helpandsupport/sendEmailWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await sendEmailWeb(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(setEmailSentStatus(true));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      dispatch(setEmailSentStatus(false));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(setEmailSentStatus(false));
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Fetch Emails
export const UpdateStatusAction = createAsyncThunk(
  "helpandsupport/updateEmailStatus",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateStatus(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Fetch h&s chatlist
export const GetAllChatListAction = createAsyncThunk(
  "helpandsupport/chatList",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getAllChatList();
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Add Chat
export const AddChatAction = createAsyncThunk(
  "helpandsupport/addChat",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addChat(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Get Single User Messages
export const GetUserMessagesAction = createAsyncThunk(
  "helpandsupport/getMessages",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getMessages(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Add Message
export const AddMessageAction = createAsyncThunk(
  "helpandsupport/addMessage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addMessage(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Delete Message
export const DeleteMessageAction = createAsyncThunk(
  "helpandsupport/deleteMessage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteMessage(payload);
      if (res.status === 1) {
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Finish Message
export const FinishChatAction = createAsyncThunk(
  "helpandsupport/finishChat",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await finishChat(payload);
      if (res.status === 1) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For Fetch h&s chatlist
export const GetAllChatListForWebAction = createAsyncThunk(
  "helpandsupport/chatListForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getAllChatListWeb();
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Add Chat
export const AddChatForWebAction = createAsyncThunk(
  "helpandsupport/addChatForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addChatForWeb(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Get Single User Messages
export const GetUserMessagesForWebAction = createAsyncThunk(
  "helpandsupport/getMessagesForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getMessagesForWebByChatId(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Add Message
export const AddMessageForWebAction = createAsyncThunk(
  "helpandsupport/addMessageForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addMessageForWeb(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({ message: error?.response?.data?.message , status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

// For Add Message
export const AddReviewForChatAction = createAsyncThunk(
  "helpandsupport/addReviewForChatWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addReviewToChatForWeb(payload);
      if (res.status === 1) {
        dispatch(closeDrawer());
        dispatch(
          openToast({
            type: "success",
            message: res?.message,
          })
        );
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(
        openToast({
          type: "error",
          message: res?.message,
        })
      );
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);
