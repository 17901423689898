import React from "react";
import MuiTypography from "../MuiTypography";
import MuiBox from "../MuiBox";
import NoDataFound from "assets/images/SVG/NoDataFound.svg";

const NoDataFoundCard = ({ title = "No Data Found", desc = 'Whoops... this information is not available for a moment', width = '14em', padding = 5 }) => {
  return (
    <MuiBox
      sx={{
        p: padding,
        // bgcolor: "#fff",
        borderRadius: "12px",
        maxWidth: "100%",
        height: '100%',
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          height: '100%'
        }}
      >
        <MuiBox sx={{ maxWidth: width }}>
          <img src={NoDataFound} className="img-contain" />
        </MuiBox>
        <MuiTypography variant="h5" color="#303030" fontWeight={"bold"} align={"center"}>
          {title}
        </MuiTypography>
        <MuiTypography variant="baseFont" color="#9E9E9E" align={"center"}>
          {desc}
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default NoDataFoundCard;
