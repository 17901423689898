import { createSlice } from "@reduxjs/toolkit";
import {
  AddBadgesAction,
  DeleteBadgesAction,
  UpdateBadgesAction,
  GetAllBadgesAction,
  GetAllBadgesForWebAction,
  GetProfessionalRequestBadgesAction,
  UpdateVisiblityBadgesAction,
  ApproveOrRejectRequestAction,
  GetTransactionDetailsForWeb,
  GetBadgesHistoryForWebAction,
  AddBadgesForWebAction,
} from "store/middlewares/badges";
import { GetTransactionDetailsDirectHire } from "store/middlewares/directHire";

export const badgesSlice = createSlice({
  name: "badges",
  initialState: {
    badgesLoading: false,
    badges: [],
    badgesHistoryLoading: false,
    badgesHistory: [],
    badgesFilterProps: {},
    transactionDetailsLoading: false,
    transactionDetails: null,
    requstBadgesFilterProps: null,
  },
  reducers: {
    setBadgesFilterProps: (state, action) => {
      state.badgesFilterProps = action.payload;
    },
    setRequestBadgesFilterProps: (state, action) => {
      state.requstBadgesFilterProps = action.payload;
    },
    resetTransactionDetails: (state) => {
      state.transactionDetails = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllBadgesAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(GetAllBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = action.payload.response;
      })
      .addCase(GetAllBadgesAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })

      .addCase(GetAllBadgesForWebAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(GetAllBadgesForWebAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = action.payload.response;
      })
      .addCase(GetAllBadgesForWebAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })

      .addCase(GetBadgesHistoryForWebAction.pending, (state) => {
        state.badgesHistoryLoading = true;
        state.badgesHistory = [];
      })
      .addCase(GetBadgesHistoryForWebAction.fulfilled, (state, action) => {
        state.badgesHistoryLoading = false;
        state.badgesHistory = action.payload.response;
      })
      .addCase(GetBadgesHistoryForWebAction.rejected, (state) => {
        state.badgesHistoryLoading = false;
        state.badgesHistory = [];
      })

      // Delete Badges
      .addCase(DeleteBadgesAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.badges?.filter((title) => title.id !== id);
        state.badgesLoading = false;
        state.badges = tempArray;
      })

      //Update Badges
      .addCase(UpdateBadgesAction.fulfilled, (state, action) => {
        let {
          data: { id, ...rest },
        } = action.payload.response;

        let tempArray = state.badges?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.badgesLoading = false;
        state.badges = tempArray;
      })

      // Add Badges
      .addCase(AddBadgesForWebAction.fulfilled, (state, action) => {
        let tempObj = {
          ...action.payload.response,
          badgesDesignation: {
            name: action.payload.name,
          },
        };

        state.badgesHistoryLoading = false;
        state.badgesHistory = [tempObj, ...state.badgesHistory];
      })
      //Add Badges
      .addCase(AddBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = [action.payload.response, ...state.badges];
      })

      //Update Visiblity Badges
      .addCase(UpdateVisiblityBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        let { id } = action.payload;
        let tempArray = state.badges?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.badges = tempArray;
      })

      //Get Professional Request Badges
      .addCase(GetProfessionalRequestBadgesAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(
        GetProfessionalRequestBadgesAction.fulfilled,
        (state, action) => {
          state.badgesLoading = false;
          state.badges = action.payload.response;
        }
      )
      .addCase(GetProfessionalRequestBadgesAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })
      .addCase(ApproveOrRejectRequestAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = state.badges.map((item) => {
          if (item?.id === action.payload.id) {
            return { ...item, status: action.payload.status };
          }
          return { ...item };
        });
      })
      .addCase(GetTransactionDetailsForWeb.pending, (state) => {
        state.transactionDetailsLoading = true;
        state.transactionDetails = null;
      })
      .addCase(GetTransactionDetailsForWeb.fulfilled, (state, action) => {
        state.transactionDetailsLoading = false;
        state.transactionDetails = action.payload.response;
      })
      .addCase(GetTransactionDetailsForWeb.rejected, (state) => {
        state.transactionDetailsLoading = false;
        state.transactionDetails = null;
      })

      // For check transaction status for direct hire
      // .addCase(GetTransactionDetailsDirectHire.pending, (state) => {
      //   state.transactionDetailsLoading = true;
      //   state.transactionDetails = null;
      // })
      // .addCase(GetTransactionDetailsDirectHire.fulfilled, (state, action) => {
      //   state.transactionDetailsLoading = false;
      //   state.transactionDetails = action.payload.response;
      // })
      // .addCase(GetTransactionDetailsDirectHire.rejected, (state) => {
      //   state.transactionDetailsLoading = false;
      //   state.transactionDetails = null;
      // });
  },
});

export const {
  setBadgesFilterProps,
  setRequestBadgesFilterProps,
  resetTransactionDetails,
} = badgesSlice.actions;

export default badgesSlice.reducer;
