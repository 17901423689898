import axiosClient from "utils/api";
const URI = "/admin";

export const getTransactionHistory = async (payload) => {
  let { role, ...rest } = payload;
  try {
    const response = await axiosClient().request({
      url: `/transactionHistory/${role}TransactionHistory`,
      method: "get",
      params: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get get Transaction History", error);
    throw error;
  }
};

// get all admin earning transction 
export const getAllAdminTransHistory = async () => {
  const URL = `${URI}/transactionHistory/adminEarnings`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get All Admin Transaction History", error);
    throw error;
  }
};

// get shift transction detail
export const getShiftTransactionDetails = async (payload) => {
  const URL = `${URI}/transactionHistory/shiftTransactionDetails/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get All shift Transaction Details History", error);
    throw error;
  }
};

// get spacific user all transaction 
export const getUserAllTransHistory = async (payload) => {
  const URL = `${URI}/transactionHistory/transactions/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get spacific user all transaction", error);
    throw error;
  }
};
