import axiosClient from "utils/api";
const URI = "/admin";
const WEB = "/professional";

// For Get Professional home
export const getProfessionalHome = async () => {
  const URL = `${WEB}/homeProfessional`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Professional Home:", error);
    throw error;
  }
};

// For Get All Professionals
export const getAllProfessional = async (payload) => {
  let { type, ...rest } = payload;
  let tempObj = {};
  Object.keys(rest)?.forEach((key) => {
    if (rest[key]) {
      tempObj = { ...tempObj, [`${key}`]: rest[key] };
    }
  });
  const URL = `${URI}/professional/professionals/${type}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: tempObj,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Active Professional:", error);
    throw error;
  }
};

// For Get a Single Professional
export const getProfessionalById = async (payload) => {
  let { id } = payload;
  const URL = `${URI}/professional/professional/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Professional By Id:", error);
    throw error;
  }
};

export const getProfessionalExperience = async (payload) => {
  let { id } = payload;
  const URL = `${URI}/experience/experience/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Professional Experince:", error);
    throw error;
  }
};

// For Upload File Add Single Professional
export const addUploadFile = async (payload) => {
  const URL = `${URI}/upload/file`;
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Add Active Professional:", error);
    throw error;
  }
};

// For Add Single Professional
export const addProfessional = async (payload) => {
  const URL = `${URI}/professional/professional`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Add Active Professional:", error);
    throw error;
  }
};

// For Edit Professional
export const editProfessional = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/professional/professional/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Edit Active Professional:", error);
    throw error;
  }
};

// For Delete Professional
export const deleteProfessional = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/professional/professional/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Delete Active Professional:", error);
    throw error;
  }
};

// For Get Holidays For Professional
export const getHolidaysForProfessional = async () => {
  const URL = `${URI}/holidayData/holidays`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Edit Active Professional:", error);
    throw error;
  }
};

// For Active Professional
export const activeProfessionalUser = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/professional/active/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Active Professional User:", error);
    throw error;
  }
};

// For Block Professional
export const blockProfessionalUser = async (payload) => {
  const { id, blockReason } = payload;
  const URL = `${URI}/professional/block/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: blockReason,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Block Professional User:", error);
    throw error;
  }
};

// For Unblock Professional
export const unblockProfessionalUser = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/professional/unblock/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Unblock Professional User:", error);
    throw error;
  }
};

// For Unblock Professional
export const restoreProfessionalUser = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/professional/restore/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Restore Professional User:", error);
    throw error;
  }
};

// For Get Holidays Data For User
export const getProfessionalUserHolidays = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/holidayData/holidays/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Holidays Data For User:", error);
    throw error;
  }
};

// For Get All notification
export const getAllProfessionalNotification = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/notification/notification/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error occurred during Get All Professional Notification:",
      error
    );
    throw error;
  }
};

// For Get All notification
export const updateAllProfessionalNotification = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/notification/notification/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error occurred during Update Professional Notification:",
      error
    );
    throw error;
  }
};

// For Get All notification
export const getAllNearbyHospitals = async (payload) => {
  const URL = `${WEB}/nearByHospitals`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Near By Hospital:", error);
    throw error;
  }
};

// For Get Hospital's Details
export const getHospitalsDetails = async (payload) => {
  const { id } = payload;
  const URL = `${WEB}/hospitalDetails/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Hospital Details:", error);
    throw error;
  }
};

// For Get All Hospital's Job List By Id
export const getHospitalJobsById = async ({ id, page }) => {
  const URL = `${WEB}/getHospitalJobs/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: { page },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Hospital's Job list:", error);
    throw error;
  }
};

// For Get Hospital's Job Details
export const getHospitalJobDetails = async (payload) => {
  const { id } = payload;
  const URL = `${WEB}/getJobDetails/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Hospital's Job Details:", error);
    throw error;
  }
};

// For Get Schedule's All Shifts Date And Time
export const getAllScheduleDateAndTime = async (payload) => {
  const URL = `/professionalSchedule/allNextShifts`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error occurred during Get Schedule's All Shifts Date And Time:",
      error
    );
    throw error;
  }
};

// For Apply To Hospital Job
export const applyHospitalJob = async (payload) => {
  const URL = `${WEB}/jobs`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during apply to job:", error);
    throw error;
  }
};

// For withdraw request To Hospital Job
export const withdrawHospitalJob = async (payload) => {
  const { id } = payload;
  const URL = `${WEB}/cancelApplication/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during withdraw request to job:", error);
    throw error;
  }
};

// For update professional availability
export const updateProfessionalAvailability = async (payload) => {
  const URL = `${WEB}/availability`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error occurred during Update Professional Availability:",
      error
    );
    throw error;
  }
};

// For Give Reviews
export const giveReviewsProfessional = async (payload) => {
  const URL = `/review/addReview`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during add review:", error);
    throw error;
  }
};

// Get professional's all reviews
export const getAllProfessionalReviews = async (payload) => {
  const { id } = payload;
  const URL = `/review/getHospitalReviews/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get all reviews:", error);
    throw error;
  }
};
