import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import shiftTypeObj from "utils/shiftTypeObj";
import dayjs from "dayjs";

// import ChatIcon from "assets/images/SVG/Bubble chat.svg";
// import PhoneIcon from "assets/images/SVG/Smartphone.svg";
import CalanderBlack from "assets/images/SVG/CalendarBlack.svg";
import { statusColorCodeProf, statusTextColorCodeProf, statusTextProf } from "utils/directHireStatus";
const ProfessionalDirectHireCard = ({ item, to }) => {
    return (
        <MuiBox
            component={Link}
            to={to}
            sx={{
                borderRadius: "12px",
                bgcolor: "#fff",
                p: { xs: "8px", sm: "12px" },
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                height: '100%',
            }
            }
        >
            <MuiBox
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    flexWrap: "wrap",
                }}
            >
                <MuiBox
                    sx={{
                        width: "115px",
                        height: "115px",
                        borderRadius: "9px",
                        overflow: "hidden",
                        flexShrink: 0,
                    }}
                >
                    <img src={item?.users?.profileImageUrl} alt="Applicant" className="img-cover" />
                </MuiBox>
                <MuiBox
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        maxWidth: "11em",
                    }}
                >
                    <MuiTypography color="text.main" variant="baseFont" fontWeight="bold">
                        {item?.users?.name}{" "}
                    </MuiTypography>
                    <MuiTypography color="text.contrastText" fontSize="14px">
                        {item?.jobTitle?.title}
                    </MuiTypography>
                    <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap" }}>
                        <MuiBox
                            sx={{
                                width: "fit-content",
                                p: "5px 12px",
                                bgcolor: "#007AFF26",
                                borderRadius: "30px",
                            }}
                        >
                            <MuiTypography
                                color="#2D2635"
                                fontSize="12px"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                {item?.jobSkills[0]?.name}
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox
                            sx={{
                                width: "fit-content",
                                p: "5px 12px",
                                bgcolor: "#ffdfc3",
                                borderRadius: "30px",
                            }}
                        >
                            <MuiTypography
                                color="#2D2635"
                                fontSize="12px"
                                sx={{ whiteSpace: "nowrap" }}
                            >
                                {shiftTypeObj[item?.JobPostDetails?.shiftType]}
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox
                            sx={{
                                width: "fit-content",
                                p: "5px 12px",
                                bgcolor: statusColorCodeProf[item?.applicantStatus],
                                borderRadius: "30px",
                            }}
                        >
                            <MuiTypography color={statusTextColorCodeProf[item?.applicantStatus]} fontSize="12px">
                                {statusTextProf[item?.applicantStatus]}
                            </MuiTypography>
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px", mt: 'auto' }}>
                <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <MuiBox
                        sx={{
                            height: "42px",
                            width: "42px",
                            bgcolor: "#E8F0F3",
                            flexShrink: 0,
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img src={CalanderBlack} />
                    </MuiBox>
                    <MuiBox
                        sx={{
                            display: "flex",
                            gap: "1px",
                            maxWidth: "8em",
                            flexDirection: "column",
                        }}
                    >
                        <MuiTypography color="text.main" fontSize="15px" fontWeight="bold">
                            {dayjs(item?.JobDateSchedule?.[0]?.startDate).format("DD/MM/YYYY")}
                        </MuiTypography>
                        <MuiTypography
                            color="text.contrastText"
                            fontSize="13px"
                            variant="baseFont"
                        >
                            {dayjs(item?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.startTime).format("HH:mm")}{" "}
                            -{" "}
                            {dayjs(item?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.endTime).format("HH:mm")}
                        </MuiTypography>
                    </MuiBox>
                </MuiBox>
                {/* <MuiBox sx={{ display: "flex", gap: "12px", width: "fit-content" }}>
          <IconButton
            aria-label="phone"
            sx={{
              height: "30px",
              width: "30px",
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              p: "5px",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={PhoneIcon} />
          </IconButton>
          <IconButton
            aria-label="phone"
            sx={{
              height: "30px",
              width: "30px",
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              p: "5px",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={ChatIcon} />
          </IconButton>
        </MuiBox> */}
            </MuiBox>
        </MuiBox >
    );
};

export default ProfessionalDirectHireCard;
