import { Box, IconButton } from "@mui/material";
import React from "react";
import { BadgeCheck, BadgeX } from "lucide-react";
import { useDispatch } from "react-redux";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { TooltipProvider } from "components/atoms";
import Swal from "sweetalert2";
import BlockSVGModel from "assets/images/blockSVGModel.svg";
import { ApproveOrRejectRequestAction } from "store/middlewares/badges";

const RequestBadges = ({ row }) => {
  const dispatch = useDispatch();
  return (
    <>
      {(!row?.status || row?.status?.toLowerCase() === "pending") && (
        <Box className="flex h-full items-center justify-end">
          {/* For active user */}
          <IconButton
            color="success"
            // sx={{ padding: "0 !important" }}
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to approve "${row?.user?.name} ${row?.user?.lastName}"'s badge?`,
                imageUrl: ApprovedSVGModel,
                imageWidth: 100,
                imageHeight: 100,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                customClass: {
                  title: "Swal-title-class",
                  popup: "custom-swal-popup",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(
                    ApproveOrRejectRequestAction({
                      id: row.id,
                      status: "accepted",
                    })
                  );
                }
              });
            }}
          >
            <TooltipProvider title="Approve">
              <BadgeCheck />
            </TooltipProvider>
          </IconButton>
          <IconButton
            color="error"
            // sx={{ padding: "0 !important" }}
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to reject "${row?.user?.name} ${row?.user?.lastName}"'s badge?`,
                imageUrl: BlockSVGModel,
                imageWidth: 100,
                imageHeight: 100,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                customClass: {
                  title: "Swal-title-class",
                  popup: "custom-swal-popup",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(
                    ApproveOrRejectRequestAction({
                      id: row.id,
                      status: "rejected",
                    })
                  );
                }
              });
            }}
          >
            <TooltipProvider title="Reject">
              <BadgeX />
            </TooltipProvider>
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default RequestBadges;
