// For Hospital Direct Hire
export const statusColorCode = {
    pending: '#ffdfc3',
    rejected: '#FF5C5C',
    pendingPayment: '#4FC64F',
    accepted: '#4FC64F',
}

export const statusTextColorCode = {
    pending: '#2D2635',
    rejected: '#ffffff',
    pendingPayment: '#ffffff',
    accepted: '#ffffff',
}

export const statusText = {
    pending: 'Response Pending',
    rejected: 'Rejected',
    pendingPayment: 'Payment Pending',
    accepted: 'Hired',
}

// For Professional Direct Hire
export const statusTextProf = {
    pending: 'Pending',
    rejected: 'Rejected',
    pendingPayment: 'Pending',
    accepted: 'Accepted',
}

export const statusTextColorCodeProf = {
    pending: '#ffffff',
    rejected: '#ffffff',
    pendingPayment: '#ffffff',
    accepted: '#ffffff',
}

export const statusColorCodeProf = {
    pending: '#E18308',
    rejected: '#FF5C5C',
    pendingPayment: '#E18308',
    accepted: '#4FC64F',
}