import {
  CustomDatePicker,
  MuiBox,
  MuiTypography,
  Textfield,
  WebInput,
} from "components/atoms";
import React, { useState } from "react";

import HospitalName from "assets/images/SVG/hospitalName.svg";
import Grid from "@mui/material/Unstable_Grid2";
import * as Yup from "yup";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import {
  AddExperienceAction,
  EditExperienceAction,
} from "store/middlewares/experience";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  description,
  endDate,
  Name,
  Role,
  startDate,
} from "utils/validation/web/ExperienceForm";

const AddExperienceModal = ({ experience = null }) => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values) => {
    if (!hasError) {
      if (experience) {
        dispatch(EditExperienceAction({ id: experience?.id, ...values }));
      } else {
        dispatch(AddExperienceAction(values));
      }
    }
  };

  const AddExperienceSchema = Yup.object().shape({
    hospitalName: Name,
    role: Role,
    description: description,
    startDate: startDate,
    endDate: endDate,
  });

  const formik = useFormik({
    initialValues: {
      hospitalName: experience ? experience?.hospitalName : "",
      role: experience ? experience?.role : "",
      startDate: experience ? experience?.startDate : null,
      endDate: experience ? experience?.endDate : null,
      description: experience ? experience?.description : null,
    },
    validationSchema: AddExperienceSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          px: { xs: "10px", sm: "20px", md: "30px" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <MuiTypography
          variant="h6"
          fontWeight={"bold"}
          align={"center"}
          sx={{ mb: 4 }}
        >
          {experience ? "Edit Exeperience" : "Add Exeperience"}
        </MuiTypography>
        <WebInput
          variant="plain"
          type="text"
          name={"hospitalName"}
          label="Hospital Name"
          placeholder="Enter Hospital Name"
          icon={<img src={HospitalName} alt="hospital name" />}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.hospitalName}
          errorMsg={formik.errors.hospitalName}
          error={formik.touched.hospitalName && formik.errors.hospitalName}
        />
        <WebInput
          variant="plain"
          type="text"
          label="Role"
          name={"role"}
          placeholder="Enter Role"
          icon={<img src={HospitalName} alt="role" />}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.role}
          errorMsg={formik.errors.role}
          error={formik.touched.role && formik.errors.role}
        />
        <MuiBox>
          <MuiTypography fontWeight="bold" variant="subtitle1">
            Duration
          </MuiTypography>
          <Grid container spacing={3} sx={{ mt: "0" }}>
            <Grid xs={6}>
              <CustomDatePicker
                label={"Start Date"}
                disableFuture={true}
                maxDate={
                  formik.values?.endDate && dayjs(formik.values?.endDate)
                }
                onChange={(e) => formik.setFieldValue("startDate", dayjs(e))}
                error={formik.touched.startDate && formik.errors.startDate}
                value={
                  formik.values?.startDate && dayjs(formik.values?.startDate)
                }
                onError={(e) => {
                  if (e) {
                    setHasError(true);
                  } else {
                    setHasError(false);
                  }
                }}
                errorMsg={formik.errors.startDate}
              />
            </Grid>
            <Grid xs={6}>
              <CustomDatePicker
                label={"End Date"}
                disableFuture={true}
                minDate={
                  formik.values?.startDate && dayjs(formik.values?.startDate)
                }
                onChange={(e) => {
                  formik.setFieldValue("endDate", dayjs(e));
                }}
                value={
                  formik.values?.endDate ? dayjs(formik.values?.endDate) : null
                }
                error={formik.touched.endDate && formik.errors.endDate}
                errorMsg={formik.errors.endDate}
                onError={(e) => {
                  if (e) {
                    setHasError(true);
                  } else {
                    setHasError(false);
                  }
                }}
              />
            </Grid>
          </Grid>
        </MuiBox>
        <Textfield
          label={"Description"}
          name={"description"}
          bgcolor="#fff"
          required={false}
          rows={4}
          placeholder={"Enter Description"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.description}
          errorMsg={formik.errors.description}
          error={formik.touched.description && formik.errors.description}
        />
        <Button type="submit" variant="lightButton">
          {experience ? "Update" : "Add"}
        </Button>
      </MuiBox>
    </form>
  );
};

export default AddExperienceModal;
