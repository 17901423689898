import { IconButton } from "@mui/material";
import React from "react";
import { UserRoundCheck, X } from "lucide-react";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";
import BlockSVGModel from "assets/images/blockSVGModel.svg";
import { ActiveProfessionalAction } from "store/middlewares/users/professionals";
import { useDispatch } from "react-redux";

const ProfStatusButton = ({ type = "accept", name, id }) => {
  const dispatch = useDispatch();

  return (
    <IconButton
      sx={{ padding: "0 !important" }}
      disableRipple
      onClick={() => {
        Swal.fire({
          title: `Are you sure you want to activate "${name}"'s profile?`,
          imageUrl: type === "accept" ? ApprovedSVGModel : BlockSVGModel,
          imageWidth: 100,
          imageHeight: 100,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
          customClass: {
            title: "Swal-title-class",
            popup: "custom-swal-popup",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (type === "accept") {
              dispatch(ActiveProfessionalAction({ id, navigate: true }));
            }
          }
        });
      }}
    >
      <div
        className={`text-slate-500 btn ${
          type === "accept"
            ? "bg-green-500 hover:bg-green-400"
            : "bg-red-500 hover:bg-red-400"
        }  border-slate-200  hover:border-slate-30`}
      >
        {type === "accept" ? (
          <UserRoundCheck className="text-white inline-block size-5 mr-1" />
        ) : (
          <X className="text-white inline-block size-5 mr-1" />
        )}
        <span className="align-middle text-white capitalize">{type}</span>
      </div>
    </IconButton>
  );
};

export default ProfStatusButton;
