import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import MuiBox from "../MuiBox";
import { CustomDatePicker, FormError, MuiTypography, PDFViewer, WebInput } from "..";
import { Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Folder from "assets/images/SVG/folder.svg";
import CloseIcon from "assets/images/SVG/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  AddCredentialCertificateWebAction,
  EditCredentialCertificateWebAction,
} from "store/middlewares/credential/web";
import dayjs from "dayjs";
import {
  expiryDate,
  File,
  name,
  certificateNumber,
} from "utils/validation/web/CertificateUpload";
import { ImagesFileTypesAndPdf } from "utils/ImagesFileTypes";
import { openToast } from "store/slices/common/toast.slice";

const AddCertificate = ({ certificate = null }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [isPDF, setIsPDF] = useState(false);
  const { items } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const { credentialActionSuccess } = useSelector((state) => state.credential);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      ImagesFileTypesAndPdf.includes(file.type) &&
      file.size <= Number(items.uploadImageSize) * 1024 * 1024
    ) {
      formik.setFieldValue("file", file);
      setSelectedImage(URL.createObjectURL(file));
      setIsPDF(file?.type.includes("pdf"));
      event.target.value = "";
    } else if (file.size > Number(items.uploadImageSize) * 1024 * 1024) {
      dispatch(
        openToast({
          type: "error",
          message: `Please upload image having size less than ${Number(
            items.uploadImageSize
          )}MB`,
        })
      );
    } else {
      dispatch(
        openToast({
          type: "error",
          message:
            "File has an unsupported file type. Allowed file types are: .jpeg, .jpg, .png, .gif, .bmp, .tiff, .webp, .heif, .heic, .cr2, .nef, .arw, .jfif, .avif, .bpg",
        })
      );
    }
  };

  const [initialVal, setInitialVal] = useState({
    name: "",
    number: null,
    expiryDate: "",
    file: null,
  });

  useEffect(() => {
    if (certificate) {
      setInitialVal({
        name: certificate?.name,
        number: certificate?.number,
        expiryDate: dayjs(certificate?.expiryDate).format("YYYY-MM-DD"),
        file: null,
      });
      setSelectedImage(certificate?.imageUrl);
      setIsPDF(certificate?.imageUrl.includes(".pdf"));
    } else {
      setInitialVal({
        name: "",
        number: null,
        expiryDate: "",
        file: null,
      });
    }
  }, [certificate]);

  const handleSubmit = (values) => {
    if (certificate) {
      let tempObj = { ...values };
      if (!values?.file) {
        tempObj = { ...tempObj, image: certificate?.image };
        delete tempObj["file"];
      }
      dispatch(
        EditCredentialCertificateWebAction({
          ...tempObj,
          number: tempObj?.number,
          id: certificate?.id,
        })
      );
    } else {
      dispatch(
        AddCredentialCertificateWebAction({
          ...values,
          number: String(values?.number),
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialVal,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: name,
      number: certificateNumber,
      expiryDate: expiryDate,
      file: !certificate ? File : null,
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (credentialActionSuccess) {
      formik.resetForm();
      setSelectedImage("");
      // reset();
    }
  }, [credentialActionSuccess]);

  // console.log(certificate, 'certificate');
  // console.log(formik.errors, 'formik errors');
  // console.log(credentialActionSuccess, 'credentialActionSuccess');

  return (
    <MuiBox
      sx={{
        p: { xs: "10px", sm: "20px" },
        bgcolor: "#fff",
        borderRadius: "12px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} lg={6}>
            <MuiTypography fontWeight="bold" variant="h6">
              Certification Upload
            </MuiTypography>
            <MuiTypography variant="baseFont" color="text.contrastText">
              Add your documents here, and you can upload up to 5 files max.
            </MuiTypography>
            <MuiBox
              sx={{
                my: 2,
                p: 3,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "12px",
                gap: "12px",
                border: "2px dashed #000000bb",
              }}
            >
              <img src={Folder} />
              <MuiTypography variant="baseFont" color="#0B0B0B">
                Start uploading
              </MuiTypography>
              <Button
                component="label"
                sx={{
                  width: "fit-content",
                  p: "3px 12px",
                  border: "1px solid #0CA8DF",
                  borderRadius: "10px",
                }}
              >
                <MuiTypography
                  variant="baseFont"
                  color="#0CA8DF"
                  fontWeight="bold"
                >
                  Upload File
                </MuiTypography>
                <input
                  type="file"
                  accept={ImagesFileTypesAndPdf.join(",")}
                  hidden
                  onChange={handleChange}
                />
              </Button>
            </MuiBox>
            {formik.errors.file && formik.touched.file && (
              <MuiBox sx={{ margin: "0 auto", width: "fit-content" }}>
                <FormError errorMsg={formik.errors.file} />
              </MuiBox>
            )}
            {/* <CustomProgressbar /> */}
            <MuiBox
              style={{
                marginTop: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <WebInput
                label="Name of Certificate"
                placeholder="Enter License No."
                name={"name"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ""}
                errorMsg={formik.errors.name}
                error={formik.touched.name && formik.errors.name}
              />
              <WebInput
                label="Certification Number"
                name={"number"}
                placeholder="Enter Certification Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.number || ""}
                errorMsg={formik.errors.number}
                error={formik.touched.number && formik.errors.number}
              />
              <CustomDatePicker
                label="Expiration Date"
                name={"expiryDate"}
                format={"YYYY-MM-DD"}
                disablePast={certificate ? false : true}
                value={
                  formik.values.expiryDate
                    ? dayjs(formik.values.expiryDate)
                    : null
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "expiryDate",
                    dayjs(e).format("YYYY-MM-DD")
                  )
                }
                required={true}
                errorMsg={formik.errors.expiryDate}
                error={formik.touched.expiryDate && formik.errors.expiryDate}
              />
            </MuiBox>
          </Grid>
          {selectedImage && (
            <Grid xs={12} lg={6}>
              <MuiBox
                sx={{
                  border: "2px dashed #000000bb",
                  borderRadius: "12px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    p: "5px",
                    bgcolor: "#ffffff70",
                    "&:hover": { bgcolor: "#ffffffd4" },
                    zIndex: 999999
                  }}
                  onClick={() => setSelectedImage("")}
                >
                  <img src={CloseIcon} />
                </IconButton>
                {isPDF ? ( 
                  <PDFViewer file={selectedImage} width={475} />
                ) : (
                  <img src={selectedImage} className="img-cover" />
                )}
              </MuiBox>
              <MuiBox sx={{ pt: 4 }}>
                <Button variant="lightButton" type="submit">
                  Done
                </Button>
              </MuiBox>
            </Grid>
          )}
        </Grid>
      </form>
    </MuiBox>
  );
};

export default AddCertificate;
