import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAccessManagementUsers,
  deleteAccessManagementUsers,
  editAccessManagementUsers,
  getAccessManagementUsers,
} from "store/services/accessManagementUsers";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import {  handleAuthErrorAdmin } from "utils/handleAuthError";

//Get Access Management Users
export const GetAllAccessManagementUsers = createAsyncThunk(
  "access/getAllAccessManagementUsers",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getAccessManagementUsers();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

//Add Access Management Users
export const AddAccessManagementUsers = createAsyncThunk(
  "access/addAccessManagementUsers",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addAccessManagementUsers(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

//Add Access Management Users
export const EditAccessManagementUsers = createAsyncThunk(
  "access/editAccessManagementUsers",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await editAccessManagementUsers(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

//Delete Access Management Users
export const DeleteAccessManagementUsers = createAsyncThunk(
  "access/deleteAccessManagementUsers",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteAccessManagementUsers(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);
