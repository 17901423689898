import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { FullScreenImageModel } from "components/molecules";
import { handleAddressClick } from "utils/handleAddressClick";

// import ChatIcon from "assets/images/SVG/Bubble chat.svg";
// import PhoneIcon from "assets/images/SVG/Smartphone.svg";
import Location from "assets/images/SVG/map-pin.svg";
import Beg from "assets/images/SVG/beg.svg";
import Star from "assets/images/SVG/Star.svg";


const UserDetailsCard = ({ singleJob }) => {
    return (
        <MuiBox
            sx={{
                borderRadius: "12px",
                bgcolor: "#fff",
                p: { xs: 1, sm: 2 },
            }}
        >
            <MuiBox
                sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                }}
            >
                <MuiBox
                    sx={{
                        maxWidth: "220px",
                        height: "162px",
                        position: "relative",
                        flexShrink: 0,
                    }}
                >
                    <MuiBox
                        sx={{
                            height: "235px",
                            borderRadius: "15px",
                            overflow: "hidden",
                            position: "absolute",
                            top: "-75px",
                            bgcolor: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <FullScreenImageModel
                            imgSrc={singleJob?.users?.profileImageUrl}
                            fullScreen={true}
                        />
                        {/* {me?.role === "hospital" && (
                            <MuiBox
                                sx={{
                                    width: "fit-content",
                                    position: "absolute",
                                    top: "8px",
                                    right: "8px",
                                }}
                            >
                                <StatusPill
                                    status={availablityStatus[singleJob?.users?.isAvailable]}
                                    statusText={
                                        availablityTextStatus[singleJob?.users?.isAvailable]
                                    }
                                    isWeb={true}
                                />
                            </MuiBox>
                        )} */}
                    </MuiBox>
                </MuiBox>
                <MuiBox>
                    <MuiBox
                        sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "space-between",
                            flexWrap: "wrap-reverse",
                        }}
                    >
                        <MuiBox
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                maxWidth: "15em",
                            }}
                        >
                            <MuiTypography color="text.main" variant="h6" fontWeight="bold">
                                {`${singleJob?.users?.name} ${singleJob?.users?.lastName}`}
                            </MuiTypography>
                            <MuiTypography color="text.contrastText" variant="baseFont">
                                {singleJob?.users?.jobTitles?.title}
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox
                            sx={{
                                display: "flex",
                                gap: "12px",
                                width: "fit-content",
                            }}
                        >
                            {singleJob?.status?.toLowerCase() !== "inprogress" && (
                                <>
                                    {/* <IconButton
                    // component={Link}
                    onClick={handleClick}
                    aria-label="phone"
                    sx={{
                      height: "45px",
                      width: "45px",
                      bgcolor: "darkButton.main",
                      transition: "all 0.3s",
                      borderRadius: "9px",
                      overflow: "hidden",
                      "&:hover": {
                        bgcolor: "#2d2d2d",
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <img src={PhoneIcon} />
                  </IconButton> */}
                                    {/* <IconButton
                    component={Link}
                    aria-label="phone"
                    sx={{
                      height: "45px",
                      width: "45px",
                      bgcolor: "darkButton.main",
                      transition: "all 0.3s",
                      borderRadius: "9px",
                      overflow: "hidden",
                      "&:hover": {
                        bgcolor: "#2d2d2d",
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <img src={ChatIcon} />
                  </IconButton> */}
                                </>
                            )}
                        </MuiBox>
                    </MuiBox>
                    <MuiBox sx={{ mt: 2 }}>
                        <MuiBox>
                            <MuiBox
                                sx={{
                                    maxWidth: "18em",
                                    display: "flex",
                                    gap: "5px",
                                    mt: 1,
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    handleAddressClick(
                                        [
                                            singleJob?.users?.addressDetails?.firstAddress,
                                            singleJob?.users?.addressDetails?.secondAddress,
                                            singleJob?.users?.addressDetails?.landmark,
                                            singleJob?.users?.addressDetails?.city,
                                            singleJob?.users?.addressDetails?.state,
                                            singleJob?.users?.addressDetails?.zipCode,
                                        ]
                                            .filter(Boolean)
                                            .join(", ")
                                    )
                                }
                            >
                                <img
                                    src={Location}
                                    style={{
                                        height: "24px",
                                        width: "24px",
                                        objectFit: "contain",
                                    }}
                                />
                                <MuiTypography color="text.main" variant="baseFont">
                                    {[
                                        singleJob?.users?.addressDetails?.firstAddress,
                                        singleJob?.users?.addressDetails?.secondAddress,
                                        singleJob?.users?.addressDetails?.landmark,
                                        singleJob?.users?.addressDetails?.city,
                                        singleJob?.users?.addressDetails?.state,
                                        singleJob?.users?.addressDetails?.zipCode,
                                    ]
                                        .filter(Boolean)
                                        .join(", ")}
                                </MuiTypography>
                            </MuiBox>
                        </MuiBox>
                        {/* {me?.role === "hospital" && ( */}
                        <MuiBox
                            sx={{
                                width: "fit-content",
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                                mt: 2,
                                flexWrap: "wrap",
                            }}
                        >
                            <MuiBox
                                sx={{
                                    display: "flex",
                                    width: "fit-content",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <MuiBox
                                    sx={{
                                        width: "26px",
                                        height: "26px",
                                        borderRadius: "6px",
                                        bgcolor: "#E8F0F3",
                                        p: "5px",
                                    }}
                                >
                                    <img
                                        src={Beg}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                        }}
                                    />
                                </MuiBox>
                                <MuiTypography
                                    color="text.main"
                                    fontWeight="bold"
                                    sx={{ fontSize: "14px" }}
                                >
                                    {singleJob?.totalExperience}{" "}
                                    years
                                </MuiTypography>
                            </MuiBox>
                            <MuiBox
                                sx={{
                                    display: "flex",
                                    width: "fit-content",
                                    alignItems: "center",
                                    gap: "5px",
                                    bgcolor: "#FFF7E7",
                                    borderRadius: "6px",
                                    p: "5px",
                                }}
                            >
                                <MuiBox sx={{ width: "18px", height: "18px" }}>
                                    <img
                                        src={Star}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                        }}
                                    />
                                </MuiBox>
                                <MuiTypography
                                    color="text.contrastText"
                                    sx={{ fontSize: "14px" }}
                                >
                                    {`${singleJob?.reviewRatingCount} (${singleJob?.reviewRatingCount})`}
                                </MuiTypography>
                            </MuiBox>
                        </MuiBox>
                        {/* )} */}
                    </MuiBox>
                </MuiBox>
            </MuiBox>

            {/* CONTACT POPOVER -----Start */}
            {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MuiTypography sx={{ p: "8px 12px" }}>{singleJob?.users?.countryCode}{" "}{singleJob?.users?.phone}</MuiTypography>
      </Popover> */}
            {/* CONTACT POPOVER -----End */}
        </MuiBox>
    );
};

export default UserDetailsCard;
