import React from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import { IconButton } from "@mui/material";
import EditIcon from "assets/images/SVG/delete.svg";
import Clock from "assets/images/SVG/ClockBlue.svg";
import Calender from "assets/images/SVG/CalendarBlue.svg";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { AddScheduleModal } from "components/molecules";
import { openDrawer } from "store/slices/common/drawer.slice";
import { UpdateScheduleOfJobForWebAction } from "store/middlewares/jobs";

const NexShiftScheduleCard = ({
  editable = true,
  schedule,
  fromHire = false,
  jobId = "",
  applicantId = "",
}) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        border: "1px solid #D5D4D7",
        borderRadius: "12px",
        p: { xs: 1, sm: 2 },
        display: "flex",
        bgcolor: "#fff",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap-reverse",
          gap: 2,
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <img src={Calender} />
          <MuiTypography
            fontSize="14px"
            fontWeight="bold"
            sx={{
              display: "flex",
              gap: "6px",
              flexWrap: "wrap",
            }}
          >
            <span>
              {dayjs(schedule?.scheduleCurrentDate).format("DD/MM/YYYY")}
            </span>
          </MuiTypography>
        </MuiBox>
        {editable && (
          <IconButton
            aria-label="edit"
            sx={{
              bgcolor: "#0A1E41",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              "&:hover": {
                bgcolor: "#112c5abb",
                borderRadius: "50%",
              },
            }}
            onClick={() => {
              dispatch(
                openDrawer({
                  content: (
                    <AddScheduleModal
                      schedule={{
                        startDate: schedule?.startDate,
                        endDate: schedule?.endDate,
                        timeSlots: schedule?.JobTimeSchedule?.map((item) => {
                          return {
                            endTime: item?.endTime,
                            startTime: item?.startTime,
                          };
                        }),
                      }}
                      onClick={(obj) => {
                        dispatch(
                          UpdateScheduleOfJobForWebAction({
                            id: schedule?.id,
                            ...obj,
                            fromHire: fromHire,
                            jobId: jobId,
                            applicantId: applicantId,
                          })
                        );
                      }}
                    />
                  ),
                  width: "650px",
                })
              );
            }}
          >
            <img src={EditIcon} />
          </IconButton>
        )}
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          mt: "15px",
        }}
      >
        <img src={Clock} />
        <MuiTypography
          fontSize="14px"
          sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
        >
          <span>{dayjs(schedule?.startTime).format("HH:mm")}</span>
          <span>To</span>
          <span>{dayjs(schedule?.endTime).format("HH:mm")}</span>
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default NexShiftScheduleCard;
