import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { openDrawer } from "store/slices/common/drawer.slice";
import ConfirmModal from "../ConfirmModal";

import Beg from "assets/images/SVG/begWhite.svg";
import CalenderIcon from "assets/images/SVG/plain calendar black.svg";
import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
import dayjs from "dayjs";
import AddExperienceModal from "../AddExperienceModal";
import { DeleteExperienceAction } from "store/middlewares/experience";
const ExeperienceCard = ({ experience }) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        p: 2,
        bgcolor: "#fff",
        borderRadius: "12px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 1,
        mt: "5px",
      }}
    >
      <MuiBox
        sx={{
          height: "41px",
          width: "41px",
          borderRadius: "50%",
          overflow: "hidden",
          flexShrink: 0,
          bgcolor: "#0CA8DF",
          display: "flex",
          placeContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Beg}
          style={{
            height: "24px",
            width: "24px",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          flexBasis: "100%",
          wordBreak: "break-all",
        }}
      >
        <MuiTypography
          variant="subtitle1"
          fontWeight="bold"
          lineHeight="19.49px"
          sx={{ overflowWrap: "break-word" }}
        >
          {experience?.hospitalName}
        </MuiTypography>
        <MuiTypography
          fontSize="14px"
          lineHeight="20px"
          color="text.blue"
          fontWeight={600}
        >
          {experience?.role}
        </MuiTypography>
        <MuiBox sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={CalenderIcon}
            style={{
              height: "19px",
              width: "19px",
              objectFit: "contain",
            }}
          />
          <MuiTypography fontSize="14px" lineHeight="20px" fontWeight="bold">
            {dayjs(experience?.startDate).format("MMM DD, YYYY")} to{" "}
            {dayjs(experience?.endDate).format("MMM DD, YYYY")}
          </MuiTypography>
        </MuiBox>
        <MuiTypography
          fontSize="16px"
          lineHeight="20px"
          color="text.contrastText"
          sx={{ overflowWrap: "break-word" }}
        >
          {experience?.description}
        </MuiTypography>
      </MuiBox>
      <MuiBox
        sx={{
          width: { xs: "fit-content", md: "fit-content" },
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          flexDirection: {
            xs: "row",
          },
        }}
      >
        <IconButton
          sx={{
            p: "6px",
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
          }}
          onClick={() =>
            dispatch(
              openDrawer({
                content: <AddExperienceModal experience={experience} />,
              })
            )
          }
        >
          <img src={EditIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
        <IconButton
          sx={{
            p: "6px",
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
          }}
          onClick={() =>
            dispatch(
              openDrawer({
                content: (
                  <ConfirmModal
                    type="delete"
                    title="Delete Exeperience"
                    message={
                      <>
                        Are you sure you want to delete your exeperience? <br />
                        This action is permanent and cannot be undone.
                      </>
                    }
                    btnText={"Delete Exeperience"}
                    onClick={() =>
                      dispatch(DeleteExperienceAction({ id: experience?.id }))
                    }
                  />
                ),
              })
            )
          }
        >
          <img src={DeleteIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
      </MuiBox>
    </MuiBox>
  );
};

export default ExeperienceCard;
