import { CustomLoader, MuiBox } from "components/atoms";
import React from "react";

function FullScreenCustomLoader() {
  return (
    <MuiBox sx={{bgcolor: "#000000BF", height: "100%", width: "100%", zIndex:"999999999",position:"fixed",top:"50%",left:"50%",transform: "translate(-50%, -50%)"}}>
      <CustomLoader />
    </MuiBox>
  );
}

export default FullScreenCustomLoader;
