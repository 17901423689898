import { configureStore } from "@reduxjs/toolkit";

//--------------------- admin --------------------
import { authSlice } from "./slices/admin/session/auth";
import { usersSlice } from "./slices/admin/users/users.slice";

// ------------------ web ----------------------------
import { meSlice } from "./slices/admin/me.slice.js";
import { settingsSlice } from "./slices/admin/settings/settings.slice";
import { toastSlice } from "./slices/common/toast.slice";
import { layoutSlice } from "./slices/common/layout.slice";
import { jobTitleSlice } from "./slices/admin/jobTitle";
import { jobCancellationSlice } from "./slices/admin/jobCancellation";
import { adminModalSlice } from "./slices/common/adminModal.slice";
import { specialitiesSlice } from "./slices/admin/specialities";
import { facilitiesSlice } from "./slices/admin/facilities";
import { skillsSlice } from "./slices/admin/skills";
import { countriesSlice } from "./slices/admin/locationManagement/countries";
import { reportsSlice } from "./slices/admin/reports";
import { cmsSlice } from "./slices/admin/cms";
import { citiesSlice } from "./slices/admin/locationManagement/cities";
import { userSlice } from "./slices/admin/users/user.js";
import { credentialSlice } from "./slices/admin/credentials";
import { rolesSlice } from "./slices/admin/accessManagement/roles";
import { accessSlice } from "./slices/admin/accessManagement/access";
import { accessUsersSlice } from "./slices/admin/accessManagement/users";
import { deleteReasonsSlice } from "./slices/admin/deleteReason";
import { blockReasonsSlice } from "./slices/admin/blockReason";
import { rejectReasonsSlice } from "./slices/admin/RejectReason";
import { bannersSlice } from "./slices/admin/banner";
import { dynamicMenuSlice } from "./slices/admin/dynamicMenu";
import { badgesSlice } from "./slices/admin/badges";
import { taxSlice } from "./slices/admin/Tax";
import { dashboardSlice } from "./slices/admin/dashboard";
import { languagesSlice } from "./slices/admin/language";
import { jobsSlice } from "./slices/admin/jobs";
import { provincesSlice } from "./slices/admin/locationManagement/provinces";
import { subscriptionManagementSlice } from "./slices/admin/subscriptions";
import { helpAndSupportSlice } from "./slices/admin/helpAndSupportManagement";
import { paymentPeriodsSlice } from "./slices/admin/paymentPeriods";
import { drawerSlice } from "./slices/common/drawer.slice";
import { errorMessageSlice } from "./slices/common/errorMessage.slice";
import { directHireSlice } from "./slices/admin/directHire";

function configStore() {
  const store = configureStore({
    reducer: {
      auth: authSlice.reducer,
      users: usersSlice.reducer,
      user: userSlice.reducer,
      me: meSlice.reducer,
      jobs: jobsSlice.reducer,
      dashboard: dashboardSlice.reducer,
      settings: settingsSlice.reducer,
      toast: toastSlice.reducer,
      layout: layoutSlice.reducer,
      jobTitles: jobTitleSlice.reducer,
      jobCancellations: jobCancellationSlice.reducer,
      reports: reportsSlice.reducer,
      specialities: specialitiesSlice.reducer,
      facilities: facilitiesSlice.reducer,
      skills: skillsSlice.reducer,
      countries: countriesSlice.reducer,
      cities: citiesSlice.reducer,
      provinces: provincesSlice.reducer,
      adminModal: adminModalSlice.reducer,
      cms: cmsSlice.reducer,
      roles: rolesSlice.reducer,
      access: accessSlice.reducer,
      accessUsers: accessUsersSlice.reducer,
      helpAndSupport: helpAndSupportSlice.reducer,
      credential: credentialSlice.reducer,
      deleteReasons: deleteReasonsSlice.reducer,
      blockReasons: blockReasonsSlice.reducer,
      rejectReason: rejectReasonsSlice.reducer,
      banners: bannersSlice.reducer,
      dynamicMenu: dynamicMenuSlice.reducer,
      badges: badgesSlice.reducer,
      tax: taxSlice.reducer,
      languages: languagesSlice.reducer,
      subscriptionManagement: subscriptionManagementSlice.reducer,
      paymentPeriods: paymentPeriodsSlice.reducer,
      drawer: drawerSlice.reducer,
      errorMessage: errorMessageSlice.reducer,
      directHire: directHireSlice.reducer
      // Add other reducers if needed
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
  return store;
}

export default configStore;
