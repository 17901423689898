import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const licenceNo = Yup.string()
  .required("Please enter license number")
  .matches(startsWithSpaceRegExp, "License Number cannot start with a space")
  .matches(
    onlySpaceRegExp,
    "License Number should contain at least one character"
  )
  .min(2, "License no must be at least 2 characters long")
  .max(18, "License no must be at most 18 characters long");

export const issuedMonth = Yup.string().required("Please enter Issued Month");
export const issuedYear = Yup.string().required("Please enter Issued Year");

export const expiryMonth = Yup.string().required("Please enter Expiry Month");
export const expiryYear = Yup.string().required("Please enter Expiry Year");
export const specialityId = Yup.string().required("Please select Specilaity");

export const File = Yup.mixed()
  .required("Please upload picture")
  .test(
    "fileSize",
    "Please select a valid image file with size up to 3MB",
    (value) => {
      return value && value.size <= 3 * 1024 * 1024; // 3MB limit
    }
  );
