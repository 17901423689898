import { Box, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ArchiveRestore,
  ChevronsRight,
  ShieldBan,
  ShieldCheck,
  UserRoundCheck,
} from "lucide-react";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import { useDispatch } from "react-redux";
import { ModalBlockUser, ModalDeleteHospital } from "components/molecules";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import EditIcon from "assets/images/editpenpapercolor.svg";
import DeleteIcon from "assets/images/deleteIconSVGcolor.svg";
import DeleteSVGModel from "assets/images/deleteIconSVGModel.svg";
import {
  RestoreProfessionalAction,
  UnblockProfessionalAction,
  ActiveProfessionalAction,
} from "store/middlewares/users/professionals";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";
import Swal from "sweetalert2";
import BlockSVGModel from "assets/images/blockSVGModel.svg";

const Users = ({ row, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box className="flex h-full items-center justify-end">
      {/* For active user */}
      {type === "pending" &&
        row.isAllCredential !== false &&
        checkAccess("update_professional") && (
          <IconButton
            color="info"
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to activate "${row?.name}"'s profile?`,
                imageUrl: ApprovedSVGModel,
                imageWidth: 100,
                imageHeight: 100,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                customClass: {
                  title: "Swal-title-class",
                  popup: "custom-swal-popup",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(ActiveProfessionalAction({ id: row.id }));
                }
              });
            }}
          >
            <TooltipProvider title="Active user">
              <UserRoundCheck />
            </TooltipProvider>
          </IconButton>
        )}

      {/* For block user */}
      {type === "active" && checkAccess("update_professional") && (
        <IconButton
          color="warning"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: `Are you sure you want to block this "${row?.name}"?`,
              imageUrl: BlockSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(
                  openAdminModal({
                    title: `Block ${row?.name}`,
                    content: <ModalBlockUser row={row} />,
                  })
                );
              }
            });
          }}
        >
          <TooltipProvider title="Block">
            <ShieldBan />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For unblock user */}
      {type === "inactive" && checkAccess("update_professional") && (
        <IconButton
          color="success"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: `Are you sure you want to Unblock this "${row?.name}"?`,
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(UnblockProfessionalAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Unblock">
            <ShieldCheck />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For restore user */}
      {type === "deleted" && checkAccess("delete_professional") && (
        <IconButton
          color="warning"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: `Are you sure you want to Restore this "${row?.name}"?`,
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(RestoreProfessionalAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Restore">
            <ArchiveRestore />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For Navigate to details */}
      {type !== "deleted" && checkAccess("read_professional") && (
        <>
          <IconButton
            onClick={() => navigate(`/admin/professional/edit/${row?.id}`)}
          >
            <TooltipProvider title="Edit">
              <img src={EditIcon} className="inline-block size-5 mr-1" />
            </TooltipProvider>
          </IconButton>

          <IconButton
            color="warning"
            disableRipple
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to delete this "${row?.name}"?`,
                imageUrl: DeleteSVGModel,
                imageWidth: 100,
                imageHeight: 100,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                customClass: {
                  title: "Swal-title-class",
                  popup: "custom-swal-popup",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(
                    openAdminModal({
                      title: `Delete ${row?.name} `,
                      content: (
                        <ModalDeleteHospital row={row} type={"professional"} />
                      ),
                    })
                  );
                }
              });
            }}
          >
            <TooltipProvider title="Delete">
              <img src={DeleteIcon} className="inline-block size-5 mr-1" />
            </TooltipProvider>
          </IconButton>

          <IconButton
            color="info"
            disableRipple
            onClick={() => navigate(`/admin/professional/${type}/${row?.id}`)}
          >
            <TooltipProvider title="Detail">
              <ChevronsRight />
            </TooltipProvider>
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default Users;
