import { Button, FormControl, Slider } from "@mui/material";
import {
  WebInput,
  MuiBox,
  MuiTypography,
  Select,
  useGooglePlaces,
} from "components/atoms";
import React, { useEffect, useRef } from "react";

import HospitalIcon from "assets/images/SVG/hospitalName blue.svg";
import Location from "assets/images/SVG/LocationIcon.svg";
import Navigator from "assets/images/SVG/Navigator.svg";
import Dollar from "assets/images/SVG/dollar.svg";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { GetAllNearByHospitalAction } from "store/middlewares/users/professionals";

const CalenderFilterModal = ({ params, onSubmit }) => {
  const { items } = useSelector((state) => state.settings);

  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  let intitalVal = {
    shiftTypeFilter: "",
    filterByHospital: "",
    minPrice: "",
    maxPrice: "",
    latitude: "",
    longitude: "",
    location: "",
    distance: "",
  };

  useEffect(() => {
    dispatch(GetAllNearByHospitalAction({ limit: 1000 }));
  }, [dispatch]);

  const inputRef = useRef(null);
  const { initializePlaces } = useGooglePlaces();

  const handleSubmit = (values) => {
    let tempObj = {
      shiftTypeFilter: values.shiftTypeFilter,
      filterByHospital: values.filterByHospital,
      minPrice: values.minPrice,
      maxPrice: values.maxPrice,
    };
    if (values.latitude && values.longitude) {
      tempObj = {
        ...tempObj,
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        distance: values.distance,
      };
    }
    onSubmit(tempObj);
  };

  const handleChange = (placeDetails) => {
    // ---------------- set value -----------------//
    formik.setFieldValue("latitude", placeDetails.latLng.lat);
    formik.setFieldValue("longitude", placeDetails.latLng.lng);
    // ---------------- set value -----------------//
  };

  const formik = useFormik({
    initialValues: {
      ...intitalVal,
      distance: Number(items?.defaultDistance),
      ...params,
    },
    validationSchema: null,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleSliderChange = (event, newValue) => {
    if (newValue !== null) {
      formik.setFieldValue("distance", newValue);
    }
  };

  return (
    <MuiBox sx={{ p: { xs: "16px 10px", sm: "16px 32px" } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter
      </MuiTypography>
      <MuiBox
        onSubmit={formik.handleSubmit}
        component={"form"}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <WebInput
              variant="plain"
              placeholder={"Enter here"}
              label={"Location"}
              icon={<img src={Location} alt="Location" />}
              endIcon={<img src={Navigator} alt="Navigator" />}
              required={false}
              ref={inputRef}
              name="location"
              onChange={(e) => {
                formik.handleChange(e);
                initializePlaces(inputRef, handleChange);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.location || ""}
              errorMsg={formik.errors.location}
              error={formik.touched.location && formik.errors.location}
            />
          </Grid>
          <Grid xs={12}>
            <FormControl fullWidth>
              <MuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <MuiTypography variant="baseFont" fontWeight={"bold"}>
                  Radius
                </MuiTypography>
                <MuiBox
                  sx={{
                    width: "fit-content",
                    borderRadius: "6px",
                    border: "1px solid #000000",
                    p: "0 6px",
                  }}
                >
                  <MuiTypography sx={{ fontSize: "14px" }}>
                    {formik.values.distance}+ Miles
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
              <MuiBox
                sx={{
                  bgcolor: "#fff",
                  p: "12px 25px 6px 25px",
                  borderRadius: "12px",
                  mt: "8px",
                }}
              >
                <Slider
                  className="distance-slider"
                  min={Number(items?.minimumDistance)}
                  max={Number(items?.maximumDistance)}
                  value={formik.values.distance}
                  step={1}
                  onChange={handleSliderChange}
                  // onChangeCommitted={handleSliderChangeCommitted}
                  aria-labelledby="distance-slider"
                />
              </MuiBox>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <Select
              label={"Shift Type"}
              onChange={(e) => {
                formik.setFieldValue("shiftTypeFilter", e);
              }}
              name="shiftTypeFilter"
              required={false}
              options={[
                { label: "Full Time", value: "fullTime" },
                { label: "Part Time", value: "partTime" },
              ]}
              icon={<img src={HospitalIcon} />}
              onBlur={formik.handleBlur}
              value={formik.values?.shiftTypeFilter || ""}
              errorMsg={formik.errors.shiftTypeFilter}
              error={
                formik.touched.shiftTypeFilter && formik.errors.shiftTypeFilter
              }
            />
          </Grid>
          <Grid xs={12}>
            <Select
              label={"Facility"}
              className={"facility-select"}
              onChange={(e) => formik.setFieldValue("filterByHospital", e)}
              name="filterByHospital"
              required={false}
              options={users?.items?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.name,
                };
              })}
              onBlur={formik.handleBlur}
              value={formik.values?.filterByHospital || []}
              errorMsg={formik.errors.filterByHospital}
              error={
                formik.touched.filterByHospital &&
                formik.errors.filterByHospital
              }
              icon={<img src={HospitalIcon} />}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <WebInput
              variant="plain"
              placeholder={"Min"}
              label={"Salary (min)"}
              icon={<img src={Dollar} alt="Location" />}
              name={"minPrice"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.minPrice || ""}
              errorMsg={formik.errors.minPrice}
              error={formik.touched.minPrice && formik.errors.minPrice}
              required={false}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <WebInput
              variant="plain"
              required={false}
              placeholder={"Max"}
              label={"Salary (max)"}
              icon={<img src={Dollar} alt="Location" />}
              name={"maxPrice"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxPrice || ""}
              errorMsg={formik.errors.maxPrice}
              error={formik.touched.maxPrice && formik.errors.maxPrice}
            />
          </Grid>
        </Grid>
        <Button variant="lightButton" type={"submit"}>
          Apply
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default CalenderFilterModal;
