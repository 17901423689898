import React, { useContext } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { setRequestBadgesFilterProps } from "store/slices/admin/badges";

const RequestBadgesFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { requstBadgesFilterProps } = useSelector((state) => state.badges);

  const intitalVal = {
    status: "",
  };

  const visiblityOptions = [
    { label: "All", value: "all" },
    { label: "Accepted", value: "accepted" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];

  const AddRoleSchema = Yup.object().shape({
    status: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    if (values.status === "all" || !values.status) {
      dispatch(setRequestBadgesFilterProps(null));
    } else {
      dispatch(setRequestBadgesFilterProps({ status: values.status }));
    }
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...requstBadgesFilterProps },
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setRequestBadgesFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Status"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Status"
          name={"status"}
          value={formik.values.status}
          error={formik.errors.status && formik.touched.status}
          errorMsg={formik.errors.status}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "status",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default RequestBadgesFilter;
