import { createAsyncThunk } from "@reduxjs/toolkit";
import React from "react";
import { SuccessModal } from "components/molecules";
import {
  addCredentialAvatarsForWeb,
  addCredentialCertificateForWeb,
  addCredentialDriverLicenceForWeb,
  addCredentialJobTitleForWeb,
  addCredentialPhysicalAddressForWeb,
  addCredentialProLicenceForWeb,
  addCredentialResumeForWeb,
  addCredentialSocSecForWeb,
  deleteCredentialAvatarsForWeb,
  deleteCredentialCertificateForWeb,
  deleteCredentialPhysicalAddressForWeb,
  deleteCredentialProLicenceForWeb,
  deleteCredentialResumeForWeb,
  editCredentialAvatarsForWeb,
  editCredentialCertificateForWeb,
  editCredentialDriverLicenceForWeb,
  editCredentialPhysicalAddressForWeb,
  editCredentialProLicenceForWeb,
  editCredentialResumeForWeb,
  editCredentialSocSecForWeb,
  getCredentialAvatarsForWeb,
  getCredentialCertificateForWeb,
  getCredentialDriverLicenceForWeb,
  getCredentialJobTitleForWeb,
  getCredentialPhysicalAddressForWeb,
  getCredentialProLicenceForWeb,
  getCredentialResumeByIdForWeb,
  getCredentialResumeForWeb,
  getCredentialSocSecForWeb,
  setDefaultCredentialPhysicalAddressForWeb,
  setDefaultResumeForWeb,
  updateCredentialJobTitleForWeb,
} from "store/services/credential/web";
import { uploadFile } from "store/services/utilities";
import { closeDrawer, openDrawer } from "store/slices/common/drawer.slice";
import { handleAuthError } from "utils/handleAuthError";
import { openToast } from "store/slices/common/toast.slice";

//Get All  avatar
export const GetAllCredentialAvatarWebAction = createAsyncThunk(
  "avatar/getAllCredentialAvatarWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialAvatarsForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Delete Avatar
export const DeleteCredentialAvatarWebAction = createAsyncThunk(
  "avatar/deleteCredentialAvatarWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteCredentialAvatarsForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Avatar
export const AddCredentialAvatarWebAction = createAsyncThunk(
  "avatar/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let tempObj = {};
      const uploadFilesResponse = await uploadFile({
        file: payload.file,
        uploadFor: "avatar",
      });
      if (uploadFilesResponse.status === 1) {
        tempObj = {
          ...tempObj,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
        const res = await addCredentialAvatarsForWeb(tempObj);
        if (res.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          dispatch(
            openDrawer({
              content: (
                <SuccessModal
                  title={"Congratulation"}
                  cancelBtnText="Done"
                  message={
                    <>
                      Your avatar has been <br />
                      successfully upload
                    </>
                  }
                />
              ),
            })
          );
          return fulfillWithValue(res);
        }
        return rejectWithValue(res);
      }
      dispatch(
        openToast({ type: "error", message: uploadFilesResponse.message })
      );
      return rejectWithValue(uploadFilesResponse);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Avatar
export const EditCredentialAvatarWebAction = createAsyncThunk(
  "avatar/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let tempObj = { ...payload };
      if (payload.file) {
        const uploadFilesResponse = await uploadFile({
          file: payload.file,
          uploadFor: "avatar",
        });
        tempObj = {
          ...tempObj,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const res = await editCredentialAvatarsForWeb(tempObj);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                title={"Congratulation"}
                cancelBtnText="Done"
                message={
                  <>
                    Your avatar has been <br />
                    successfully upload
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get All  Physical Address
export const GetAllCredentialPhysicalAddressWebAction = createAsyncThunk(
  "physicalAddress/getAllForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialPhysicalAddressForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get All  Physical Address
export const AddCredentialPhysicalAddressWebAction = createAsyncThunk(
  "physicalAddress/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addCredentialPhysicalAddressForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Edit  Physical Address
export const EditCredentialPhysicalAddressWebAction = createAsyncThunk(
  "physicalAddress/editAddressForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await editCredentialPhysicalAddressForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Edit  Physical Address
export const SetDetfaultCredentialPhysicalAddressWebAction = createAsyncThunk(
  "physicalAddress/setDefaultAddress",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await setDefaultCredentialPhysicalAddressForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Delete Physical Address
export const DeleteCredentialPhysicalAddressWebAction = createAsyncThunk(
  "physicalAddress/deleteAddressForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteCredentialPhysicalAddressForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Job Title
export const GetCredentialJobTitleWebAction = createAsyncThunk(
  "jobTitle/getJobTitleForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialJobTitleForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Update Job Title
export const UpdateCredentialJobTitleWebAction = createAsyncThunk(
  "jobTitle/updateJobTitleForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateCredentialJobTitleForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Job Title
export const AddCredentialJobTitleWebAction = createAsyncThunk(
  "jobTitle/addJobTitleForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addCredentialJobTitleForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Driver Licence
export const GetCredentialDriverLicenceForWeb = createAsyncThunk(
  "drivingLicence/getDrivingLicenceForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialDriverLicenceForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Driver Licence
export const AddCredentialDriverLicenceForWeb = createAsyncThunk(
  "drivingLicence/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let tempObj = {};
      const uploadFrontFileResponse = await uploadFile({
        file: payload.frontFile,
        uploadFor: "driverLicence",
      });
      tempObj = {
        ...tempObj,
        frontImage: uploadFrontFileResponse?.response?.urls?.[0],
      };
      const uploadBackFileResponse = await uploadFile({
        file: payload.backFile,
        uploadFor: "driverLicence",
      });
      tempObj = {
        ...tempObj,
        backImage: uploadBackFileResponse?.response?.urls?.[0],
      };
      const res = await addCredentialDriverLicenceForWeb(tempObj);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                title={"Congratulation"}
                message={
                  <>
                    Your Driving Licence has
                    <br /> been successfully upload
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(res);
      }
      console.log('res', res);
      return rejectWithValue(res);
    } catch (error) {
      console.log('error', error);

      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Driver Licence
export const EditCredentialDriverLicenceForWeb = createAsyncThunk(
  "drivingLicence/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { frontFile, backFile, ...rest } = payload;
      if (frontFile) {
        const uploadFrontFileResponse = await uploadFile({
          file: frontFile,
          uploadFor: "driverLicence",
        });
        rest = {
          ...rest,
          frontImage: uploadFrontFileResponse?.response?.urls?.[0],
        };
      }
      if (backFile) {
        const uploadBackFileResponse = await uploadFile({
          file: backFile,
          uploadFor: "driverLicence",
        });
        rest = {
          ...rest,
          backImage: uploadBackFileResponse?.response?.urls?.[0],
        };
      }
      const res = await editCredentialDriverLicenceForWeb(rest);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                title={"Congratulation"}
                message={
                  <>
                    Your Driving License has
                    <br /> been successfully upload
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Credentail Resume
export const GetCredentialResumeForWeb = createAsyncThunk(
  "resume/getResumeForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialResumeForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Credentail Resume By Id
export const GetCredentialResumeByIdForWeb = createAsyncThunk(
  "resume/getResumeByIdForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialResumeByIdForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Credentail Resume By Id
export const AddCredentialResumeForWeb = createAsyncThunk(
  "resume/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addCredentialResumeForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Credentail Resume By Id
export const EditCredentialResumeForWeb = createAsyncThunk(
  "resume/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await editCredentialResumeForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({
          ...res,
          experience: payload.experienceData,
          education: payload.educationData,
        });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get Credentail Resume By Id
export const DeleteCredentialResumeForWeb = createAsyncThunk(
  "resume/deleteResumeForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteCredentialResumeForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

export const SetDetfaultCredentialResumeWebAction = createAsyncThunk(
  //Edit  Physical Address
  "resume/setDefaultResume",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await setDefaultResumeForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get All Pro Licence
export const GetAllCredentialProLicenceWebAction = createAsyncThunk(
  "proLicence/getAllForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialProLicenceForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const AddCredentialProLicenceWebAction = createAsyncThunk(
  "proLicence/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      const uploadFilesResponse = await uploadFile({
        file: file,
        uploadFor: "proLicence",
      });
      if (uploadFilesResponse.status === 1) {
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
        const res = await addCredentialProLicenceForWeb(rest);
        if (res.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          return fulfillWithValue(res);
        }
        return rejectWithValue(res);
      }
      return rejectWithValue(uploadFilesResponse);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const EditCredentialProLicenceWebAction = createAsyncThunk(
  "proLicence/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      if (file) {
        const uploadFilesResponse = await uploadFile({
          file: file,
          uploadFor: "proLicence",
        });
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const res = await editCredentialProLicenceForWeb(rest);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Delete  Pro Licence
export const DeleteCredentialProLicenceWebAction = createAsyncThunk(
  "proLicence/deleteProLicenceForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteCredentialProLicenceForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get All  Certificate
export const GetAllCredentialCertificateWebAction = createAsyncThunk(
  "certificate/getAllForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialCertificateForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const AddCredentialCertificateWebAction = createAsyncThunk(
  "certificate/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      const uploadFilesResponse = await uploadFile({
        file: file,
        uploadFor: "certificate",
      });
      if (uploadFilesResponse.status === 1) {
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
        const res = await addCredentialCertificateForWeb(rest);
        if (res.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          dispatch(
            openDrawer({
              content: (
                <SuccessModal
                  title={"Congratulation"}
                  cancelBtnText="Done"
                  message={
                    <>
                      Your Certificate has been
                      <br /> successfully upload
                    </>
                  }
                />
              ),
            })
          );
          return fulfillWithValue(res);
        }
        return rejectWithValue(res);
      }
      return rejectWithValue(uploadFilesResponse);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const EditCredentialCertificateWebAction = createAsyncThunk(
  "certificate/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      if (file) {
        const uploadFilesResponse = await uploadFile({
          file: file,
          uploadFor: "proLicence",
        });
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const res = await editCredentialCertificateForWeb(rest);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                title={"Congratulation"}
                cancelBtnText="Done"
                message={
                  <>
                    Your Certificate has been
                    <br /> successfully upload
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Delete  Certificate
export const DeleteCredentialCertificateWebAction = createAsyncThunk(
  "certificate/deleteCertificateForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteCredentialCertificateForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Get All  Soc Sec
export const GetAllCredentialSocSecWebAction = createAsyncThunk(
  "socialSecurity/getAllForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getCredentialSocSecForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const AddCredentialSocSecWebAction = createAsyncThunk(
  "socialSecurity/addForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      const uploadFilesResponse = await uploadFile({
        file: file,
        uploadFor: "socialSecurity",
      });
      if (uploadFilesResponse.status === 1) {
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
        const res = await addCredentialSocSecForWeb(rest);
        if (res.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          return fulfillWithValue(res);
        }
        return rejectWithValue(res);
      }
      return rejectWithValue(uploadFilesResponse);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

//Add Pro Licence
export const EditCredentialSocSecWebAction = createAsyncThunk(
  "socialSecurity/editForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      if (file) {
        const uploadFilesResponse = await uploadFile({
          file: file,
          uploadFor: "socialSecurity",
        });
        rest = {
          ...rest,
          image: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const res = await editCredentialSocSecForWeb(rest);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ message: error?.response?.data?.message, status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);
