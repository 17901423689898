import { createAsyncThunk } from "@reduxjs/toolkit";
import LocalstorageService from "./localstorage-services";
import {
  refreshExpiredAdminToken,
  refreshExpiredToken,
} from "store/services/session/auth";
import { encryptAndStoreToken, getDecryptedToken } from "./CryptoJS";
import { openToast } from "store/slices/common/toast.slice";

export const handleAuthError = createAsyncThunk(
  "auth/refreshTokenWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      if (payload.status === 403) {
        dispatch(openToast({ type: "error", message: payload.message }));
        LocalstorageService.removeFromLocalStorage("aboutcare-web");
        window.location = "/";
      } else if (payload.status === 401) {
        //change status here
        let user = JSON.parse(localStorage.getItem("aboutcare-web"));

        let tempObj = { refreshToken: getDecryptedToken(user?.refreshToken) };
        const res = await refreshExpiredToken(tempObj);
        if (res.status === 1) {
          user = {
            ...user,
            token: encryptAndStoreToken(res.response.accessToken),
          };
          LocalstorageService.webUser({ user: user });
          window.location.reload();
          return fulfillWithValue(res);
        }
        LocalstorageService.removeFromLocalStorage("aboutcare-web");
        window.location = "/";
        return rejectWithValue({ message: res.message });
      }
    } catch (error) {
      LocalstorageService.removeFromLocalStorage("aboutcare-web");
      window.location = "/";
    }
  }
);

export const handleAuthErrorAdmin = createAsyncThunk(
  "auth/refreshTokenAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      if (payload.status === 403) {
        LocalstorageService.removeFromLocalStorage("aboutcare-admin");
        window.location = "/admin";
      } else if (payload.status === 401) {
        //change status here
        let user = JSON.parse(localStorage.getItem("aboutcare-admin"));
        let tempObj = { refreshToken: getDecryptedToken(user?.refreshToken) };
        const res = await refreshExpiredAdminToken(tempObj);

        if (res?.status === 1) {
          const localStorage = {
            user: {
              ...user,
              token: encryptAndStoreToken(res?.response?.accessToken),
            },
          };
          LocalstorageService.loginUser(localStorage);
          window.location.reload();
          return fulfillWithValue(res);
        }
        LocalstorageService.removeFromLocalStorage("aboutcare-admin");
        window.location = "/admin";
        return rejectWithValue({ message: res?.message });
      }
    } catch (error) {
      LocalstorageService.removeFromLocalStorage("aboutcare-admin");
      window.location = "/admin";
    }
  }
);
