import axiosClient from "utils/api";
const URI = "/admin/auth";
const WEB = "/auth";

export const loginAdmin = async (payload) => {
  const URL = `${URI}/login`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const loginWeb = async (payload) => {
  const URL = `${WEB}/loginUser`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: { ...payload, deviceType: "web", fcmToken: payload.fcmToken ?  payload.fcmToken : "webFcm" },
    }); 
    return response.data;
  } catch (error) {
    console.error("Error occurred during Web login:", error);
    throw error;
  }
};

export const emailCheck = async (payload) => {
  const URL = `${WEB}/emailCheck`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Check:", error);
    throw error;
  }
};

export const logOutAdmin = async () => {
  const URL = `${URI}/logout`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Logout:", error);
    throw error;
  }
};

export const logOutWeb = async () => {
  const URL = `${WEB}/logout`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Logout:", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  const URL = `${URI}/changePassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  const URL = `${URI}/forgotPassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const resetPassword = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/resetPassword/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const register = async (payload) => {
  const URL = `${WEB}/register`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Register:", error);
    throw error;
  }
};

export const validateToken = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `${WEB}/validateToken`,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const forgotPasswordWeb = async (payload) => {
  const URL = `${WEB}/forgotPassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Forgot Password:", error);
    throw error;
  }
};

export const sendOtpWeb = async (payload) => {
  const URL = `${WEB}/sendOtp`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Forgot Password:", error);
    throw error;
  }
};

export const verifyOtp = async (payload) => {
  const URL = `${WEB}/verifyOTP`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Forgot Password:", error);
    throw error;
  }
};

export const resetPasswordWeb = async (payload) => {
  const URL = `${WEB}/resetPassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Forgot Password:", error);
    throw error;
  }
};

export const changePasswordWeb = async (payload) => {
  const URL = `${WEB}/changePassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password Web:", error);
    throw error;
  }
};


export const refreshExpiredToken = async (payload) => {
  const URL = `${WEB}/refreshToken`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Refresh Token:", error);
    throw error;
  }
};


export const refreshExpiredAdminToken = async (payload) => {
  const URL = `${URI}/refreshToken`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Refresh Admin Token:", error);
    throw error;
  }
};