import React from "react";
import { MuiBox, MuiTypography } from "components/atoms";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// import Eye from "assets/images/SVG/Eye blue.svg";
const EarningCard = ({ item }) => {
  const { items } = useSelector((state) => state.settings);
  const startTime = dayjs(item?.startTime);
  const endTime = dayjs(item?.endTime);

  const totalHours = (dateString) => {
    let hours = Math.floor(dateString / 3600);
    let remainingSeconds = dateString % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    return `${hours}.${minutes}`
  }
  return (
    <MuiBox
      component={Link}
      to={`/professional/earning-details/${item?.id}`}
      sx={{
        p: { xs: "15px 10px", sm: 2 },
        bgcolor: "#fff",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Facility
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {item?.hospital?.name}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Date
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {dayjs(item?.jobStartDate).format("DD-MM-YYYY")}{" "}-{" "}{dayjs(item?.jobEndDate).format("DD-MM-YYYY")}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Shift ID
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {item?.shiftId}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Shift Date and Hours
          </MuiTypography>
        </MuiBox>
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <MuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <MuiTypography
              variant="baseFont"
              fontWeight="bold"
              sx={{
                display: "flex",
                gap: "6px",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color={"#000000"}
            >
              {dayjs(item?.scheduleCurrentDate).format("DD-MM-YYYY")}
            </MuiTypography>
            <MuiTypography
              variant="baseFont"
              fontWeight="bold"
              sx={{
                display: "flex",
                gap: "6px",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color={"#000000"}
            >
              <span>{startTime.local().format("HH:mm")}</span>
              <span>To</span>
              <span>{endTime.local().format("HH:mm")}</span>
            </MuiTypography>
          </MuiBox>
          {/* <MuiBox sx={{ width: "fit-content" }}>
            <IconButton
              sx={{ bgcolor: "#E8F0F3", borderRadius: "6px" }}
            //   onClick={handleScheduleBtn}
            >
              <img src={Eye} />
            </IconButton>
          </MuiBox> */}
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Status
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography
            variant="baseFont"
            fontWeight="bold"
            color="#fff"
            sx={{
              bgcolor: item?.status === 'paid' ? "#52D274" : '#FF686B',
              p: "4px 15px",
              borderRadius: "13px",
              textTransform: "capitalize",
            }}
          >
            {/* {item?.status} */}
            - -
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Hours Worked
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {totalHours(item?.totalSeconds)}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Rate
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {items?.currencySymbol}
            {item?.employeeHourRate.toFixed(2)}/hr
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Deductions
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            ${item?.deduction?.toFixed(2)}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="sliderText.light">
            Earnings
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            {items?.currencySymbol}
            {item?.totalEarnings.toFixed(2)}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default EarningCard;
