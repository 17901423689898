import React, { useEffect, useState } from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CustomDatePicker, FormError, Select, WebInput } from "..";
import UploadIcon from "assets/images/SVG/Featured icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  AddCredentialProLicenceWebAction,
  EditCredentialProLicenceWebAction,
} from "store/middlewares/credential/web";
import {
  expiryMonth,
  expiryYear,
  File,
  issuedMonth,
  issuedYear,
  licenceNo,
  specialityId,
} from "utils/validation/web/ProLicence";
import { ImagesFileTypesAndPdf } from "utils/ImagesFileTypes";
import { openToast } from "store/slices/common/toast.slice";

const AddProLicence = ({ licence, reset }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.settings);

  const { credentialActionSuccess } = useSelector((state) => state.credential);

  const handleChange = (event) => {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];
    //   formik.setFieldValue("file", file);
    //   setSelectedImage(URL.createObjectURL(file)); // preview the uploaded image
    // }

    const file = event.target.files[0];
    if (
      file &&
      ImagesFileTypesAndPdf.includes(file.type) &&
      file.size <= Number(items.uploadImageSize) * 1024 * 1024
    ) {
      formik.setFieldValue("file", file);
      setSelectedImage(URL.createObjectURL(file));
      event.target.value = "";
    } else if (file.size > Number(items.uploadImageSize) * 1024 * 1024) {
      dispatch(
        openToast({
          type: "error",
          message: `Please upload image having size less than ${Number(
            items.uploadImageSize
          )}MB`,
        })
      );
    } else {
      dispatch(
        openToast({
          type: "error",
          message:
            "File has an unsupported file type. Allowed file types are: .jpeg, .jpg, .png, .gif, .bmp, .tiff, .webp, .heif, .heic, .cr2, .nef, .arw, .jfif, .avif, .bpg",
        })
      );
    }
    event.target.value = "";
  };

  const [initialVal, setInitialVal] = useState({
    licenceNo: "",
    issuedMonth: "",
    issuedYear: "",
    expiryMonth: "",
    expiryYear: "",
    specialityId: "",
    file: null,
  });

  const { specialities } = useSelector((state) => state.specialities);

  useEffect(() => {
    if (licence) {
      setInitialVal({
        licenceNo: licence?.licenceNo,
        issuedMonth: licence?.issuedMonth,
        issuedYear: licence?.issuedYear,
        expiryMonth: licence?.expiryMonth,
        expiryYear: licence?.expiryYear,
        specialityId: licence?.specialityId,
        file: null,
      });
      setSelectedImage(licence?.imageUrl);
    } else {
      setInitialVal({
        licenceNo: "",
        issuedMonth: "",
        issuedYear: "",
        expiryMonth: "",
        expiryYear: "",
        specialityId: "",
        file: null,
      });
    }
  }, [licence]);

  const handleSubmit = (values) => {
    if (licence) {
      let tempObj = { ...values };
      if (!values?.file) {
        tempObj = { ...tempObj, image: licence?.image };
        delete tempObj["file"];
      }
      dispatch(
        EditCredentialProLicenceWebAction({ ...tempObj, id: licence?.id })
      );
    } else {
      dispatch(AddCredentialProLicenceWebAction(values));
    }
    // formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialVal,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      licenceNo: licenceNo,
      issuedMonth: issuedMonth,
      issuedYear: issuedYear,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear,
      specialityId: specialityId,
      file: !licence ? File : null,
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (credentialActionSuccess) {
      formik.resetForm();
      // setInitialVal({
      //   licenceNo: "",
      //   issuedMonth: "",
      //   issuedYear: "",
      //   expiryMonth: "",
      //   expiryYear: "",
      //   specialityId: "",
      //   file: null,
      // });
      setSelectedImage("");
      reset();
    }
  }, [credentialActionSuccess]);

  return (
    <MuiBox
      sx={{
        p: { xs: "10px", sm: "20px" },
        bgcolor: "#fff",
        borderRadius: "12px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <MuiTypography fontWeight="bold" variant="h6">
          {licence ? "Edit Professional Licence" : "Add Professional Licence"}
        </MuiTypography>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 2,
          }}
        >
          <Button
            sx={{
              width: selectedImage ? "100%" : "fit-content",
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              gap: 2,
              p: selectedImage ? 0 : 1.5,
            }}
            component={"label"}
          >
            {!selectedImage ? (
              <>
                <img src={UploadIcon} />
                <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
                  <MuiTypography variant="baseFont" color="text.main">
                    Click to upload
                  </MuiTypography>
                  <MuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    align="center"
                    color="text.blue"
                  >
                    PNG or JPG 
                  </MuiTypography>
                </MuiBox>
              </>
            ) : (
              <img
                src={selectedImage}
                className="img-cover"
                style={{ width: "100%", height: "400px" }}
              />
            )}
            <input
              type="file"
              onChange={handleChange}
              accept="image/png,  image/jpeg"
              hidden
            />
          </Button>
          {/* <MuiBox sx={{ width: '352px', height: '216px', borderRadius: '12px', overflow: "hidden", position: 'relative' }}>
                <IconButton sx={{ position: 'absolute', right: 8, top: 8, p: '5px', bgcolor: '#ffffff70', '&:hover': { bgcolor: '#ffffffd4' } }}>
                <img src={CloseIcon} />
                </IconButton>
                <img src={Licence} className='img-cover' />
                </MuiBox> */}
        </MuiBox>
        {formik.errors.file && formik.touched.file && (
          <MuiBox sx={{ margin: "0 auto", width: "fit-content" }}>
            <FormError errorMsg={formik.errors.file} />
          </MuiBox>
        )}
        <MuiBox sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={6}>
              <Select
                label={"Speciality Name"}
                multiple={false}
                value={formik.values.specialityId}
                onBlur={formik.handleBlur}
                required={true}
                onChange={(e) => formik.setFieldValue("specialityId", e)}
                errorMsg={formik.errors.specialityId}
                error={
                  formik.touched.specialityId && formik.errors.specialityId
                }
                placeholder="Select Specilaity"
                options={specialities?.map((item) => {
                  return {
                    label: item?.speciality,
                    value: item?.id,
                  };
                })}
              />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <WebInput
                label={"License No."}
                name={"licenceNo"}
                placeholder="Enter License No."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.licenceNo || ""}
                errorMsg={formik.errors.licenceNo}
                error={formik.touched.licenceNo && formik.errors.licenceNo}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 5 }}>
            <Grid xs={12}>
              <MuiTypography variant="subtitle" fontWeight="bold">
                Time Duration
              </MuiTypography>
            </Grid>
            <Grid xs={12} sm={12} md={6} container>
              <MuiTypography
                fontSize="14px"
                fontWeight="bold"
                sx={{ px: 2, position: "relative", bottom: "-8px" }}
              >
                Issued Date <span style={{ color: "red" }}>*</span>
              </MuiTypography>
              <Grid xs={12} container>
                <Grid xs={6}>
                  <CustomDatePicker
                    onChange={(e) =>
                      formik.setFieldValue(
                        "issuedMonth",
                        dayjs(e).format("MMMM")
                      )
                    }
                    value={
                      formik.values.issuedMonth
                        ? dayjs(`${formik.values.issuedMonth}`, "MMMM")
                        : null
                    }
                    views={["month"]}
                    error={
                      formik.errors.issuedMonth && formik.touched.issuedMonth
                    }
                    errorMsg={formik.errors.issuedMonth}
                  />
                </Grid>
                <Grid xs={6}>
                  <CustomDatePicker
                    views={["year"]}
                    value={
                      formik.values.issuedYear
                        ? dayjs(`${formik.values.issuedYear}`, "YYYY")
                        : null
                    }
                    disableFuture
                    onChange={(e) =>
                      formik.setFieldValue(
                        "issuedYear",
                        dayjs(e).format("YYYY")
                      )
                    }
                    error={
                      formik.errors.issuedYear && formik.touched.issuedYear
                    }
                    errorMsg={formik.errors.issuedYear}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={6} container>
              <MuiTypography
                fontSize="14px"
                fontWeight="bold"
                sx={{ px: 2, position: "relative", bottom: "-8px" }}
              >
                Expiry Date <span style={{ color: "red" }}>*</span>
              </MuiTypography>
              <Grid xs={12} container>
                <Grid xs={6}>
                  <CustomDatePicker
                    views={["month"]}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "expiryMonth",
                        dayjs(e).format("MMMM")
                      )
                    }
                    value={
                      formik.values.expiryMonth
                        ? dayjs(`${formik.values.expiryMonth}`, "MMMM")
                        : null
                    }
                    error={
                      formik.errors.expiryMonth && formik.touched.expiryMonth
                    }
                    errorMsg={formik.errors.expiryMonth}
                  />
                </Grid>
                <Grid xs={6}>
                  <CustomDatePicker
                    views={["year"]}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "expiryYear",
                        dayjs(e).format("YYYY")
                      )
                    }
                    value={
                      formik.values.expiryYear
                        ? dayjs(`${formik.values.expiryYear}`, "YYYY")
                        : null
                    }
                    error={
                      formik.errors.expiryYear && formik.touched.expiryYear
                    }
                    errorMsg={formik.errors.expiryYear}
                    // disablePast
                    minDate={
                      formik.values.issuedYear
                        ? dayjs(`${formik.values.issuedYear}`, "YYYY")
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiBox>
        <MuiBox sx={{ textAlign: "right", mt: 3 }}>
          <Button
            type="submit"
            sx={{ maxWidth: { xs: "100%", sm: "352px" } }}
            variant="lightButton"
          >
            {licence ? "Done" : "Add"}
          </Button>
        </MuiBox>
      </form>
    </MuiBox>
  );
};

export default AddProLicence;
