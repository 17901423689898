import React, { useEffect } from "react";
import { CustomLoader, TableContainer, TooltipProvider } from "components/atoms";
import { IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetUserAllTransHistory } from "store/middlewares/transactions";
import { useNavigate } from "react-router-dom";
import { ChevronsRight, Info } from "lucide-react";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import ModalBadgeDetail from "components/molecules/ModalBadgeDetail";

const UserShiftTab = ({ id }) => {
  const { transactions, transactionsLoading } = useSelector(
    (state) => state.me
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(GetUserAllTransHistory({ id }));
    }
  }, [dispatch, id]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Transaction Id",
        accessorKey: "transactionNo",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Hospital Name",
        accessorKey: "JobTimeSchedule.hospitalTimeSchedule.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {row?.original?.JobTimeSchedule?.hospitalTimeSchedule?.name
              ? row?.original?.JobTimeSchedule?.hospitalTimeSchedule?.name
              : "N/A"}
          </Typography>
        ),
      },
      {
        header: "Payment Type",
        accessorKey: "paymentType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {row?.original?.paymentType ? row?.original?.paymentType : "N/A"}
          </Typography>
        ),
      },
      {
        header: "Transaction Type",
        accessorKey: "transactionType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {row?.original?.transactionType
              ? row?.original?.transactionType
              : "N/A"}
          </Typography>
        ),
      },
      {
        header: "Shift Id",
        accessorKey: "JobTimeSchedule?.shiftId",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) =>
          row?.original?.JobTimeSchedule?.shiftId ? (
            <Typography
              variant="p"
              className="text-[#0CA8DF] underline cursor-pointer font-semibold"
              onClick={() =>
                navigate(`/admin/transactions/${row.original?.shiftId}`)
              }
            >
              {row.original?.JobTimeSchedule?.shiftId}
            </Typography>
          ) : (
            <Typography variant="p">
              {row?.original?.JobTimeSchedule?.shiftId ?? "N/A"}
            </Typography>
          ),
      },
      {
        header: "Credit",
        accessorKey: "credit",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <span
            className={`px-2.5 py-0.5 text-green-500 text-xs font-semibold rounded border bg-green-100 border-transparent`}
          >
            + {row.original?.credit ? row.original.credit.toFixed(2) : "0.00"}
          </span>
        ),
      },

      {
        header: "Debit",
        accessorKey: "debit",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <span
            className={`px-2.5 py-0.5 text-red-500 bg-red-100 text-xs font-semibold rounded border border-transparent`}
          >
            - {row.original?.debit ? row.original.debit.toFixed(2) : "0.00"}
          </span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) =>
          row?.original?.JobTimeSchedule?.shiftId ? (
            <IconButton
              color="info"
              disableRipple
              onClick={() =>
                navigate(`/admin/transactions/${row.original?.shiftId}`)
              }
            >
              <TooltipProvider title="Detail">
                <ChevronsRight />
              </TooltipProvider>
            </IconButton>
          ) : (
            <IconButton
              color="info"
              disableRipple
              onClick={() =>
                dispatch(
                  openAdminModal({
                    title: `Badge Detail`,
                    maxWidth: "sm",
                    content: <ModalBadgeDetail row={row?.original?.badge} />,
                  })
                )
              }
            >
              <TooltipProvider title="Detail">
                <Info />
              </TooltipProvider>
            </IconButton>
          ),
      },
    ],
    []
  );
  return (
    <>
      {!transactionsLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={transactions ? transactions : []}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UserShiftTab;
