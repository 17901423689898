import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addJobCancellation,
  deleteJobCancellation,
  getJobCancellation,
  getJobCancellationForWeb,
  updateJobCancellation,
  updateJobCancellationVisiblity,
} from "store/services/jobCancellation";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";

export const GetAllJobCancellationAction = createAsyncThunk(
  "jobCancellation/getAllJobCancellation",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getJobCancellation(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

export const GetAllJobCancellationForWebAction = createAsyncThunk(
  "jobCancellation/getAllJobCancellationForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getJobCancellationForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const DeleteJobCancellationAction = createAsyncThunk(
  "jobCancellation/deleteJobCancellation",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteJobCancellation(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

export const UpdateVisiblityJobCancellationAction = createAsyncThunk(
  "jobCancellation/updateVisiblityJobCancellation",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateJobCancellationVisiblity(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

export const UpdateJobCancellationAction = createAsyncThunk(
  "jobCancellation/updateJobCancellation",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateJobCancellation(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

export const AddJobCancellationAction = createAsyncThunk(
  "jobCancellation/addJobCancellation",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addJobCancellation(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);
