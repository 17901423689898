import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  acceptRejectDirectHire,
  getHospitalDirectHire,
  getHospitalDirectHireDetails,
  getProfessionalDirectHire,
  getProfessionalDirectHireDetails,
} from "store/services/directHire";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

// For get all hospital direct hire listings
export const GetAllHospitalDirectHire = createAsyncThunk(
  "directHire/getAllHospitalDirectHire",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getHospitalDirectHire(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For get hospital direct hire details
export const GetHospitalDirectHireDetails = createAsyncThunk(
  "directHire/getHospitalDirectHireDetails",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getHospitalDirectHireDetails(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const resObj = response.response;
        const tempRes = {
          ...resObj,
          ...resObj.jobPost,
          users: resObj?.user,
          applicantStatus: resObj?.status,
          id: resObj?.id,
        };

        return fulfillWithValue({ response: tempRes });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For get all hospital direct hire listings
export const GetAllProfessionalDirectHire = createAsyncThunk(
  "directHire/getAllProfessionalDirectHire",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getProfessionalDirectHire(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For get professional direct hire details
export const GetProfessionalDirectHireDetails = createAsyncThunk(
  "directHire/getProfessionalDirectHireDetails",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getProfessionalDirectHireDetails(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

// For get professional direct hire details
export const AcceptRejectDirectHireAction = createAsyncThunk(
  "directHire/acceptRejectDirectHireAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await acceptRejectDirectHire(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(response);
      }
      dispatch(openToast({ message: response?.message, type: "error" }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);
