import { FormError, MuiBox, MuiTypography, Textfield } from "components/atoms";
import React, { useEffect } from "react";
import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import Cancel from "assets/images/SVG/Cancel.svg";
import CheckboxBorder from "assets/images/SVG/checkbox border.svg";
import CheckboxIcon from "assets/images/SVG/check-square.svg";
import { GetAllJobCancellationForWebAction } from "store/middlewares/jobCancellation";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { CancelShiftByProfessional } from "store/middlewares/users/schedule";
const CancelModal = ({ id }) => {
  const dispatch = useDispatch();

  const { jobCancellations } = useSelector((state) => state.jobCancellations);

  useEffect(() => {
    dispatch(GetAllJobCancellationForWebAction());
  }, [dispatch]);

  const handleSubmit = (values) => {
    dispatch(CancelShiftByProfessional({ ...values, id: id }));
  };

  const formik = useFormik({
    initialValues: { cancelReasonId: "" },
    validationSchema: Yup.object().shape({
      cancelReasonId: Yup.string().required("Please select cancel Reason"),
      cancelNote: Yup.string().nullable(),
    }),
    onSubmit: handleSubmit,
  });

  const handleChange = (e) => {
    formik.setFieldValue("cancelReasonId", "");
    if (e.target.checked) {
      formik.setFieldValue("cancelReasonId", e.target.value);
    }
  };

  return (
    <MuiBox
      sx={{
        px: { xs: "10px", sm: "20px", md: "30px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
      component={"form"}
      onSubmit={formik.handleSubmit}
    >
      <MuiBox sx={{ textAlign: "center" }}>
        <MuiBox sx={{ maxWidth: "240px", m: "0 auto" }}>
          <img src={Cancel} />
        </MuiBox>
        <MuiTypography variant="h4" fontWeight={"bold"} align={"center"}>
          Cancel
        </MuiTypography>
      </MuiBox>

      <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
        {jobCancellations?.map((item) => {
          return (
            <FormControl key={item?.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item?.id === formik.values.cancelReasonId}
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                    value={item?.id}
                    onChange={handleChange}
                  />
                }
                label={item?.reason}
              />
            </FormControl>
          );
        })}
        {formik.errors.cancelReasonId && formik.touched.cancelReasonId && (
          <FormError errorMsg={formik.errors.cancelReasonId} />
        )}
      </MuiBox>

      <MuiBox>
        <Textfield
          label={"Additional Notes"}
          className={"cancel-modal-textfield"}
          rows={6}
          name={"cancelNote"}
          bgcolor="#fff"
          required={false}
          placeholder={"Enter Additional Notes"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.cancelNote}
          errorMsg={formik.errors.cancelNote}
          error={formik.touched.cancelNote && formik.errors.cancelNote}
        />
      </MuiBox>

      <MuiBox sx={{ display: "flex", gap: 4, mt: 3 }}>
        <Button type="reset" variant="darkButton">
          Cancel
        </Button>
        <Button type="submit" variant="lightButton">
          Submit
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default CancelModal;
