import React, { useState } from "react";
import MuiTypography from "@mui/material/Typography";
import MuiButton from "@mui/material/Button";
import { MuiBox } from "components/atoms";
import { handleAddressClick } from "utils/handleAddressClick";

const CommentBox = ({
  expanded = false,
  comment,
  variant,
  sx = {},
  maxChar = 100,
  isAddress = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const maxCharCount = maxChar; // Maximum characters to show when collapsed

  const handleToggle = (event) => {
    setIsExpanded(!isExpanded);
    event.preventDefault();
  };

  const truncatedText =
    comment?.length > maxCharCount && !isExpanded
      ? `${comment.slice(0, maxCharCount)}.....`
      : comment;

  return (
    <MuiTypography variant={variant} sx={{ ...sx }}>
      <MuiBox
        onClick={() => {
          if (isAddress) {
            handleAddressClick(comment);
          }
        }}
        sx={{ cursor: isAddress ? "pointer" : "default", display: "inline" }}
      >
        {truncatedText}
      </MuiBox>

      {comment?.length > maxCharCount && (
        <MuiButton
          size="small"
          sx={{ ml: "5px", p: 0, color: "#1976d2", textTransform: "none" }}
          onClick={(e) => handleToggle(e)}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </MuiButton>
      )}
    </MuiTypography>
  );
};

export default CommentBox;
