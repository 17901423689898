import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDynamicMenu,getWebDynamicMenu,updateDynamicMenu } from "store/services/dynamicMenu";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";

export const GetAllDynamicMenu = createAsyncThunk(
  "dynamicMenu/getAllDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getDynamicMenu();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

export const GetWebAllDynamicMenu = createAsyncThunk(
  "dynamicMenu/getWebAllDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getWebDynamicMenu();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const UpdateDynamicMenuAction = createAsyncThunk(
  "dynamicMenu/updateDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateDynamicMenu(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);
