import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  AddAddressForWebAction,
  DeleteAddressForWebAction,
  EditAddressForWebAction,
  GetAddressForWebAction,
  SetAddressDefault,
} from "store/middlewares/address";
import {
  AddExperienceAction,
  DeleteExperienceAction,
  EditExperienceAction,
  GetExperienceWebAction,
} from "store/middlewares/experience";
import {
  AddHolidaysAction,
  DeleteHolidaysAction,
  EditHolidaysAction,
  GetHolidaysWebAction,
} from "store/middlewares/holidays";
import {
  AddGalleryAction,
  DeleteAccountAction,
  DeleteGalleryAction,
  GetDownloadPdfAction,
  GetEarningsAction,
  GetEarningsDetailAction,
  GetGalleryAction,
  GetMeAction,
  GetMeForWebAction,
  GetRevenueAction,
  UpdateMeAction,
  UpdateMeForWebAction,
  UpdateMileageAction,
} from "store/middlewares/me";
import {
  GetAllNotification,
  GetNotificationSettings,
  UpdateNotificationSettings,
} from "store/middlewares/notifications";
import { UpdatePaymentPeriodsForWebAction } from "store/middlewares/paymentPeriods";
import {
  GetAllAdminEarnTransHistory,
  GetShiftTransactionDetailsAction,
  GetTransactionHistory,
  GetUserAllTransHistory,
} from "store/middlewares/transactions";
import {
  GetAllProfessionalReviewsAction,
  GiveReviewsProfessionalAction,
  UpdateProfessionalAvailability,
} from "store/middlewares/users/professionals";
import {
  CancelShiftByProfessional,
  GetAllShiftsForUser,
  GetIndividualShiftAction,
  GetMyShiftsCountProfessional,
  GetMyShiftsProfessionalAction,
  GetScheduleDetailsAction,
  GetScheduleShiftDetailsAction,
  GetShiftDetailsAction,
  HospitalCompleteJobAction,
  ProfessionalClockInAction,
  ProfessionalClockOutAction,
} from "store/middlewares/users/schedule";
import LocalstorageService from "utils/localstorage-services";

// isDeleteMeFulFilled
export const meSlice = createSlice({
  name: "me",
  initialState: {
    meLoading: false,
    me: null,
    meUpdating: false,
    address: [],
    addressLoading: false,
    experiences: [],
    totalExperience: 0,
    experiencesLoading: false,
    notifications: null,
    notificationsLoading: false,
    holidays: [],
    holidaysLoading: false,
    addressActionSuccess: false,
    gallery: [],
    galleryLoading: false,
    galleryUploading: false,
    schedules: null,
    scheduleLoading: false,
    singleShift: null,
    singleShiftLoading: false,
    individualShifts: null,
    individualShiftsLoading: false,
    revenue: null,
    revenueLoading: false,
    shiftCounts: [],
    shiftCountsLoading: false,
    transactions: null,
    transactionsLoading: false,
    reviews: [],
    reviewsLoading: false,
    earnings: [],
    earningsLoading: false,
    notificationsItems: null,
    notificationsItemsLoading: false,
    downloadPdf: null,
    downloadPdfLoading: false,
    reviewAdded: false,
    clockInOutLoading: false,
    badgesRequested: false
  },
  reducers: {
    setAddressAction: (state, action) => {
      state.addressActionSuccess = action.payload;
    },
    setRemainingBalance: (state, action) => {
      state.me = {...state.me, totalBalance: action.payload}
      state.badgesRequested = true
    }
  },
  extraReducers(builder) {
    builder
      .addCase(GetMeAction.pending, (state) => {
        state.meLoading = true;
        state.me = null;
      })
      .addCase(GetMeAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload.response;
      })
      .addCase(GetMeAction.rejected, (state) => {
        state.meLoading = false;
        state.me = null;
      })
      .addCase(GetMeForWebAction.pending, (state) => {
        state.meLoading = true;
        state.me = null;
      })
      .addCase(GetAllShiftsForUser.pending, (state) => {
        state.scheduleLoading =
          state?.schedules?.items?.length > 0 ? false : true;
      })
      .addCase(GetAllShiftsForUser.fulfilled, (state, action) => {
        state.scheduleLoading = false;
        state.schedules = action.payload.response;
      })
      .addCase(GetAllShiftsForUser.rejected, (state, action) => {
        state.scheduleLoading = false;
        state.schedules = action.payload.response;
      })
      .addCase(GetShiftDetailsAction.pending, (state) => {
        state.singleShiftLoading = true;
        state.singleShift = null;
      })
      .addCase(GetShiftDetailsAction.fulfilled, (state, action) => {
        state.singleShiftLoading = false;
        state.singleShift = action.payload;
      })
      .addCase(GetShiftDetailsAction.rejected, (state) => {
        state.singleShiftLoading = false;
        state.singleShift = null;
      })

      .addCase(GetScheduleDetailsAction.pending, (state) => {
        state.singleShiftLoading = true;
        state.singleShift = null;
        state.individualShifts = null;
      })
      .addCase(GetScheduleDetailsAction.fulfilled, (state, action) => {
        state.singleShiftLoading = false;
        state.singleShift = action.payload;
        state.individualShifts = null;
      })
      .addCase(GetScheduleDetailsAction.rejected, (state) => {
        state.individualShifts = null;
        state.singleShiftLoading = false;
        state.singleShift = null;
      })

      // New shifts schedule merged API
      .addCase(GetScheduleShiftDetailsAction.pending, (state) => {
        state.singleShiftLoading = true;
        state.singleShift = null;
      })
      .addCase(GetScheduleShiftDetailsAction.fulfilled, (state, action) => {
        state.singleShiftLoading = false;
        state.singleShift = action.payload.response ?? null;
      })
      .addCase(GetScheduleShiftDetailsAction.rejected, (state) => {
        state.singleShift = null;
        state.singleShiftLoading = false;
      })

      .addCase(GetIndividualShiftAction.pending, (state) => {
        state.individualShiftsLoading = true;
        state.individualShifts = null;
      })
      .addCase(GetIndividualShiftAction.fulfilled, (state, action) => {
        state.individualShiftsLoading = false;
        state.individualShifts = action.payload.response;
      })
      .addCase(GetIndividualShiftAction.rejected, (state) => {
        state.individualShiftsLoading = false;
        state.individualShifts = null;
      })

      .addCase(GetMyShiftsProfessionalAction.pending, (state) => {
        state.individualShiftsLoading = true;
        state.individualShifts = null;
      })
      .addCase(GetMyShiftsProfessionalAction.fulfilled, (state, action) => {
        state.individualShiftsLoading = false;
        state.individualShifts = { items: action.payload.response };
      })
      .addCase(GetMyShiftsProfessionalAction.rejected, (state) => {
        state.individualShiftsLoading = false;
        state.individualShifts = null;
      })

      .addCase(GetMyShiftsCountProfessional.pending, (state) => {
        state.shiftCountsLoading = true;
        state.shiftCounts = [];
      })
      .addCase(GetMyShiftsCountProfessional.fulfilled, (state, action) => {
        state.shiftCountsLoading = false;
        state.shiftCounts = action.payload.response;
      })
      .addCase(GetMyShiftsCountProfessional.rejected, (state) => {
        state.shiftCountsLoading = false;
        state.shiftCounts = [];
      })

      .addCase(ProfessionalClockInAction.pending, (state) => {
        state.clockInOutLoading = true;
      })
      .addCase(ProfessionalClockInAction.fulfilled, (state, action) => {
        state.individualShiftsLoading = false;
        state.clockInOutLoading = false;
        state.individualShifts = {
          ...state.individualShifts,
          clockInClockOut: [
            action.payload.response,
            ...state.individualShifts.clockInClockOut,
          ],
        };
      })
      .addCase(ProfessionalClockInAction.rejected, (state) => {
        state.clockInOutLoading = false;
      })

      .addCase(ProfessionalClockOutAction.pending, (state) => {
        state.clockInOutLoading = true;
      })
      .addCase(ProfessionalClockOutAction.fulfilled, (state, action) => {
        let clockInArray = state.individualShifts?.clockInClockOut?.map(
          (item) => {
            if (item?.id === action.payload.response.id) {
              return { ...item, ...action.payload.response };
            }
            return item;
          }
        );
        state.individualShiftsLoading = false;
        state.clockInOutLoading = false;
        state.individualShifts = {
          ...state.individualShifts,
          clockInClockOut: clockInArray,
        };
      })
      .addCase(ProfessionalClockOutAction.rejected, (state) => {
        state.clockInOutLoading = false;
      })

      .addCase(HospitalCompleteJobAction.fulfilled, (state) => {
        state.singleShift = { ...state.singleShift, status: "completed" };
      })

      .addCase(GetMeForWebAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload.response;
      })
      .addCase(GetMeForWebAction.rejected, (state) => {
        state.meLoading = false;
        state.me = null;
      })
      .addCase(UpdateMeForWebAction.pending, (state) => {
        state.meUpdating = true;
      })
      .addCase(UpdateMeForWebAction.rejected, (state) => {
        state.meUpdating = false;
      })
      .addCase(UpdateMeForWebAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.meUpdating = false;
        let tempObj = { ...state.me };
        if (tempObj.role === "hospital") {
          if (action.payload.response.updatedProfile) {
            tempObj = { ...tempObj, ...action.payload.response.updatedProfile };
          }
          if (action.payload.response.updateMainPerson) {
            tempObj = {
              ...tempObj,
              mainPersonDetails: action.payload.response.updateMainPerson,
            };
          }
        }
        if (tempObj?.role === "professional") {
          tempObj = { ...tempObj, ...action.payload.response };
        }
        state.me = tempObj;
      })
      .addCase(GetExperienceWebAction.pending, (state) => {
        state.experiencesLoading = true;
        state.totalExperience = 0;
        state.experiences = [];
      })
      .addCase(GetExperienceWebAction.fulfilled, (state, action) => {
        state.totalExperience = action.payload.response.totalYearOfExperience;
        state.experiencesLoading = false;
        state.experiences = action.payload.response.experiences;
      })
      .addCase(GetExperienceWebAction.rejected, (state) => {
        state.totalExperience = 0;
        state.experiencesLoading = false;
        state.experiences = [];
      })
      .addCase(AddExperienceAction.fulfilled, (state, action) => {
        state.experiencesLoading = false;
        let tempArray = [action.payload.response, ...state.experiences];
        let newTotal = 0;
        tempArray.forEach((item) => {
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
        });
        state.totalExperience = newTotal;
        state.experiences = tempArray;
      })
      .addCase(EditExperienceAction.fulfilled, (state, action) => {
        state.experiencesLoading = false;
        let newTotal = 0;
        state.experiences = state.experiences.map((item) => {
          if (item.id === action.payload.response.id) {
            newTotal += Math.abs(
              dayjs(action.payload.response.startDate).diff(
                dayjs(action.payload.response.endDate),
                "years"
              )
            );
            return { ...item, ...action.payload.response };
          }
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
          return { ...item };
        });
        state.totalExperience = newTotal;
      })
      .addCase(DeleteExperienceAction.fulfilled, (state, action) => {
        state.experiencesLoading = false;
        let newTotal = 0;
        let tempArray = state.experiences?.filter(
          (item) => item.id !== action.payload.id
        );
        tempArray?.forEach((item) => {
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
        });
        state.experiences = tempArray;
        state.totalExperience = newTotal;
      })
      .addCase(GetAddressForWebAction.pending, (state) => {
        state.addressLoading = true;
        state.address = [];
        state.addressActionSuccess = false;
      })
      .addCase(GetAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = action.payload.response;
        state.addressActionSuccess = false;
      })
      .addCase(GetAddressForWebAction.rejected, (state) => {
        state.addressLoading = false;
        state.address = [];
        state.addressActionSuccess = false;
      })
      .addCase(AddAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = [action.payload.response, ...state.address];
        state.addressActionSuccess = true;
      })
      .addCase(EditAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.addressActionSuccess = true;
        state.address = state.address.map((item) => {
          if (item.id === action.payload.response.id) {
            return { ...item, ...action.payload.response };
          }
          return { ...item };
        });
      })
      .addCase(SetAddressDefault.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = state.address.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, isDefault: true };
          }
          return { ...item, isDefault: false };
        });
      })
      .addCase(UpdateMileageAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = { ...state.me, mileage: action.payload.response.mileage };
      })
      .addCase(DeleteAccountAction.fulfilled, (state) => {
        state.meLoading = false;
        LocalstorageService.removeFromLocalStorage("aboutcare-web");
        state.me = null;
        window.location = "/";
      })
      .addCase(DeleteAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = state.address.filter(
          (item) => item?.id !== action.payload.id
        );
      })
      .addCase(GetNotificationSettings.pending, (state) => {
        state.notificationsLoading = true;
        state.notifications = null;
      })
      .addCase(GetNotificationSettings.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        state.notifications = action.payload.response;
      })
      .addCase(GetNotificationSettings.rejected, (state) => {
        state.notificationsLoading = false;
        state.notifications = null;
      })

      // For get all notifications
      .addCase(GetAllNotification.pending, (state) => {
        state.notificationsItemsLoading = true;
        state.notificationsItems = null;
      })
      .addCase(GetAllNotification.fulfilled, (state, action) => {
        state.notificationsItemsLoading = false;
        state.notificationsItems = action.payload.response;
      })
      .addCase(GetAllNotification.rejected, (state) => {
        state.notificationsItemsLoading = false;
        state.notificationsItems = null;
      })

      .addCase(UpdateNotificationSettings.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.notifications = {
          ...state.notifications,
          ...action.payload.response,
        };
      })
      .addCase(UpdateMeAction.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        let { me } = state;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
      })
      .addCase(GetHolidaysWebAction.pending, (state) => {
        state.holidaysLoading = true;
        state.holidays = [];
      })
      .addCase(GetHolidaysWebAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = action.payload.response;
      })
      .addCase(GetHolidaysWebAction.rejected, (state) => {
        state.holidaysLoading = false;
        state.holidays = [];
      })
      .addCase(AddHolidaysAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = [action.payload.response, ...state.holidays];
      })
      .addCase(EditHolidaysAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = state.holidays.map((item) => {
          if (item.id === action.payload.response.id) {
            return { ...item, ...action.payload.response };
          }
          return { ...item };
        });
      })
      .addCase(DeleteHolidaysAction.fulfilled, (state, action) => {
        let tempArray = state.holidays?.filter(
          (item) => item.id !== action.payload.id
        );
        state.holidays = tempArray;
      })

      .addCase(GetGalleryAction.pending, (state) => {
        state.galleryLoading = true;
        state.gallery = [];
      })
      .addCase(GetGalleryAction.fulfilled, (state, action) => {
        state.galleryLoading = false;
        state.gallery = action.payload.response;
      })
      .addCase(GetGalleryAction.rejected, (state) => {
        state.galleryLoading = false;
        state.gallery = [];
      })

      .addCase(AddGalleryAction.pending, (state) => {
        state.galleryUploading = true;
      })
      .addCase(AddGalleryAction.fulfilled, (state, action) => {
        state.galleryUploading = false;
        state.gallery = [...state.gallery, action.payload.response];
      })
      .addCase(AddGalleryAction.rejected, (state) => {
        state.galleryUploading = false;
      })

      .addCase(DeleteGalleryAction.fulfilled, (state, action) => {
        state.gallery = state.gallery.filter(
          (item) => item.id !== action.payload.id
        );
      })

      .addCase(UpdatePaymentPeriodsForWebAction.fulfilled, (state, action) => {
        state.me = {
          ...state.me,
          paymentPeriodId: action.payload.paymentPeriodId,
        };
      })

      .addCase(CancelShiftByProfessional.pending, (state) => {
        state.singleShiftLoading = true;
      })
      .addCase(CancelShiftByProfessional.fulfilled, (state) => {
        state.singleShiftLoading = false;
        state.singleShift = {
          ...state.singleShift,
          status: "cancelled",
        };
      })
      .addCase(GetRevenueAction.pending, (state) => {
        state.revenueLoading = true;
        state.revenue = null;
      })
      .addCase(GetRevenueAction.fulfilled, (state, action) => {
        state.revenueLoading = false;
        state.revenue = action.payload.response;
      })
      .addCase(GetRevenueAction.rejected, (state) => {
        state.revenueLoading = false;
        state.revenue = null;
      })
      .addCase(UpdateProfessionalAvailability.fulfilled, (state, action) => {
        state.me = { ...state.me, ...action.payload.response };
      })
      .addCase(GetTransactionHistory.pending, (state) => {
        state.transactionsLoading = true;
        state.transactions = null;
      })
      .addCase(GetTransactionHistory.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactions = action.payload.response;
      })
      .addCase(GetTransactionHistory.rejected, (state) => {
        state.transactionsLoading = false;
        state.transactions = null;
      })

      // get all admin earning transction
      .addCase(GetAllAdminEarnTransHistory.pending, (state) => {
        state.transactionsLoading = true;
        state.transactions = null;
      })
      .addCase(GetAllAdminEarnTransHistory.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactions = action.payload.response;
      })
      .addCase(GetAllAdminEarnTransHistory.rejected, (state) => {
        state.transactionsLoading = false;
        state.transactions = null;
      })

      // get spacific user transction
      .addCase(GetUserAllTransHistory.pending, (state) => {
        state.transactionsLoading = true;
        state.transactions = null;
      })
      .addCase(GetUserAllTransHistory.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactions = action.payload.response;
      })
      .addCase(GetUserAllTransHistory.rejected, (state) => {
        state.transactionsLoading = false;
        state.transactions = null;
      })

      // get Get Shift Transaction Details transction
      .addCase(GetShiftTransactionDetailsAction.pending, (state) => {
        state.transactionsLoading = true;
        state.transactions = null;
      })
      .addCase(GetShiftTransactionDetailsAction.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactions = action.payload.response;
      })
      .addCase(GetShiftTransactionDetailsAction.rejected, (state) => {
        state.transactionsLoading = false;
        state.transactions = null;
      })

      .addCase(GetEarningsAction.pending, (state) => {
        state.earningsLoading = true;
        state.earnings = [];
      })
      .addCase(GetEarningsAction.fulfilled, (state, action) => {
        state.earningsLoading = false;
        state.earnings = action.payload.response;
      })
      .addCase(GetEarningsAction.rejected, (state) => {
        state.earningsLoading = false;
        state.earnings = [];
      })

      .addCase(GetEarningsDetailAction.pending, (state) => {
        state.earningsLoading = true;
        state.earnings = [];
      })
      .addCase(GetEarningsDetailAction.fulfilled, (state, action) => {
        state.earningsLoading = false;
        state.earnings = action.payload.response;
      })
      .addCase(GetEarningsDetailAction.rejected, (state) => {
        state.earningsLoading = false;
        state.earnings = [];
      })

      .addCase(GetAllProfessionalReviewsAction.pending, (state) => {
        state.reviewsLoading = true;
        state.reviews = [];
      })
      .addCase(GetAllProfessionalReviewsAction.fulfilled, (state, action) => {
        state.reviewsLoading = false;
        state.reviews = action.payload.response;
      })
      .addCase(GetAllProfessionalReviewsAction.rejected, (state) => {
        state.reviewsLoading = false;
        state.reviews = [];
      })

      .addCase(GetDownloadPdfAction.pending, (state) => {
        state.downloadPdfLoading = true;
        state.downloadPdf = null;
      })
      .addCase(GetDownloadPdfAction.fulfilled, (state, action) => {
        state.downloadPdfLoading = false;
        state.downloadPdf = action.payload.response;
      })
      .addCase(GetDownloadPdfAction.rejected, (state) => {
        state.downloadPdfLoading = false;
        state.downloadPdf = null;
      })

      .addCase(GiveReviewsProfessionalAction.pending, (state) => {
        state.reviewAdded = false;
      })
      .addCase(GiveReviewsProfessionalAction.fulfilled, (state) => {
        state.reviewAdded = true;
      });
  },
});

export const { setAddressAction, setRemainingBalance } = meSlice.actions;

export default meSlice.reducer;
