import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";
import {
  updateMe,
  getMe,
  changePassword,
  getMeForWeb,
  updateMeForWeb,
  updateMileage,
  deleteAccount,
  getGallery,
  addGallery,
  deleteGallery,
  getRevenue,
  getEarnings,
  getEarningDetail,
  getDownloadPdf,
} from "store/services/me.services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "store/slices/common/toast.slice";
import { uploadFile } from "store/services/utilities";
import { closeDrawer } from "store/slices/common/drawer.slice";

export const GetMeAction = createAsyncThunk(
  "me/getMe",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getMe(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const GetMeForWebAction = createAsyncThunk(
  "me/getMeForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getMeForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const UpdateMileageAction = createAsyncThunk(
  "me/upadateMileage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateMileage(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const DeleteAccountAction = createAsyncThunk(
  "me/deleteAccount",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteAccount(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const UpdateMeForWebAction = createAsyncThunk(
  "me/updateMeForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      if (file) {
        const uploadFilesResponse = await uploadFile({
          file,
          uploadFor: "profile",
        });
        rest = {
          ...rest,
          profileImage: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const response = await updateMeForWeb(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const UpdateMeAction = createAsyncThunk(
  "me/updateMe",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateMe(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, data: payload });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const ChangePasswordAction = createAsyncThunk(
  "me/changePasswordAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await changePassword(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const GetGalleryAction = createAsyncThunk(
  "me/getGalleryAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getGallery(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const DeleteGalleryAction = createAsyncThunk(
  "me/deleteGalleryAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteGallery(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const AddGalleryAction = createAsyncThunk(
  "me/addGalleryAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const uploadFilesResponse = await uploadFile({
        file: payload.file,
        uploadFor: "gallery",
      });
      if (uploadFilesResponse.status === 1) {
        const response = await addGallery({
          image: uploadFilesResponse?.response?.urls?.[0],
        });
        if (response.status === 1) {
          // if any async operation need to be done on response data, do it here,
          // then full fill data
          return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
      }
      return rejectWithValue({ message: uploadFilesResponse?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const GetRevenueAction = createAsyncThunk(
  "me/getRevenueAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getRevenue(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const GetEarningsAction = createAsyncThunk(
  "me/getEarningsAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getEarnings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const GetEarningsDetailAction = createAsyncThunk(
  "me/getEarningDetailsAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getEarningDetail(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const GetDownloadPdfAction = createAsyncThunk(
  "me/getDownloadPdfAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getDownloadPdf(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        handleAuthError({
          message: error?.response?.data?.message,
          status: error?.response?.status,
          isWeb: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);
