import React from "react";
import MuiTypography from "../MuiTypography";

const StatusPill = ({ status, statusText, isWeb = false }) => {
  const colorObj = {
    accepted: "!border-green-400 text-green-500",
    true: "!border-sky-400 text-sky-500",
    rejected: "!border-red-400 text-red-500",
    pending: "!border-orange-400 text-orange-500",
  };
  const bgcolorObj = {
    accepted: "!bg-green-500",
    rejected: "!bg-red-500",
    pending: "!bg-orange-500",
    true: "!bg-sky-500",
  };

  const webbgcolorObj = {
    accepted: "!bg-[#07B67C]",
    rejected: "!bg-[#FF4A4D]",
  };
  
  return (
    <div
      className={`flex items-center px-2.5 py-0.5 text-md capitalize font-medium rounded border bg-white  ${colorObj[status]}`}
    >
      <div
        className={`size-1.5 mr-2 rounded-full inline-block ${!isWeb ? bgcolorObj[status] : webbgcolorObj[status]} `}
      ></div>
      {isWeb ? (
        <MuiTypography variant="baseFont" sx={{ color: status === "accepted" ? "successButton.dark" : "error.dark" }}>
          {statusText}
        </MuiTypography>
      ) : (
        statusText
      )}
    </div>
  );
};

export default StatusPill;
