import { createSlice } from "@reduxjs/toolkit";
import {
  AddCountryAction,
  DeleteCountryAction,
  GetAllCountriesAction,
  GetAllCountriesForWebAction,
  UpdateCountryAction,
  UpdateDefaultCountryAction,
  UpdateVisiblityCountryAction,
} from "store/middlewares/countries";

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    countriesLoading: false,
    countries: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllCountriesAction.pending, (state) => {
        state.countriesLoading = true;
        state.countries = [];
      })
      .addCase(GetAllCountriesAction.fulfilled, (state, action) => {
        state.countriesLoading = false;
        state.countries = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCountriesAction.rejected, (state) => {
        state.countriesLoading = false;
        state.countries = [];
      })
      .addCase(GetAllCountriesForWebAction.pending, (state) => {
        state.countriesLoading = true;
        state.countries = [];
      })
      .addCase(GetAllCountriesForWebAction.fulfilled, (state, action) => {
        state.countriesLoading = false;
        state.countries = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCountriesForWebAction.rejected, (state) => {
        state.countriesLoading = false;
        state.countries = [];
      })
      // Delete Job Title
      .addCase(DeleteCountryAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.countries?.filter((title) => title.id !== id);
        state.countriesLoading = false;
        state.countries = tempArray;
      })
      //Update Job Title
      .addCase(UpdateCountryAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.countries?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.countriesLoading = false;
        state.countries = tempArray;
      })
      .addCase(UpdateVisiblityCountryAction.fulfilled, (state, action) => {
        state.countriesLoading = false;
        let { id } = action.payload;
        let tempArray = state.countries?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.countries = tempArray;
      })
      .addCase(UpdateDefaultCountryAction.fulfilled, (state, action) => {
        state.countriesLoading = false;
        let { id } = action.payload;
        let tempArray = state.countries?.map((title) => {
          if (title.id === id) {
            return { ...title, isDefault: true };
          }
          return { ...title, isDefault: false };
        });
        state.countries = tempArray;
      })
      //Add Job Title
      .addCase(AddCountryAction.fulfilled, (state, action) => {
        state.countriesLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.countries = [...state.countries, Obj];
      });
  },
});

export default countriesSlice.reducer;
