import React, { useMemo } from "react";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

// Register Chart.js components, including Filler
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Filler);

const LineChart = ({ revenueType, revenueData }) => {
    const dynamicLabels = useMemo(() => {
        if (!revenueData || revenueData.length === 0) return [];
        if (revenueType === "weekly") {
            return revenueData.map(({ date }) => dayjs(date).format("ddd"));
        } else if (revenueType === "monthly") {
            return revenueData.map(({ date }) => dayjs(date).format("D"));
        } else if (revenueType === "yearly") {
            return revenueData.map(({ date }) => dayjs(date).format("MMM"));
        }
        return [];
    }, [revenueType, revenueData]);


    const labelsMap = { weekly: "Weekly", monthly: "Monthly", yearly: "Yearly" };
    const dynamicDatasetLabel = labelsMap[revenueType] || "Revenue";


    // For get min-max values
    const minRevenue = useMemo(() => {
        if (!revenueData || revenueData.length === 0) return 0;
        return Math.min(...revenueData.map((d) => d.totalRevenue));
    }, [revenueData]);
    const maxRevenue = useMemo(() => {
        if (!revenueData || revenueData.length === 0) return 100;
        return Math.max(...revenueData.map((d) => d.totalRevenue));
    }, [revenueData]);

    // Use useMemo to create the data object
    const data = useMemo(() => {
        return {
            labels: dynamicLabels,
            datasets: [
                {
                    label: dynamicDatasetLabel,
                    data: revenueData?.map((d) => d.totalRevenue) || [],
                    borderColor: "#FF3030",
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
                        gradient.addColorStop(0, "rgba(126, 72, 193, 0.3)");
                        gradient.addColorStop(1, "rgba(254, 86, 89, 0.3)");
                        return gradient;
                    },
                    fill: "start",
                    pointStyle: "circle",
                    pointRadius: 8,
                    pointHoverRadius: 12,
                    pointBackgroundColor: "#FFFFFF",
                    pointBorderColor: "#FF3030",
                    pointBorderWidth: 2,
                },
            ],
        };
    }, [dynamicLabels, dynamicDatasetLabel, revenueData]);

    // Chart options with boundaries
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            y: {
                min: Math.floor(minRevenue * 0.9),
                max: Math.ceil(maxRevenue * 1.1),
                grid: {
                    borderDash: [5, 5],
                    color: "rgba(200, 200, 200, 0.5)",
                },
            },
            x: {
                grid: {
                    borderDash: [5, 5],
                    color: "rgba(200, 200, 200, 0.5)",
                },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChart;
