import { useState } from "react";
import { Document, Page } from "react-pdf";
import React from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import CustomLoader from "../CustomLoader";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
function PDFViewer({ file, width }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document loading={<CustomLoader />} file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page loading={<CustomLoader />} key={`page_${index + 1}`} pageNumber={index + 1}  width={width} renderAnnotationLayer={false} renderTextLayer={false}/>
        ))}
      </Document>
    </div>
  );
}

export default PDFViewer;
