import React from "react";

const Input = ({
  onChange,
  onBlur,
  type = "text",
  value,
  error,
  errorMsg,
  placeholder,
  label,
  className,
  name,
  step,
  inputIcon,
  inputClassName,
  labelClassName,
  disabled = false,
  isRequired = true,
  inputRef,
  ...rest
}) => {
  return (
    <div className={`py-2 ${className}`}>
      {typeof label === "string" ? (
        <>
          <label htmlFor={name} className={`font-medium ${labelClassName}`}>
            {label} {isRequired && <span className="text-[#FF5C5C]">*</span>}
          </label>
          <br />
        </>
      ) : (
        label
      )}
      <div className="relative">
        {inputIcon}
        <input
          ref={inputRef}
          disabled={disabled}
          type={type}
          name={name}
          step={step}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          value={value}
          placeholder={placeholder}
          className={`form-input focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300 disabled:text-slate-500 placeholder:text-slate-400 search-input-border-style ${inputClassName} ${
            error
              ? "border-red-500 search-input-border-style-error"
              : "border-slate-200"
          } `}
          {...rest}
        />
        {error ? (
          <div className="mt-1 text-sm text-red-500 ">{errorMsg}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
