import { MuiBox, MuiTypography } from "components/atoms";
import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DeleteJobForWebAction } from "store/middlewares/jobs";
import { useDispatch } from "react-redux";
import { openDrawer } from "store/slices/common/drawer.slice";
import ConfirmModal from "../ConfirmModal";
import CalanderBlack from "assets/images/SVG/CalendarBlack.svg";
import DollarBlue from "assets/images/SVG/DollarBlue.svg";
import People from "assets/images/SVG/people.svg";
import EditIcon from "assets/images/SVG/EditLight.svg";
import DeleteIcon from "assets/images/SVG/DeleteLight.svg";
import { ApplyHospitalJobAction, WithdrawHospitalJobAction } from "store/middlewares/users/professionals";
import shiftTypeObj from "utils/shiftTypeObj";
import dayjs from "dayjs";
import { applicationStatusColor, statusText } from "utils/jobApplicationStatus";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const MyJobsCard = ({ type, job }) => {
  const typeProfessional = type === "professional";
  const navigate = useNavigate();

  // For job applied
  const [applied, setApplied] = useState(false);
  const handleApplyClick = (e) => {
    e.preventDefault();
    if (!applied) {
      dispatch(ApplyHospitalJobAction({ jobId: job?.id }));
    }
    if (applied && job?.type === "applied") {
      dispatch(WithdrawHospitalJobAction({ id: job?.id }));
    }
  };
  useEffect(() => {
    if (job?.type === 'applied') {
      setApplied(true);
    } else {
      setApplied(false);
    }
  }, [job]);

  const dispatch = useDispatch();

  return (
    <MuiBox
      component={Link}
      to={
        typeProfessional
          ? `/professional/application-details/${job?.id}`
          : `${job?.id}`
      }
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        borderLeft: "2px solid #0CA8DF",
        height: "100%",
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            overflow: "hidden",
          }}
        >
          <MuiTypography
            variant="h6"
            color="text.main"
            sx={{ overflow: "hidden", maxWidth: "300px", whiteSpace: "nowrap" }}
          >
            {job?.JobPostDetails?.shiftTitle}
          </MuiTypography>
        </MuiBox>
        <MuiBox
          sx={{
            width: "fit-content",
            maxWidth: "11em",
            display: "flex",
            gap: "5px",
            mt: 1,
            alignItems: "center",
          }}
        >
          <img
            src={People}
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          <MuiTypography color="text.main" variant="baseFont">
            {job?.JobPostDetails?.multipleOpening}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <MuiBox
            sx={{
              height: "42px",
              width: "42px",
              bgcolor: "#E8F0F3",
              flexShrink: 0,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={CalanderBlack} />
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              maxWidth: "11em",
              gap: "1px",
              flexDirection: "column",
            }}
          >
            <MuiTypography color="text.main" fontSize="15px" fontWeight="bold">
              {" "}
              {dayjs(job?.JobDateSchedule?.[0]?.startDate).format(
                "DD/MM/YYYY"
              )}{" "}
              - {dayjs(job?.JobDateSchedule?.[0]?.endDate).format("DD/MM/YYYY")}
            </MuiTypography>
            <MuiTypography
              color="text.contrastText"
              fontSize="13px"
              variant="baseFont"
            >
              {dayjs(
                job?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.startTime
              ).format("HH:mm")}{" "}
              -{" "}
              {dayjs(
                job?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.endTime
              ).format("HH:mm")}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox
          sx={{
            width: "fit-content",
            maxWidth: "11em",
            display: "flex",
            gap: "5px",
            mt: 1,
            alignItems: "center",
          }}
        >
          <img
            src={DollarBlue}
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          <MuiTypography color="text.main" variant="baseFont">
            {job?.JobPostDetails?.employeeHourRate?.toFixed(2)}/hr
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          mt: "auto",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
            // p: 1,
          }}
        >
          <MuiBox
            sx={{
              width: "fit-content",
              maxWidth: "247px",
              p: "5px 12px",
              bgcolor: "#007AFF26",
              borderRadius: "30px",
            }}
          >
            <MuiTypography
              color="#2D2635"
              fontSize="15px"
              sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
            >
              {job?.jobSkills?.[0]?.name}
            </MuiTypography>
          </MuiBox>
          <MuiBox
            sx={{
              width: "fit-content",
              p: "5px 12px",
              borderRadius: "30px",
              bgcolor: "#ffdfc3",
            }}
          >
            <MuiTypography
              color="#2D2635"
              fontSize="15px"
              sx={{ whiteSpace: "nowrap" }}
            >
              {shiftTypeObj[job?.JobPostDetails?.shiftType]}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        {type === "professional" ? (
          <Button
            variant={"lightButton"}
            sx={{
              width: "fit-content",
              p: "5px 12px",
              bgcolor: applicationStatusColor[job?.type],
              "&:hover": {
                bgcolor: applicationStatusColor[job?.type],
              },
            }}
            onClick={handleApplyClick}
          >
            {statusText[job?.type]}
          </Button>
        ) : (
          <>
            {job?.acceptedApplicants === 0 ? (
              <MuiBox
                sx={{ display: "flex", gap: "12px", width: "fit-content" }}
              >
                <IconButton
                  disableRipple
                  aria-label="phone"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/hospital/jobs/post-job?id=${job?.id}`);
                  }}
                  sx={{
                    height: "30px",
                    width: "30px",
                    bgcolor: "darkButton.main",
                    transition: "all 0.3s",
                    borderRadius: "6px",
                    overflow: "hidden",
                    p: "5px",
                    "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
                  }}
                >
                  <img src={EditIcon} />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(
                      openDrawer({
                        content: (
                          <ConfirmModal
                            type="delete"
                            title="Delete Job"
                            message={
                              <>
                                Are you sure you want to delete this job? <br />
                                This action is permanent and cannot be undone.
                              </>
                            }
                            onClick={() =>
                              dispatch(DeleteJobForWebAction({ id: job.id }))
                            }
                            btnText={"Delete"}
                          />
                        ),
                      })
                    );
                  }}
                  aria-label="phone"
                  sx={{
                    height: "30px",
                    width: "30px",
                    bgcolor: "darkButton.main",
                    transition: "all 0.3s",
                    borderRadius: "6px",
                    overflow: "hidden",
                    p: "5px",
                    "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
                  }}
                >
                  <img src={DeleteIcon} />
                </IconButton>
              </MuiBox>
            ) : (
              <MuiBox
                sx={{
                  width: "fit-content",
                  bgcolor: "successButton.main",
                  p: "5px 12px",
                  borderRadius: "30px",
                }}
              >
                <MuiTypography color="input.contrastText" fontWeight={"bold"}>
                  Hired - {job?.acceptedApplicants}
                </MuiTypography>
              </MuiBox>
            )}
          </>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default MyJobsCard;
