import axiosClient from "utils/api";
const URI = "/admin/schedule";

// For get all jobs
export const getAllUserShifts = async (payload) => {
  let { role, ...rest } = payload;
  let URL = `${role}Schedule/${role}AllJobsShifts`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
      params: rest,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured Get All Shifts For ${role}:`, error.message);
    throw error;
  }
};

// For get shift details
export const getShiftDetails = async (payload) => {
  let { id, role } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/professionalShiftDetails/${id}`
      : `hospitalSchedule/hospitalShiftDetails/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured Get Shift Details :`, error.message);
    throw error;
  }
};


export const getScheduleShiftDetails = async (payload) => {
  let { shiftId, scheduleId, role } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/professionalShiftAndScheduleDetails/${shiftId}/${scheduleId}`
      : `hospitalSchedule/hospitalShiftAndScheduleDetails/${shiftId}/${scheduleId}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured Get Shift Details :`, error.message);
    throw error;
  }
};

// For get schedule details
export const getScheduleDetails = async (payload) => {
  let { id, role } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/professionalScheduleDetails/${id}`
      : `hospitalSchedule/hospitalScheduleDetails/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured Get Schedule Details :`, error.message);
    throw error;
  }
};

// For Get shifts Data For User
export const getProfessionalUserAllShifts = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/shiftDetailsOfUser/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get swifts:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const getProfessionalUserShiftDetail = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/shiftDetails/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get swifts:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const getProfessionalUserScheduleDetail = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/scheduleDetails/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Schedule By Id:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const getMyShiftsProfessional = async (payload) => {
  const URL = "/professionalSchedule/myShift";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Schedule By Id:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const getMyShiftsCountProfessional = async (payload) => {
  const URL = "/professionalSchedule/getMyShiftCount";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Schedule By Id:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const cancelProfessionalUserSchedule = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/cancelSchedule/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Cancel Schedule:", error);
    throw error;
  }
};

// For Get shifts Data For User
export const cancelProfessionalUserShift = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/cancelShift/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Cancel Shift:", error);
    throw error;
  }
};

// For
export const cancelShiftProfessional = async (payload) => {
  let { id, role, ...rest } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/cancelShift/${id}`
      : `hospitalSchedule/cancelShift/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error(
      `Error occuured Cancel Shift By Professional :`,
      error.message
    );
    throw error;
  }
};

// // For
// export const cancelScheduleProfessional = async (payload) => {
//   let { id, role, ...rest } = payload;
//   let URL =
//     role === "professional"
//       ? `professionalSchedule/cancelSchedule/${id}`
//       : `hospitalSchedule/cancelSchedule/${id}`;
//   try {
//     const res = await axiosClient().request({
//       url: URL,
//       method: "put",
//       data: rest,
//     });

//     return res.data;
//   } catch (error) {
//     console.error(
//       `Error occuured Cancel Shift By Professional :`,
//       error.message
//     );
//     throw error;
//   }
// };

// For get individual shift for user
export const getIndividualShift = async (payload) => {
  let URL = `professionalSchedule/individualShift`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
      params: payload,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured get indiviuals shift :`, error.message);
    throw error;
  }
};

// For get individual shift for user
export const professionalClockIn = async (payload) => {
  let { role, ...rest } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/startJob`
      : `/hospitalSchedule/clockIn`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "POST",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured at Professional Clock In :`, error.message);
    throw error;
  }
};

// For get individual shift for user
export const professionalClockOut = async (payload) => {
  let { id, role, ...rest } = payload;
  let URL =
    role === "professional"
      ? `professionalSchedule/endJob/${id}`
      : `/hospitalSchedule/clockOut/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "PUT",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured at Professional Clock Out :`, error.message);
    throw error;
  }
};

// For get individual shift for user
export const hospitalCompleteJob = async (payload) => {
  let URL = `/hospitalSchedule/completeProfessionalJob`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "PUT",
      data: payload,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured at Complete Job :`, error.message);
    throw error;
  }
};


// For Get on Going Shift List
export const getOnGoingShiftList = async (payload) => {
  const URL = `${URI}/onGoingShift`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during on Going Shift List:", error);
    throw error;
  }
};