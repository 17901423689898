import React, { useEffect, useRef, useState } from "react";
import {
  BackButtonHeader,
  WebInput,
  MuiBox,
  useGooglePlaces,
} from "components/atoms";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as Yup from "yup";
import LocationIcon from "assets/images/SVG/LocationIcon.svg";
import { useFormik } from "formik";
import {
  city,
  country,
  firstAddress,
  secondAddress,
  state,
  zipCode,
} from "utils/validation/web/HospitalForm";
import {
  AddCredentialPhysicalAddressWebAction,
  EditCredentialPhysicalAddressWebAction,
} from "store/middlewares/credential/web";
import { useDispatch, useSelector } from "react-redux";

const AddPhysicalAddress = ({ selectedAddress, backFunc }) => {
  const dispatch = useDispatch();

  const { credentialActionSuccess } = useSelector((state) => state.credential);
  const [initialVal, setInitialVal] = useState({
    firstAddress: "",
    secondAddress: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });

  useEffect(() => {
    if (selectedAddress) {
      setInitialVal({
        firstAddress: selectedAddress?.firstAddress,
        secondAddress: selectedAddress?.secondAddress,
        city: selectedAddress?.city,
        state: selectedAddress?.state,
        country: selectedAddress?.country,
        zipCode: selectedAddress?.zipCode,
      });
    } else {
      setInitialVal({
        firstAddress: "",
        secondAddress: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      });
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (credentialActionSuccess) {
      backFunc();
    }
  }, [credentialActionSuccess]);

  const AddressSchema = Yup.object().shape({
    firstAddress: firstAddress,
    secondAddress: secondAddress,
    city: city,
    state: state,
    country: country,
    zipCode: zipCode,
  });

  const handleSubmit = (values) => {
    if (selectedAddress) {
      dispatch(
        EditCredentialPhysicalAddressWebAction({
          ...values,
          id: selectedAddress?.id,
          secondAddress: values.secondAddress ? values.secondAddress : null,
          zipCode: `${values?.zipCode}`,
        })
      );
    } else {
      dispatch(
        AddCredentialPhysicalAddressWebAction({
          ...values,
          zipCode: `${values?.zipCode}`,
          secondAddress: values.secondAddress ? values.secondAddress : null,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialVal,
    validationSchema: AddressSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // ---------------- Google Place API -----------------//
  const inputRef = useRef(null);
  const { initializePlaces } = useGooglePlaces();

  const handleChange = (placeDetails) => {
    // ---------------- set value -----------------//
    formik.setFieldValue(
      "firstAddress",
      placeDetails?.firstAddress ? placeDetails?.firstAddress : ""
    );
    formik.setFieldValue(
      "secondAddress",
      placeDetails?.secondAddress ? placeDetails?.secondAddress : ""
    );
    formik.setFieldValue("city", placeDetails?.city ? placeDetails?.city : "");
    formik.setFieldValue(
      "state",
      placeDetails?.state ? placeDetails?.state : ""
    );
    formik.setFieldValue(
      "country",
      placeDetails?.country ? placeDetails?.country : ""
    );
    formik.setFieldValue(
      "zipCode",
      placeDetails?.zipCode ? placeDetails?.zipCode : ""
    );
    formik.setFieldValue(
      "latitude",
      placeDetails.latLng.lat ? `${placeDetails.latLng.lat}` : ""
    );
    formik.setFieldValue(
      "longitude",
      placeDetails.latLng.lng ? `${placeDetails.latLng.lng }`: ""
    );

    // ---------------- set value -----------------//
  };

  return (
    <MuiBox>
      <BackButtonHeader
        title={selectedAddress ? "Edit Address" : "Add Address"}
        backButtonFunc={() => backFunc()}
      />
      <form
        onSubmit={formik.handleSubmit}
        style={{
          backgroundColor: "#fff",
          padding: "15px",
          borderRadius: "12px",
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              ref={inputRef}
              label="Address 1"
              required={true}
              name={"firstAddress"}
              icon={<img src={LocationIcon} />}
              placeholder={"Enter Address 1"}
              value={formik.values.firstAddress}
              onChange={(e) => {
                formik.handleChange(e);
                initializePlaces(inputRef, handleChange);
              }}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.firstAddress}
              error={formik.touched.firstAddress && formik.errors.firstAddress}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              label="Address 2"
              required={false}
              icon={<img src={LocationIcon} />}
              placeholder={"Enter Address 2"}
              name={"secondAddress"}
              value={formik.values.secondAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.secondAddress}
              error={
                formik.touched.secondAddress && formik.errors.secondAddress
              }
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              required={true}
              label="Country"
              icon={<img src={LocationIcon} />}
              placeholder={"Enter Country"}
              name={"country"}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.country}
              error={formik.touched.country && formik.errors.country}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              label="State"
              name={"state"}
              placeholder={"Enter State"}
              icon={<img src={LocationIcon} />}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.state}
              error={formik.touched.state && formik.errors.state}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              label="City"
              placeholder={"Enter City"}
              icon={<img src={LocationIcon} />}
              value={formik.values.city}
              name={"city"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.city}
              error={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <WebInput
              label="ZIP Code"
              type="text"
              isNumeric={true}
              icon={<img src={LocationIcon} />}
              placeholder={"Enter Zip Code"}
              value={formik.values.zipCode}
              name={"zipCode"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.errors.zipCode}
              error={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Grid>
        </Grid>
        <MuiBox sx={{ textAlign: "right", mt: 3 }}>
          <Button
            type="submit"
            sx={{ maxWidth: { xs: "100%", sm: "352px" } }}
            variant="lightButton"
          >
            {selectedAddress ? "Update" : "Add"}
          </Button>
        </MuiBox>
      </form>
    </MuiBox>
  );
};

export default AddPhysicalAddress;
