import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const name = Yup.string()
  .required("Please enter certificate name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(
    onlySpaceRegExp,
    "Certificate Name should contain at least one character"
  );
export const certificateNumber = Yup.string()
  .required("Please enter certificate number")
  .min(4, "Certificate no must be at least 4 characters long")
  .max(18, "Certificate no must be at most 18 characters long");

export const expiryDate = Yup.string().required("Please enter expiration date");

export const File = Yup.mixed()
  .required("Please upload picture")
  .test(
    "fileSize",
    "Please select a valid image file with size up to 3MB",
    (value) => {
      return value && value.size <= 3 * 1024 * 1024; // 3MB limit
    }
  );
