export const credentialStatusColorObj = {
  rejected: "#FF5C5C",
  accepted: "#00AE46",
  pending: "#E18308",
};

export const credentialStatusTextObj = {
  rejected: "Rejected",
  accepted: "Accepted",
  pending: "In Review",
};

export const applicationStatusText = {
  rejected: "Rejected",
  accepted: "Hired",
  pending: "Pending",
};

