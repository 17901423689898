import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllCredentialAvatarAction,
  GetAllCredentialLicencesAction,
  ManageAllCredentialAvatarAction,
  ManageAllCredentialLicencesAction,
  GetAllCredentialCertificateAction,
  GetAllCredentialServiceLicenceAction,
  GetAllCredentialSocialSecurityAction,
  ManageAllCredentialCertificateAction,
  ManageAllCredentialServiceLicenceAction,
  ManageAllCredentialSocialSecurityAction,
  GetProfessionalUserResume,
  UpdateProfessionalUserResume,
  GetAllCredentialAddressAction,
  ManageAllCredentialAddressAction,
  GetAllCredentialsAction,
  GetAllCredentialJobTitle,
  ManageCredentialJobTitle,
} from "store/middlewares/credential";
import {
  AddCredentialAvatarWebAction,
  AddCredentialCertificateWebAction,
  AddCredentialDriverLicenceForWeb,
  AddCredentialJobTitleWebAction,
  AddCredentialPhysicalAddressWebAction,
  AddCredentialProLicenceWebAction,
  AddCredentialResumeForWeb,
  AddCredentialSocSecWebAction,
  DeleteCredentialAvatarWebAction,
  DeleteCredentialCertificateWebAction,
  DeleteCredentialPhysicalAddressWebAction,
  DeleteCredentialProLicenceWebAction,
  DeleteCredentialResumeForWeb,
  EditCredentialAvatarWebAction,
  EditCredentialCertificateWebAction,
  EditCredentialDriverLicenceForWeb,
  EditCredentialPhysicalAddressWebAction,
  EditCredentialProLicenceWebAction,
  EditCredentialResumeForWeb,
  EditCredentialSocSecWebAction,
  GetAllCredentialAvatarWebAction,
  GetAllCredentialCertificateWebAction,
  GetAllCredentialPhysicalAddressWebAction,
  GetAllCredentialProLicenceWebAction,
  GetAllCredentialSocSecWebAction,
  GetCredentialDriverLicenceForWeb,
  GetCredentialJobTitleWebAction,
  GetCredentialResumeByIdForWeb,
  GetCredentialResumeForWeb,
  SetDetfaultCredentialPhysicalAddressWebAction,
  SetDetfaultCredentialResumeWebAction,
  UpdateCredentialJobTitleWebAction,
} from "store/middlewares/credential/web";

export const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    avatarLoading: false,
    licenceLoading: false,
    socialSecurityLoading: false,
    resumesLoading: false,
    resumes: [],
    singleResume: null,
    singleResumeLoading: false,
    avatar: [],
    licence: null,
    socialSecurity: [],
    serviceLicenceLoading: false,
    serviceLicence: [],
    certificateLoading: false,
    certificate: [],
    physicalAddress: [],
    physicalAddressLoading: false,
    physicalAddressActionSuccess: false,
    credentials: [],
    credentialsLoading: false,
    paymentMethod: [],
    paymentMethodLoading: false,
    jobTitles: null,
    jobTitlesLoading: false,
    initialLoad: true,
    credentialActionSuccess: false,
    credentailUploading: false,
  },
  reducers: {
    resetActionSuccess: (state) => {
      state.credentialActionSuccess = false;
    },
    resetSingleResume: (state) => {
      state.singleResume = null;
    },
  },
  extraReducers(builder) {
    builder

      //get avatar
      .addCase(GetAllCredentialAvatarAction.pending, (state) => {
        state.avatarLoading = true;
        state.avatar = [];
      })
      .addCase(GetAllCredentialAvatarAction.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.avatar = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialAvatarAction.rejected, (state) => {
        state.avatarLoading = false;
        state.avatar = [];
      })

      // manage avatar status
      .addCase(ManageAllCredentialAvatarAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.avatar?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.avatarLoading = false;
        state.avatar = tempArray;
      })

      //get licence
      .addCase(GetAllCredentialLicencesAction.pending, (state) => {
        state.licenceLoading = true;
        state.licence = [];
      })
      .addCase(GetAllCredentialLicencesAction.fulfilled, (state, action) => {
        state.licenceLoading = false;
        state.licence = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialLicencesAction.rejected, (state) => {
        state.licenceLoading = false;
        state.licence = [];
      })

      // manage licence status
      .addCase(ManageAllCredentialLicencesAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.licence?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.licenceLoading = false;
        state.licence = tempArray;
      })

      //get Physical Address
      .addCase(GetAllCredentialAddressAction.pending, (state) => {
        state.physicalAddressLoading = true;
        state.physicalAddress = [];
      })
      .addCase(GetAllCredentialAddressAction.fulfilled, (state, action) => {
        state.physicalAddressLoading = false;
        state.physicalAddress = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialAddressAction.rejected, (state) => {
        state.physicalAddressLoading = false;
        state.physicalAddress = [];
      })

      // manage Physical Address
      .addCase(ManageAllCredentialAddressAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.physicalAddress?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.physicalAddress = tempArray;
      })

      //get socialSecurity
      .addCase(GetAllCredentialSocialSecurityAction.pending, (state) => {
        state.socialSecurityLoading = true;
        state.socialSecurity = [];
      })
      .addCase(
        GetAllCredentialSocialSecurityAction.fulfilled,
        (state, action) => {
          state.socialSecurityLoading = false;
          state.socialSecurity = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialSocialSecurityAction.rejected, (state) => {
        state.socialSecurityLoading = false;
        state.socialSecurity = [];
      })

      // manage socialSecurity
      .addCase(
        ManageAllCredentialSocialSecurityAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.socialSecurity?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.socialSecurityLoading = false;
          state.socialSecurity = tempArray;
        }
      )

      //get ServiceLicence
      .addCase(GetAllCredentialServiceLicenceAction.pending, (state) => {
        state.serviceLicenceLoading = true;
        state.serviceLicence = [];
      })
      .addCase(
        GetAllCredentialServiceLicenceAction.fulfilled,
        (state, action) => {
          state.serviceLicenceLoading = false;
          state.serviceLicence = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialServiceLicenceAction.rejected, (state) => {
        state.serviceLicenceLoading = false;
        state.serviceLicence = [];
      })

      // manage ServiceLicence
      .addCase(
        ManageAllCredentialServiceLicenceAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.serviceLicence?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.serviceLicenceLoading = false;
          state.serviceLicence = tempArray;
        }
      )

      //get Certificate
      .addCase(GetAllCredentialCertificateAction.pending, (state) => {
        state.certificateLoading = true;
        state.certificate = [];
      })
      .addCase(GetAllCredentialCertificateAction.fulfilled, (state, action) => {
        state.certificateLoading = false;
        state.certificate = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialCertificateAction.rejected, (state) => {
        state.certificateLoading = false;
        state.certificate = [];
      })

      // manage Certificate status
      .addCase(
        ManageAllCredentialCertificateAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.certificate?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.certificateLoading = false;
          state.certificate = tempArray;
        }
      )

      // For Get Resumes Data For User
      .addCase(GetProfessionalUserResume.pending, (state) => {
        state.resumes = [];
        state.resumesLoading = true;
      })
      .addCase(GetProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = action.payload.response;
        state.resumesLoading = false;
      })
      .addCase(GetProfessionalUserResume.rejected, (state) => {
        state.resumes = [];
        state.resumesLoading = false;
      })

      // For Get All Credentials
      .addCase(GetAllCredentialsAction.pending, (state) => {
        if (state.initialLoad) {
          state.credentialsLoading = true;
        }
        state.credentials = [];
      })
      .addCase(GetAllCredentialsAction.fulfilled, (state, action) => {
        state.credentials = action.payload.response || [];
        state.credentialsLoading = false;
        state.initialLoad = false;
      })
      .addCase(GetAllCredentialsAction.rejected, (state) => {
        state.credentials = [];
        state.credentialsLoading = false;
        state.initialLoad = false;
      })

      // For Update Status Of Resume
      .addCase(UpdateProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = state.resumes?.map((resume) => {
          if (resume?.id === action.payload.id) {
            return { ...resume, status: action.payload.status };
          }
          return { ...resume };
        });
        state.resumesLoading = false;
      })

      //get Job Title
      .addCase(GetAllCredentialJobTitle.pending, (state) => {
        state.jobTitlesLoading = true;
        state.jobTitles = null;
      })
      .addCase(GetAllCredentialJobTitle.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialJobTitle.rejected, (state) => {
        state.jobTitlesLoading = false;
        state.jobTitles = null;
      })

      // manage Job Title
      .addCase(ManageCredentialJobTitle.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        state.jobTitlesLoading = false;
        state.jobTitles = { ...state.jobTitles, ...obj };
      })

      //get avatar Web
      .addCase(GetAllCredentialAvatarWebAction.pending, (state) => {
        state.avatarLoading = true;
        state.credentialActionSuccess = false;
        state.avatar = [];
      })
      .addCase(GetAllCredentialAvatarWebAction.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.credentialActionSuccess = false;
        state.avatar = [action.payload.response];
      })
      .addCase(GetAllCredentialAvatarWebAction.rejected, (state) => {
        state.avatarLoading = false;
        state.credentialActionSuccess = false;
        state.avatar = [];
      })

      //Delete Avatar
      .addCase(DeleteCredentialAvatarWebAction.fulfilled, (state) => {
        state.avatarLoading = false;

        state.avatar = [];
      })
      //Add Avatar
      .addCase(AddCredentialAvatarWebAction.pending, (state) => {
        state.credentailUploading = true;
      })

      //Add Avatar
      .addCase(AddCredentialAvatarWebAction.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.avatar = [action.payload.response];
      })
      //Add Avatar
      .addCase(AddCredentialAvatarWebAction.rejected, (state, action) => {
        state.avatarLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.avatar = [action.payload.response];
      })

      .addCase(EditCredentialAvatarWebAction.pending, (state) => {
        state.credentailUploading = true;
      })

      //Edit Avatar
      .addCase(EditCredentialAvatarWebAction.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.avatar = [action.payload.response];
      })

      .addCase(EditCredentialAvatarWebAction.rejected, (state) => {
        state.credentailUploading = false;
      })

      //get Physical Address Web
      .addCase(GetAllCredentialPhysicalAddressWebAction.pending, (state) => {
        state.physicalAddressLoading = true;
        state.credentialActionSuccess = false;
        state.physicalAddress = [];
      })
      .addCase(
        GetAllCredentialPhysicalAddressWebAction.fulfilled,
        (state, action) => {
          state.physicalAddressLoading = false;
          state.credentialActionSuccess = false;
          state.physicalAddress = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialPhysicalAddressWebAction.rejected, (state) => {
        state.physicalAddressLoading = false;
        state.credentialActionSuccess = false;
        state.physicalAddress = [];
      })

      .addCase(
        SetDetfaultCredentialPhysicalAddressWebAction.fulfilled,
        (state, action) => {
          state.physicalAddressLoading = false;
          state.physicalAddress = state.physicalAddress.map((item) => {
            if (item.id === action.payload.id) {
              return { ...item, isDefault: true };
            }
            return { ...item, isDefault: false };
          });
        }
      )

      //add Physical Address Web
      .addCase(
        AddCredentialPhysicalAddressWebAction.fulfilled,
        (state, action) => {
          state.physicalAddressLoading = false;
          state.credentialActionSuccess = true;
          state.physicalAddress = [
            action.payload.response,
            ...state.physicalAddress,
          ];
        }
      )

      //edit Physical Address Web
      .addCase(
        EditCredentialPhysicalAddressWebAction.fulfilled,
        (state, action) => {
          state.physicalAddressLoading = false;
          state.credentialActionSuccess = true;
          state.physicalAddress = state.physicalAddress.map((item) => {
            if (item?.id === action.payload.response.id) {
              return { ...item, ...action.payload.response };
            }
            return { ...item };
          });
        }
      )

      //delete Physical Address
      .addCase(
        DeleteCredentialPhysicalAddressWebAction.fulfilled,
        (state, action) => {
          state.physicalAddressLoading = false;
          state.physicalAddress = state.physicalAddress.filter(
            (item) => item?.id !== action.payload.id
          );
        }
      )

      //get Job Titles
      .addCase(GetCredentialJobTitleWebAction.pending, (state) => {
        state.jobTitlesLoading = true;
        state.jobTitles = null;
      })
      .addCase(GetCredentialJobTitleWebAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = action.payload.response;
      })
      .addCase(GetCredentialJobTitleWebAction.rejected, (state) => {
        state.jobTitlesLoading = false;
        state.jobTitles = null;
      })

      //edit Job Title Web
      .addCase(UpdateCredentialJobTitleWebAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = { ...state.jobTitles, ...action.payload.response };
      })

      //add Job Title Web
      .addCase(AddCredentialJobTitleWebAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = { ...state.jobTitles, ...action.payload.response };
      })

      //get Job Titles
      .addCase(GetCredentialDriverLicenceForWeb.pending, (state) => {
        state.licenceLoading = true;
        state.licence = null;
      })
      .addCase(GetCredentialDriverLicenceForWeb.fulfilled, (state, action) => {
        state.licenceLoading = false;
        state.licence = action.payload.response;
      })
      .addCase(GetCredentialDriverLicenceForWeb.rejected, (state) => {
        state.licenceLoading = false;
        state.licence = null;
      })

      //add Driver Licence
      .addCase(AddCredentialDriverLicenceForWeb.pending, (state) => {
        state.credentailUploading = true;
        state.licence = null;
      })
      .addCase(AddCredentialDriverLicenceForWeb.fulfilled, (state, action) => {
        state.credentailUploading = false;
        state.licence = action.payload.response;
      })
      .addCase(AddCredentialDriverLicenceForWeb.rejected, (state) => {
        state.credentailUploading = false;
        state.licence = null;
      })

      //edit Driver Licence
      .addCase(EditCredentialDriverLicenceForWeb.pending, (state) => {
        state.credentailUploading = true;
        state.licenceLoading = true;
      })
      .addCase(EditCredentialDriverLicenceForWeb.fulfilled, (state, action) => {
        state.credentailUploading = false;
        state.licenceLoading = false;
        state.licence = action.payload.response;
      })
      .addCase(EditCredentialDriverLicenceForWeb.rejected, (state) => {
        state.credentailUploading = false;
        state.licenceLoading = false;
      })

      //get Resume For Web
      .addCase(GetCredentialResumeForWeb.pending, (state) => {
        state.resumesLoading = true;
        state.resumes = [];
        state.credentialActionSuccess = false;
        state.singleResumeLoading = true;
      })
      .addCase(GetCredentialResumeForWeb.fulfilled, (state, action) => {
        state.credentialActionSuccess = false;
        state.resumesLoading = false;
        state.resumes = action.payload.response;
      })
      .addCase(GetCredentialResumeForWeb.rejected, (state) => {
        state.resumesLoading = false;
        state.credentialActionSuccess = false;
        state.resumes = [];
      })

      //delete Resume
      .addCase(DeleteCredentialResumeForWeb.fulfilled, (state, action) => {
        state.resumesLoading = false;
        state.resumes = state.resumes.filter(
          (item) => item?.id !== action.payload.id
        );
      })

      //get Resume By Id For Web
      .addCase(GetCredentialResumeByIdForWeb.pending, (state) => {
        state.singleResume = null;
        state.singleResumeLoading = true;
      })
      .addCase(GetCredentialResumeByIdForWeb.fulfilled, (state, action) => {
        state.singleResume = action.payload.response;
        state.singleResumeLoading = false;
      })
      .addCase(GetCredentialResumeByIdForWeb.rejected, (state) => {
        state.singleResume = null;
        state.singleResumeLoading = false;
      })

      //add Resume For Web
      .addCase(AddCredentialResumeForWeb.fulfilled, (state, action) => {
        let tempObj = {
          ...action.payload.response,
          id: action.payload.response.resumeId,
          status: "pending",
        };
        state.resumesLoading = false;
        state.credentialActionSuccess = true;
        state.resumes = [tempObj, ...state.resumes];
      })

      // edit Resume For Web
      .addCase(EditCredentialResumeForWeb.fulfilled, (state, action) => {
        state.credentialActionSuccess = true;
        state.resumesLoading = false;
        state.resumes = state.resumes.map((item) => {
          if (item.id === action.payload.response.id) {
            return {
              ...item,
              ...action.payload.response,
              experience: action.payload.experience,
              education: action.payload.education,
            };
          }
          return item;
        });
        if (
          state.singleResume &&
          state.singleResume.id === action.payload.response.id
        ) {
          state.singleResume = {
            ...state.singleResume,
            ...action.payload.response,
            experience: action.payload.experience,
            education: action.payload.education,
          };
        }
      })

      .addCase(
        SetDetfaultCredentialResumeWebAction.fulfilled,
        (state, action) => {
          state.resumesLoading = false;
          state.resumes = state.resumes.map((item) => {
            if (item.id === action.payload.id) {
              return { ...item, isDefault: true };
            }
            return { ...item, isDefault: false };
          });
        }
      )

      //get Pro Licence Web
      .addCase(GetAllCredentialProLicenceWebAction.pending, (state) => {
        state.serviceLicenceLoading = true;
        state.credentialActionSuccess = false;
        state.serviceLicence = [];
      })
      .addCase(
        GetAllCredentialProLicenceWebAction.fulfilled,
        (state, action) => {
          state.serviceLicenceLoading = false;
          state.credentialActionSuccess = false;
          state.serviceLicence = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialProLicenceWebAction.rejected, (state) => {
        state.serviceLicenceLoading = false;
        state.credentialActionSuccess = false;
        state.serviceLicence = [];
      })

      //Add Pro Licence
      .addCase(AddCredentialProLicenceWebAction.pending, (state) => {
        state.credentailUploading = true;
        state.credentialActionSuccess = false;
      })
      .addCase(AddCredentialProLicenceWebAction.fulfilled, (state, action) => {
        state.serviceLicenceLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.serviceLicence = [
          action.payload.response,
          ...state.serviceLicence,
        ];
      })
      .addCase(AddCredentialProLicenceWebAction.rejected, (state) => {
        state.credentailUploading = false;
        state.credentialActionSuccess = false;
      })

      // Edit Pro Licence
      .addCase(EditCredentialProLicenceWebAction.pending, (state) => {
        state.credentailUploading = true;
        state.credentialActionSuccess = false;
      })
      .addCase(EditCredentialProLicenceWebAction.fulfilled, (state, action) => {
        state.serviceLicenceLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.serviceLicence = state.serviceLicence.map((item) => {
          if (item?.id === action.payload.response.id) {
            return { ...item, ...action.payload.response };
          }
          return { ...item };
        });
      })
      .addCase(EditCredentialProLicenceWebAction.rejected, (state) => {
        state.credentailUploading = false;
        state.credentialActionSuccess = false;
      })

      //delete PRo Licence
      .addCase(DeleteCredentialProLicenceWebAction.pending, (state) => {
        state.credentialActionSuccess = false;
      })
      .addCase(
        DeleteCredentialProLicenceWebAction.fulfilled,
        (state, action) => {
          state.serviceLicenceLoading = false;
          state.credentialActionSuccess = true;
          state.serviceLicence = state.serviceLicence.filter(
            (item) => item?.id !== action.payload.id
          );
        }
      )

      //get Certificate
      .addCase(GetAllCredentialCertificateWebAction.pending, (state) => {
        state.certificateLoading = true;
        state.certificate = [];
      })
      .addCase(
        GetAllCredentialCertificateWebAction.fulfilled,
        (state, action) => {
          state.certificateLoading = false;
          state.certificate = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialCertificateWebAction.rejected, (state) => {
        state.certificateLoading = false;
        state.certificate = [];
      })

      //Add Certiifcate
      .addCase(AddCredentialCertificateWebAction.pending, (state) => {
        state.credentailUploading = true;
        state.credentialActionSuccess = false;
      })
      .addCase(AddCredentialCertificateWebAction.fulfilled, (state, action) => {
        state.certificateLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.certificate = [action.payload.response, ...state.certificate];
      })
      .addCase(AddCredentialCertificateWebAction.rejected, (state) => {
        state.credentailUploading = false;
        state.credentialActionSuccess = false;
      })

      // Edit Certificate
      .addCase(EditCredentialCertificateWebAction.pending, (state) => {
        state.credentailUploading = true;
        state.credentialActionSuccess = false;
      })
      .addCase(
        EditCredentialCertificateWebAction.fulfilled,
        (state, action) => {
          state.certificateLoading = false;
          state.credentailUploading = false;
          state.credentialActionSuccess = true;
          state.certificate = state.certificate.map((item) => {
            if (item?.id === action.payload.response.id) {
              return { ...item, ...action.payload.response };
            }
            return { ...item };
          });
        }
      )
      .addCase(EditCredentialCertificateWebAction.rejected, (state) => {
        state.credentailUploading = false;
        state.credentialActionSuccess = false;
      })

      //delete certificate
      .addCase(DeleteCredentialCertificateWebAction.pending, (state) => {
        state.credentialActionSuccess = false;
      })
      .addCase(
        DeleteCredentialCertificateWebAction.fulfilled,
        (state, action) => {
          state.certificateLoading = false;
          state.certificate = state.certificate.filter(
            (item) => item?.id !== action.payload.id
          );
          state.credentialActionSuccess = true;
        }
      )

      //get socialSecurity
      .addCase(GetAllCredentialSocSecWebAction.pending, (state) => {
        state.socialSecurityLoading = true;
        state.credentialActionSuccess = false;
        state.socialSecurity = [];
      })
      .addCase(GetAllCredentialSocSecWebAction.fulfilled, (state, action) => {
        state.socialSecurityLoading = false;
        state.socialSecurity = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        state.credentialActionSuccess = false;
      })
      .addCase(GetAllCredentialSocSecWebAction.rejected, (state) => {
        state.credentialActionSuccess = false;
        state.socialSecurityLoading = false;
        state.socialSecurity = [];
      })

      //Add Certiifcate
      .addCase(AddCredentialSocSecWebAction.pending, (state) => {
        state.credentailUploading = true;
      })
      .addCase(AddCredentialSocSecWebAction.fulfilled, (state, action) => {
        state.socialSecurityLoading = false;
        state.credentailUploading = false;
        state.credentialActionSuccess = true;
        state.socialSecurity = action.payload.response;
      })
      .addCase(AddCredentialSocSecWebAction.rejected, (state) => {
        state.credentailUploading = false;
      })

      // Edit Certificate
      .addCase(EditCredentialSocSecWebAction.pending, (state) => {
        state.socialSecurityLoading = true;
        state.credentailUploading = true;
      })
      .addCase(EditCredentialSocSecWebAction.fulfilled, (state, action) => {
        state.socialSecurityLoading = false;
        state.credentialActionSuccess = true;
        state.credentailUploading = false;
        state.socialSecurity = action.payload.response;
      })
      .addCase(EditCredentialSocSecWebAction.rejected, (state) => {
        state.credentailUploading = false;
        state.socialSecurityLoading = false;
      });
  },
});

export const { resetActionSuccess, resetSingleResume } =
  credentialSlice.actions;

export default credentialSlice.reducer;
