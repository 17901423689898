import React, { useEffect, useState } from "react";
import BlankImg from "assets/images/SVG/no-data-home.svg";
import { MuiBox } from "components/atoms";

const FullScreenImageModel = ({
  imgSrc,
  placeholder = BlankImg,
  style,
  fullScreen = false,
  alt = "image",
}) => {
  const [error, setError] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isModalOpen) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "auto";
    }
  }, [isModalOpen]);

  // Function to open/close the modal
  const toggleModal = (imageSrc) => {
    if (fullScreen && !error) {
      setSelectedImage(imageSrc);
      setModalOpen(!isModalOpen);
    }
  };
  return (
    <>
      <img
        src={imgSrc ? (error ? placeholder : imgSrc) : placeholder}
        onError={() => {
          setError(true);
        }}
        className={
          imgSrc ? (error ? "img-contain" : "img-cover") : "img-contain"
        }
        style={{ ...style, cursor: fullScreen ? "pointer" : "unset" }}
        alt={alt}
        onClick={() =>
          toggleModal(imgSrc ? (error ? placeholder : imgSrc) : placeholder)
        }
      />
      {fullScreen && isModalOpen && selectedImage && (
        <MuiBox
          sx={{
            position: "fixed",
            inset: "0px",
            zIndex: "99999999",
            display: "flex",
            padding: "20px",
            bgcolor: "#000000BF",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={toggleModal}
        >
          <img
            src={selectedImage}
            alt=""
            className="max-h-full max-w-full rounded-md pointer-events-none "
          />
        </MuiBox>
      )}
    </>
  );
};

export default FullScreenImageModel;
