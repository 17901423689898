import React, { useState, forwardRef } from "react";
import {
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import EyeIcon from "assets/images/SVG/EyeIcon.svg";
import EyeOffIcon from "assets/images/SVG/eye off.svg";
import MuiTypography from "../MuiTypography";
import MuiBox from "../MuiBox";
import FormError from "../FormError";

const Input = forwardRef(
  (
    {
      className,
      onChange,
      onBlur,
      type = "text",
      value,
      error,
      errorMsg,
      placeholder = "Enter here",
      label,
      name,
      fullWidth = true,
      disabled = false,
      readOnly = false,
      variant = "basic",
      icon,
      endIcon,
      allowBlurFunction = false,
      required = true,
      isNumeric = false, // New prop for numeric inputs
      ...rest
    },
    ref // Accept ref as the second argument
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleInputChange = (e) => {
      let inputValue = e.target.value;

      // If isNumeric is true, sanitize the input
      if (isNumeric) {
        inputValue = inputValue.replace(/\D/g, "").slice(0, 9); // Allow only digits, max length 9
      }
      if (ref?.current) {
        ref.current.value = inputValue;
      }

      onChange({
        target: {
          name,
          value: inputValue,
        },
      });
    };

    const handleClickShowPassword = () => {
      if (type === "password") {
        setShowPassword(!showPassword);
      }
    };

    return (
      <MuiBox sx={{ opacity: disabled ? ".5" : "1" }}>
        <div>
          <MuiTypography variant="baseFont" fontWeight={"bold"}>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </MuiTypography>
        </div>
        <OutlinedInput
          inputRef={ref} // Use the ref properly
          className={className}
          id={name}
          fullWidth={fullWidth}
          onChange={handleInputChange} // Use custom handler
          autoComplete={ref ? null : "new-password"}
          onBlur={(e) => {
            if (allowBlurFunction) {
              onBlur(e);
            }
          }}
          type={showPassword ? "text" : type}
          value={value}
          error={Boolean(error)}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          sx={{
            marginTop: variant === "plain" ? "8px !important" : "",
          }}
          startAdornment={
            icon && (
              <InputAdornment position="start">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "14px",
                    height: "43px",
                  }}
                >
                  {icon}
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: "inputBorder.main" }}
                  />
                </div>
              </InputAdornment>
            )
          }
          endAdornment={
            <>
              {endIcon && (
                <InputAdornment position="start">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      height: "43px",
                    }}
                  >
                    {endIcon}
                  </div>
                </InputAdornment>
              )}
              {type === "password" && (
                <InputAdornment position="end">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: "5px",
                      gap: "14px",
                      height: "43px",
                    }}
                  >
                    <IconButton
                      sx={{ color: "button.main" }}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img src={EyeIcon} alt="Show Password" />
                      ) : (
                        <img src={EyeOffIcon} alt="Hide Password" />
                      )}
                    </IconButton>
                  </div>
                </InputAdornment>
              )}
            </>
          }
          variant={variant}
          {...rest}
        />
        {error && errorMsg && <FormError errorMsg={errorMsg} />}
      </MuiBox>
    );
  }
);

Input.displayName = "Input"; // ✅ Assign a display name

export default Input;
