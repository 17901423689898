import axiosClient from "utils/api";
const HospitalURI = "/hospital";
const ProfessionalURI = "/professional";

// For get all hospital direct hire listings
export const getHospitalDirectHire = async () => {
  let URL = `${HospitalURI}/directHire`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get All Hospital Direct Hire List:",
      error.message
    );
    throw error;
  }
};

// For get hospital direct hire details
export const getHospitalDirectHireDetails = async (payload) => {
  const { id } = payload;
  let URL = `${HospitalURI}/directHireDetail/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get Hospital Direct Hire Details:",
      error.message
    );
    throw error;
  }
};

// For get all professional direct hire listings
export const getProfessionalDirectHire = async () => {
  let URL = `${ProfessionalURI}/jobOffers`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get All Professional Direct Hire List:",
      error.message
    );
    throw error;
  }
};

// For get professional direct hire details
export const getProfessionalDirectHireDetails = async (payload) => {
  const { id } = payload;
  let URL = `${ProfessionalURI}/jobOffers/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get Professional Direct Hire Details:",
      error.message
    );
    throw error;
  }
};

// For reject / accept direct hire
export const acceptRejectDirectHire = async (payload) => {
  let { id, ...rest } = payload;
  let URL = `/professional/accepteOrRejectJob/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error(`Error occuured Accept Reject Direct Hire :`, error.message);
    throw error;
  }
};
