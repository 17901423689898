import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllCredentialJobTitle,
  ManageCredentialJobTitle,
} from "store/middlewares/credential";
import checkAccess from "utils/checkAccess";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";
import { CustomLoader, NoDataFound, StatusPill } from "components/atoms";

function JobTitle({ userId }) {
  const dispatch = useDispatch();
  const { jobTitles, jobTitlesLoading } = useSelector(
    (state) => state.credential
  );

  useEffect(() => {
    dispatch(GetAllCredentialJobTitle({ id: userId }));
  }, [dispatch, userId]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">Job Title :</h6>
          {!jobTitlesLoading ? (
            <>
              {jobTitles?.jobTitleName ? (
                <div className="grid grid-cols-1 gap-4">
                  <div className="mb-4 flex items-center justify-between">
                    <h6 className="text-base capitalize">
                      {jobTitles?.jobTitleName}
                    </h6>
                    <div className="flex gap-2 justify-end">
                      <StatusPill
                        status={jobTitles?.status}
                        statusText={jobTitles?.status}
                      />
                    </div>
                  </div>
                  {checkAccess("update_credentials") && (
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-6 mx-3 ">
                      {jobTitles?.status !== "accepted" && (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title:
                                "Are you sure you want to accept this job title?",
                              imageUrl: ApprovedSVGModel,
                              imageWidth: 180,
                              imageHeight: 180,
                              showCancelButton: true,
                              confirmButtonColor: "#00AE46",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Confirm",
                              customClass: {
                                title: "Swal-title-class",
                                popup: "custom-swal-popup",
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                dispatch(
                                  ManageCredentialJobTitle({
                                    id: jobTitles?.id,
                                    status: "accepted",
                                  })
                                );
                              }
                            });
                          }}
                          type="button"
                          className="col-start-5 text-white capitalize btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 rounded-lg"
                        >
                          Accept
                        </button>
                      )}
                      {jobTitles?.status !== "rejected" && (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title:
                                "Are you sure you want to reject this Job Title?",
                              imageUrl: RejectSVGModel,
                              imageWidth: 180,
                              imageHeight: 180,
                              showCancelButton: true,
                              confirmButtonColor: "#00AE46",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Confirm",
                              customClass: {
                                title: "Swal-title-class",
                                popup: "custom-swal-popup",
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                dispatch(
                                  ManageCredentialJobTitle({
                                    id: jobTitles?.id,
                                    status: "rejected",
                                  })
                                );
                              }
                            });
                          }}
                          type="button"
                          className="col-start-6 text-white capitalize btn px-3 bg-[#FF5C5C] hover:text-white hover:bg-[#ff3030] p-2 rounded-lg"
                        >
                          Reject
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default JobTitle;
