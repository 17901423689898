import { WebLayout } from "components/molecules";
import useDocumentTitle from "hooks/useDocumentTitle";
import React, { lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { validateTokenWeb } from "store/middlewares/session/auth";
import { getDecryptedToken } from "utils/CryptoJS";
import ScrollToTop from "utils/ScrollToTop";

// Pages
const HomePage = lazy(() => import("pages/Web/HomePage"));
const DetailsPage = lazy(() => import("pages/Web/DetailsPage"));
const JobsPage = lazy(() => import("pages/Web/JobsPage"));
const SchedulePage = lazy(() => import("pages/Web/SchedulePage"));
const ProfessionalnearBy = lazy(() => import("pages/Web/ProfessionalNearBy"));
// const ProfessionalDetails = lazy(() => import("components/organisms/ProfessionalDetails"));
const ProfessionalResume = lazy(() => import("pages/Web/ProfessionalResume"));
const ProfessionalHire = lazy(() => import("pages/Web/ProfessionalHire"));
const PostJob = lazy(() => import("pages/Web/PostJob"));
const TotalApplicants = lazy(() => import("pages/Web/TotalApplicants"));
const ScheduleDetails = lazy(() => import("pages/Web/ScheduleDetails"));
const MessagePage = lazy(() => import("pages/Web/MessagePage"));
const RevenuePage = lazy(() => import("pages/Web/RevenuePage"));
const ProfilePage = lazy(() => import("pages/Web/ProfilePage"));
const PaymentStatus = lazy(() => import("pages/Web/PaymentStatus"));
const OnGoingShiftsPage = lazy(() => import("pages/Web/OnGoingShiftsPage"));
const Error404 = lazy(() => import("pages/ErrorPages/Error404"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

// professional and application details  group
const hospitalRoutes = [
  {
    path: generatePath("home"),
    element: <HomePage />,
  },
  {
    path: generatePath("jobs"),
    element: <JobsPage />,
  },
  {
    path: generatePath("professional-near-by"),
    element: <ProfessionalnearBy />,
  },
  {
    path: generatePath("on-going-shifts"),
    element: <OnGoingShiftsPage />,
  },
  // {
  //   path: generatePath(":type/details"),
  //   element: <ProfessionalDetails />,
  // },
  // {
  //   path: generatePath(":type/details/:id/resume"),
  //   element: <ProfessionalResume />,
  // },
  // {
  //   path: generatePath("resume"),
  //   element: <ProfessionalResume />,
  // },
  {
    path: generatePath("hire"),
    element: <ProfessionalHire />,
  },
  {
    path: generatePath(":type/:id"),
    element: <DetailsPage />,
  },
  {
    path: generatePath("jobs/post-job"),
    element: <PostJob />,
  },
  {
    path: generatePath(":type/:id/applicants"),
    element: <TotalApplicants />,
  },
  {
    path: generatePath(":type/:id/resume"),
    element: <ProfessionalResume />,
  },
  {
    path: generatePath("schedule"),
    element: <SchedulePage />,
  },
  {
    path: generatePath("schedule/:type/schedule-details/:shiftId/:scheduleId"),
    element: <ScheduleDetails />,
  },
  {
    path: generatePath("message"),
    element: <MessagePage />,
  },
  {
    path: generatePath("revenue"),
    element: <RevenuePage />,
  },
  {
    path: generatePath("profile"),
    element: <ProfilePage type="hospitals" />,
  },
];

const paymentRoutes = [
  {
    path: generatePath("direct-hire-payment"),
    element: <PaymentStatus redirectTo="/hospital/jobs" />,
  },
  {
    path: generatePath("hire-payment"),
    element: <PaymentStatus redirectTo="/hospital/jobs" />,
  },
];

const hospitalNavigation = () => {
  useDocumentTitle("Vital Medical Staffing");

  const user = JSON.parse(localStorage.getItem("aboutcare-web"));

  const dispatch = useDispatch();

  const { isWebTokenValid } = useSelector((state) => state.auth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({ token: getDecryptedToken(user?.refreshToken) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            !user || !isWebTokenValid ? (
              <Navigate to="/" />
            ) : (
              <Navigate to="home" />
            )
          }
        />
        {paymentRoutes.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !user || !isWebTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.role === "professional" ? (
                  <>
                    {user?.status === "pending" ? (
                      <Navigate to="/professional/credentials" />
                    ) : (
                      <Navigate to="/professional/home" />
                    )}
                  </>
                ) : (
                  <WebLayout />
                )}
              </>
            )
          }
        >
          {hospitalRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Route>
        {/* all undefined routes are handled here for admin */}
        {/* <Route path="*" element={<Navigate to="/" replace />}  */}
        <Route
          path="*"
          element={
            !user || !isWebTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {user?.role === "professional" ? (
                  <>
                    {user?.status === "pending" ? (
                      <Navigate to="/professional/credentials" />
                    ) : (
                      <Navigate to="/professional/home" />
                    )}
                  </>
                ) : (
                  <Error404 />
                )}
              </>
            )
          }
        />
      </Routes>
    </>
  );
};

export default hospitalNavigation;
