import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveProfessionalAction,
  ApplyHospitalJobAction,
  BlockProfessionalAction,
  GetHospitalDetailsAction,
  GetNotificationForProfessional,
  GetProfessionalByIdAction,
  GetProfessionalExperience,
  GetProfessionalUserHolidays,
  UnblockProfessionalAction,
  UpdateNotificationForProfessional,
  WithdrawHospitalJobAction,
} from "store/middlewares/users/professionals";
import {
  GetProfessionalUserShiftDetail,
  GetProfessionalUserAllShifts,
  GetProfessionalUserScheduleDetail,
  CancelProfessionalUserSchedule,
  CancelProfessionalUserShift,
  GetOnGoingShiftListAction,
} from "store/middlewares/users/schedule";
import {
  BlockHospitalAction,
  EditHospitalAddressAction,
  GetHospitalAddressAction,
  GetHospitalByIdAction,
  GetNearByProfessionalByIdAction,
  GetSubscriptionHospitalAction,
  UnblockHospitalAction,
} from "store/middlewares/users/hospitals";
import { UpdateProfessionalUserResume } from "store/middlewares/credential";
import {
  ApproveApplicationForWebAction,
  GetApplicantDetailForWebAction,
} from "store/middlewares/jobs";

const initialState = {
  getUserLoading: false,
  user: {},
  userLoading: false,
  userExperience: [],
  userExperienceLoading: false,
  holidays: [],
  holidaysLoading: false,
  schedules: [],
  schedulesLoading: false,
  singleSchedule: null,
  singleScheduleLoading: false,
  shift: null,
  shiftLoading: false,
  shiftFilterProps: false,
  notification: [],
  notificationLoading: false,
  getSubscriptionLoading: false,
  Subscription: [],
  address: {},
  addressLoading: false,
  hospitalDetailsLoading: false,
  hospitalDetails: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setShiftFilterProps: (state, action) => {
      state.shiftFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // For Get Single Professional User
      .addCase(GetProfessionalByIdAction.pending, (state) => {
        state.user = null;
        state.getUserLoading = true;
      })
      .addCase(GetProfessionalByIdAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })
      .addCase(GetProfessionalByIdAction.rejected, (state) => {
        state.user = null;
        state.getUserLoading = false;
      })
      // For Get Single Professional Users Experience
      .addCase(GetProfessionalExperience.pending, (state) => {
        state.userExperience = null;
        state.userExperienceLoading = true;
      })
      .addCase(GetProfessionalExperience.fulfilled, (state, action) => {
        state.userExperience = action.payload.response;
        state.userExperienceLoading = false;
      })

      // For Get Hospital
      .addCase(GetHospitalByIdAction.pending, (state) => {
        state.user = null;
        state.getUserLoading = true;
      })
      .addCase(GetHospitalByIdAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })
      .addCase(GetHospitalByIdAction.rejected, (state) => {
        state.user = null;
        state.getUserLoading = false;
      })

      // For Block Professional User
      .addCase(BlockProfessionalAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = { ...state.user, ...action.payload.response };
      })

      // For Unblock Professional User
      .addCase(UnblockProfessionalAction.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload.response };
        state.getUserLoading = false;
      })

      // For Activate Professional User
      .addCase(ActiveProfessionalAction.fulfilled, (state) => {
        state.user = { ...state.user, status: "accepted" };
      })

      // For Update Status Of Resume
      .addCase(UpdateProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = state.resumes?.map((resume) => {
          if (resume?.id === action.payload.id) {
            return { ...resume, status: action.payload.status };
          }
          return { ...resume };
        });
        state.resumesLoading = false;
      })

      // For Block Hospital User
      .addCase(BlockHospitalAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = action.payload.response;
      })

      // For Unblock Hospital User
      .addCase(UnblockHospitalAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })

      // Single Professional User's Tabs

      // For Get Holidays Data For Professional User
      .addCase(GetProfessionalUserHolidays.pending, (state) => {
        state.holidays = [];
        state.holidaysLoading = true;
      })
      .addCase(GetProfessionalUserHolidays.fulfilled, (state, action) => {
        state.holidays = action.payload.response;
        state.holidaysLoading = false;
      })
      .addCase(GetProfessionalUserHolidays.rejected, (state) => {
        state.holidays = [];
        state.holidaysLoading = false;
      })

      // For Get swifts Data For Professional User
      .addCase(GetProfessionalUserAllShifts.pending, (state) => {
        state.schedules = [];
        state.schedulesLoading = true;
      })
      .addCase(GetProfessionalUserAllShifts.fulfilled, (state, action) => {
        state.schedules = action.payload.response;
        state.schedulesLoading = false;
      })
      .addCase(GetProfessionalUserAllShifts.rejected, (state) => {
        state.schedules = [];
        state.schedulesLoading = false;
      })

      // For Get swifts detail Data For Professional User
      .addCase(GetProfessionalUserShiftDetail.pending, (state) => {
        state.shift = null;
        state.shiftLoading = true;
      })
      .addCase(GetProfessionalUserShiftDetail.fulfilled, (state, action) => {
        state.shift = action.payload.response;
        state.shiftLoading = false;
      })
      .addCase(GetProfessionalUserShiftDetail.rejected, (state) => {
        state.shift = null;
        state.shiftLoading = false;
      })

      // For Get swifts detail Data For Professional User
      .addCase(GetProfessionalUserScheduleDetail.pending, (state) => {
        state.singleSchedule = null;
        state.singleScheduleLoading = true;
      })
      .addCase(GetProfessionalUserScheduleDetail.fulfilled, (state, action) => {
        state.singleSchedule = action.payload.response;
        state.singleScheduleLoading = false;
      })
      .addCase(GetProfessionalUserScheduleDetail.rejected, (state) => {
        state.singleSchedule = null;
        state.singleScheduleLoading = false;
      })

      // Cancel Shift
      .addCase(CancelProfessionalUserSchedule.fulfilled, (state, action) => {
        state.singleSchedule = { ...state.singleSchedule, status: "cancelled" };
        state.singleScheduleLoading = false;
        state.schedules = state.schedules?.map((item) => {
          if (item?.id === action.payload.id) {
            return { ...item, status: "cancelled" };
          }
          return item;
        });
      })

      // Cancel Shift
      .addCase(CancelProfessionalUserShift.fulfilled, (state, action) => {
        state.shift = { ...state.shift, status: "cancelled" };
        state.singleScheduleLoading = false;
        state.singleSchedule = {
          ...state.singleSchedule,
          JobTimeSchedule: state.singleSchedule?.JobTimeSchedule?.map(
            (item) => {
              if (item?.id === action.payload.id) {
                return { ...item, status: "cancelled" };
              }
              return item;
            }
          ),
        };
      })

      // For Hospital Subscription
      .addCase(GetSubscriptionHospitalAction.pending, (state) => {
        state.Subscription = [];
        state.getSubscriptionLoading = true;
      })
      .addCase(GetSubscriptionHospitalAction.fulfilled, (state, action) => {
        state.Subscription = action.payload.response;
        state.getSubscriptionLoading = false;
      })
      .addCase(GetSubscriptionHospitalAction.rejected, (state) => {
        state.Subscription = [];
        state.getSubscriptionLoading = false;
      })

      // For Get address hospital
      .addCase(GetHospitalAddressAction.pending, (state) => {
        state.address = null;
        state.addressLoading = true;
      })
      .addCase(GetHospitalAddressAction.fulfilled, (state, action) => {
        state.address = action.payload.response;
        state.addressLoading = false;
      })
      .addCase(GetHospitalAddressAction.rejected, (state) => {
        state.address = null;
        state.addressLoading = false;
      })

      .addCase(EditHospitalAddressAction.fulfilled, (state, action) => {
        state.address = action.payload.response;
        let addressDetails = state.user.addressDetails;
        let tempArray = addressDetails.map((add) => {
          if (add.id === action.payload.response.id) {
            return { ...add, ...action.payload.response };
          } else {
            return { ...add };
          }
        });
        state.user = {
          ...state.user,
          addressDetails: tempArray,
        };
        state.addressLoading = false;
      })

      // For Get notification Data For Professional User
      .addCase(GetNotificationForProfessional.pending, (state) => {
        state.notification = [];
        state.notificationLoading = true;
      })
      .addCase(GetNotificationForProfessional.fulfilled, (state, action) => {
        state.notification = action.payload.response;
        state.notificationLoading = false;
      })
      .addCase(GetNotificationForProfessional.rejected, (state) => {
        state.notification = [];
        state.notificationLoading = false;
      })

      .addCase(UpdateNotificationForProfessional.fulfilled, (state, action) => {
        state.notification = {
          ...state.notification,
          ...action.payload.response,
        };
        state.notificationLoading = false;
      })

      // For Get Hospital Details
      .addCase(GetHospitalDetailsAction.pending, (state) => {
        state.hospitalDetailsLoading = true;
        state.hospitalDetails = null;
      })
      .addCase(GetHospitalDetailsAction.fulfilled, (state, action) => {
        state.hospitalDetailsLoading = false;
        state.hospitalDetails = action.payload.response;
      })
      .addCase(GetHospitalDetailsAction.rejected, (state) => {
        state.hospitalDetailsLoading = false;
        state.hospitalDetails = null;
      })

      // For Get All Professional Users
      .addCase(GetNearByProfessionalByIdAction.pending, (state) => {
        // let { users } = state;
        state.getUserLoading = false;
        // state.users = users?.items?.length > 0 ? { ...users } : null;
      })
      .addCase(GetNearByProfessionalByIdAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = action.payload.response;
      })
      .addCase(GetNearByProfessionalByIdAction.rejected, (state) => {
        state.getUserLoading = false;
        state.user = null;
      })

      // For Get All Professional Users
      .addCase(GetApplicantDetailForWebAction.pending, (state) => {
        // let { users } = state;
        state.getUserLoading = false;
        // state.users = users?.items?.length > 0 ? { ...users } : null;
      })
      .addCase(GetApplicantDetailForWebAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = action.payload;
      })
      .addCase(GetApplicantDetailForWebAction.rejected, (state) => {
        state.getUserLoading = false;
        state.user = null;
      })

      // For apply to job
      .addCase(ApproveApplicationForWebAction.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(ApproveApplicationForWebAction.fulfilled, (state, action) => {
        if (state.user?.result?.jobStatus) {
          state.user.result.jobStatus.status = action.payload.response.status;
        }
        state.userLoading = false;
      })
      .addCase(ApproveApplicationForWebAction.rejected, (state) => {
        state.userLoading = false;
      })

      // // For apply to job
      .addCase(ApplyHospitalJobAction.fulfilled, (state, action) => {
        let tempArray = state.hospitalDetails?.JobPost?.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, isApplied: true, type: "applied" };
          }
          return item;
        });
        state.hospitalDetails = {
          ...state.hospitalDetails,
          JobPost: tempArray,
        };
      })

      // For withdraw to job
      .addCase(WithdrawHospitalJobAction.fulfilled, (state, action) => {
        let tempArray = state.hospitalDetails?.JobPost?.map((item) => {
          if (item.id === action.payload.response.jobId) {
            return { ...item, isApplied: false, type: "apply" };
          }
          return item;
        });
        state.hospitalDetails = {
          ...state.hospitalDetails,
          JobPost: tempArray,
        };
      })

      //  For Get On Going Shift List
      .addCase(GetOnGoingShiftListAction.pending, (state) => {
        state.shiftLoading = true;
        state.shift = null;
      })
      .addCase(GetOnGoingShiftListAction.fulfilled, (state, action) => {
        state.shiftLoading = false;
        state.shift = action.payload.response;
      })
      .addCase(GetOnGoingShiftListAction.rejected, (state) => {
        state.shiftLoading = false;
        state.shift = null;
      });
  },
});

export const { setShiftFilterProps } = userSlice.actions;

export default userSlice.reducer;
