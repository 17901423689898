import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBadges,
  deleteBadges,
  updateVisiblityBadges,
  getBadges,
  updateBadges,
  getBadgesForWeb,
  addBadgesForWeb,
  getProfessionalRequestBadges,
  approveOrRejectBadges,
  getTransactionDetailsForWeb,
  getBadgesHistoryForWeb,
} from "store/services/badges";
import {
  closeAdminModal,
  openAdminModal,
} from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";
import { addUploadFile } from "store/services/users/professionals";
import { SuccessModalAdmin } from "components/molecules";
import React from "react";
import { setRemainingBalance } from "store/slices/admin/me.slice";

export const GetAllBadgesAction = createAsyncThunk(
  "badges/getAllBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const GetAllBadgesForWebAction = createAsyncThunk(
  "badges/getAllBadgesForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getBadgesForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

export const GetBadgesHistoryForWebAction = createAsyncThunk(
  "badges/getBadgesHistoryForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getBadgesHistoryForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        handleAuthError({ status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

export const AddBadgesForWebAction = createAsyncThunk(
  "badges/addAllBadgesForWeb",
  async (
    payload,
    { rejectWithValue, getState, dispatch, fulfillWithValue }
  ) => {
    let { name, onSuccess,  ...rest} = payload

    try {
      const { me } = getState();
      const res = await addBadgesForWeb(rest);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (payload.paymentMethod === "wallet") {
          if(onSuccess) {
            onSuccess()
          }
          let amount =
            Number(res?.response?.price) +
            Number(res?.response?.federalIncomeTax) +
            Number(res?.response?.stateIncomeTax);
          let remainingBalance = me?.me?.totalBalance - amount;
          dispatch(setRemainingBalance(remainingBalance));
          dispatch(openToast({ type: "success", message: res?.message }));
        } else {
          // console.log(res, "call");
          window.location = res.response.checkoutUrl;
        }

        return fulfillWithValue({...res, name: name});
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(
        handleAuthError({ status: error?.response?.status, isWeb: true })
      );
      return rejectWithValue(error);
    }
  }
);

export const DeleteBadgesAction = createAsyncThunk(
  "badges/deleteBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const UpdateBadgesAction = createAsyncThunk(
  "badges/updateBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, image, ...rest } = payload;
      if (file) {
        const uploadFileResponse = await addUploadFile({
          file,
          uploadFor: "banner",
        });
        if (uploadFileResponse.status === 1) {
          image = uploadFileResponse?.response?.urls[0];
        }
      }
      const res = await updateBadges({ image: image, ...rest });
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const AddBadgesAction = createAsyncThunk(
  "badges/addBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      const uploadFileResponse = await addUploadFile({
        file,
        uploadFor: "badges",
      });
      if (uploadFileResponse.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const res = await addBadges({
          image: uploadFileResponse?.response?.urls[0],
          ...rest,
        });
        if (res.status === 1) {
          dispatch(closeAdminModal());
          dispatch(openToast({ type: "success", message: res?.message }));
          return fulfillWithValue(res);
        }
        dispatch(openToast({ type: "error", message: res?.message }));
        return rejectWithValue({ message: res?.message });
      }
      dispatch(
        openToast({ type: "error", message: uploadFileResponse?.message })
      );
      return rejectWithValue({ message: uploadFileResponse?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//Update Visiblity Badges
export const UpdateVisiblityBadgesAction = createAsyncThunk(
  "badges/updateVisiblityBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblityBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//get Professional Request Badges
export const GetProfessionalRequestBadgesAction = createAsyncThunk(
  "badges/getProfessionalRequestBadgesAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getProfessionalRequestBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//get Professional Request Badges
export const ApproveOrRejectRequestAction = createAsyncThunk(
  "badges/approveOrRejectRequestAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await approveOrRejectBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: <SuccessModalAdmin msg={res?.message} />,
          })
        );
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(
        openToast({
          type: "error",
          message: res?.message,
        })
      );
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);


//get Transaction Details Badges
export const GetTransactionDetailsForWeb = createAsyncThunk(
  "badges/getTransactionDetailsForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getTransactionDetailsForWeb(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthError({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);
