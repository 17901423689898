import React from "react";
import Clock from "assets/images/SVG/clock orange.svg";
import CircleCheck from "assets/images/SVG/CredentialCheckCirle.svg";
import CircleClose from "assets/images/SVG/CredentialCloseCirle.svg";
import MuiTypography from "../MuiTypography";
import MuiBox from "../MuiBox";

const CredentailStatus = ({ type, status }) => {
  const statusImg = {
    pending: Clock,
    accepted: CircleCheck,
    rejected: CircleClose,
  };

  const typeObj = {
    avatar: "Avatar",
    licence: "Driving License",
    socialSecurity: "Social Security",
  };

  const statusCheck = {
    pending: `Your ${typeObj[type]} under review.`,
    accepted: `Your ${typeObj[type]} approved by admin.`,
    rejected: `Your ${typeObj[type]} rejected by admin.`,
  };

  return (
    <MuiBox
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        bgcolor: "#fff",
        borderRadius: "12px",
        p: "15px",
        mb: 2,
      }}
    >
      <img src={statusImg[status]} />
      <MuiTypography variant="baseFont">
        {statusCheck[status]}
      </MuiTypography>
    </MuiBox>
  );
};

export default CredentailStatus;
