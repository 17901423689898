import React, { createContext, useState } from "react";
export const HospitalSignUpContext = createContext();

const HospitalSignUpProvider = ({ children }) => {
  const [payload, setPayload] = useState(null);

  return (
    <HospitalSignUpContext.Provider
      value={{
        payload,
        setPayload,
      }}
    >
      {children}
    </HospitalSignUpContext.Provider>
  );
};

export default HospitalSignUpProvider;
