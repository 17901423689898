import { WarningRounded } from "@mui/icons-material";
import {
  Divider,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MuiBox from "../MuiBox";
import LocalPhoneOutlined from "assets/images/SVG/phone input icon.svg";
import DropdownIcon from "assets/images/SVG/chevron-down-gray.svg";
import MuiTypography from "../MuiTypography";
const CustomDropdownIcon = () => (
  <img
    src={DropdownIcon}
    alt="dropdown"
    style={{ pointerEvents: "none" }}
    className="no-select"
  />
);

const PhoneInput = ({
  inputValue,
  inputOnChange,
  inputOnBlur,
  inputName,
  placeholder,
  readOnly = false,
  allowOnBlur = false,
  error,
  errorMsg,
  options = ["+1", "+91", "+44", "+81", "+101"],
  label = "Enter",
  icon = <img src={LocalPhoneOutlined} style={{ flexShrink: 0 }} />,
  required = true,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const PlainSelect = styled(Select)(() => ({
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      margin: 0,
    },
    "& .MuiSelect-select": {
      paddingLeft: "10px",
      paddingRight: "10px !important",
      paddingTop: "0",
      paddingBottom: "0",
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
  }));

  return (
    <>
      <div>
        <Typography variant="baseFont" fontWeight={"bold"}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Typography>
      </div>
      <MuiBox
        sx={{
          maxWidth: "100%",
        }}
      >
        <OutlinedInput
          sx={{ width: "100%" }}
          variant="basic"
          placeholder={placeholder}
          onChange={(e) => {
            const sanitizedValue = e.target.value.replace(/\D/g, ""); // Allow only digits
            inputOnChange({
              target: {
                name: inputName,
                value: sanitizedValue,
              },
            });
          }}
          onBlur={(e) => {
            if (allowOnBlur) {
              inputOnBlur(e);
            }
          }}
          readOnly={readOnly}
          value={inputValue}
          name={inputName}
          inputProps={{ maxLength: 10 }}
          error={error}
          startAdornment={
            <InputAdornment position="start">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "14px",
                  height: "43px",
                  width: "41px",
                }}
              >
                {icon}
                <Divider
                  orientation="vertical"
                  variant="middle"
                  // ligh
                  flexItem
                  sx={{ backgroundColor: "inputBorder.main" }}
                />
              </div>
              <PlainSelect
                value={selectedOption}
                readOnly={readOnly}
                onChange={handleSelectChange}
                IconComponent={CustomDropdownIcon}
                sx={{
                  marginTop: "0",
                }}
              >
                {options.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText
                      primary={name}
                      sx={{ width: "fit-content", pr: "10px" }}
                    />
                  </MenuItem>
                ))}
              </PlainSelect>
            </InputAdornment>
          }
        />
      </MuiBox>
      {error && (
        <MuiBox sx={{ marginTop: "8px" }}>
          <WarningRounded color="error" size={20} />
          <MuiTypography
            component={"span"}
            sx={{ marginLeft: "5px" }}
            variant="error"
            color="error"
          >
            {errorMsg}
          </MuiTypography>
        </MuiBox>
      )}
    </>
  );
};

export default PhoneInput;
