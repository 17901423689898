import { WebInput, MuiBox, MuiTypography, Select } from "components/atoms";
import React from "react";
import * as Yup from "yup";
import Radius from "assets/images/SVG/radius mile.svg";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { UpdateMileageAction } from "store/middlewares/me";
const MileageRadiusModal = () => {
  const { me } = useSelector((state) => state.me);

  const dispatch = useDispatch();

  const mileageOptions = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
    { label: "25", value: "25" },
    { label: "30", value: "30" },
    { label: "35", value: "35" },
    { label: "40", value: "40" },
    { label: "45", value: "45" },
    { label: "50", value: "50" },
    { label: "Custom", value: "custom" },
  ];

  const handleSubmit = (values) => {
    const tempObj = {
      mileage:
        values?.mileage === "custom"
          ? String(values.customRadius)
          : values.mileage,
    };
    dispatch(UpdateMileageAction(tempObj));
  };

  const MileageSchema = Yup.object().shape({
    mileage: Yup.string().required("Please select a mileage radius"),
    customRadius: Yup.number()
      .nullable()
      .when("mileage", (mileage, schema) => {
        if (mileage[0] === "custom") {
          return schema
            .typeError("Radius must be a number")
            .required("Please enter custom value")
            .positive("Radius enter a positive number")
            .max(999999, "Radius must be at most 6 digits");
        }
        return schema.nullable();
      }),
  });

  const formik = useFormik({
    initialValues: {
      mileage:
        mileageOptions?.find((item) => item.value === me?.mileage)?.value ??
        "custom",
      customRadius: mileageOptions?.find((item) => item.value === me?.mileage)
        ? null
        : Number(me?.mileage),
    },
    enableReinitialize: true,
    validationSchema: MileageSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          alignItems: "center",
          px: { xs: "15px", sm: "20px", md: "30px" },
        }}
      >
        <MuiTypography variant="subtitle!" fontWeight="bold" align="center">
          Mileage Radius
        </MuiTypography>
        <Select
          variant="plain"
          label="Mileage Radius"
          name="mileage"
          required={true}
          value={formik.values.mileage}
          error={formik.errors.mileage && formik.touched.mileage}
          errorMsg={formik.errors.mileage}
          icon={<img src={Radius} />}
          options={mileageOptions}
          multiple={false}
          onChange={(e) => {
            formik.setFieldValue("mileage", e);
            if (e !== "custom") {
              formik.setFieldValue("customRadius", 0);
            }
          }}
        />

        {formik.values.mileage === "custom" && (
          <WebInput
            variant="plain"
            placeholder="Enter Custom Radius"
            label="Custom"
            type="number"
            icon={<img src={Radius} />}
            name="customRadius"
            value={formik.values.customRadius}
            error={formik.errors.customRadius && formik.touched.customRadius}
            errorMsg={formik.errors.customRadius}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        )}

        <Button type="submit" variant="lightButton" sx={{ maxWidth: "338px" }}>
          Done
        </Button>
      </MuiBox>
    </form>
  );
};

export default MileageRadiusModal;
