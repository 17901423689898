import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomLoader,
  TableContainer,
  TooltipProvider,
} from "components/atoms";
import { IconButton, Typography } from "@mui/material";
import { GetProfessionalJobsAction } from "store/middlewares/jobs";
import { ChevronsRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const UserHolidayTab = ({ id }) => {
  const { jobs, jobsLoading } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetProfessionalJobsAction({ id }));
  }, [dispatch, id]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Shift Title",
        accessorKey: "jobPost.JobPostDetails.shiftTitle",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Shift Type",
        accessorKey: "jobPost.JobPostDetails.shiftType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p">
            {row?.original?.jobPost?.JobPostDetails?.shiftType === "fullTime"
              ? "Full Time"
              : "Part Time"}
          </Typography>
        ),
      },
      {
        header: "Employee Hour Rate",
        accessorKey: "jobPost.JobPostDetails.employeeHourRate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {`$ ${row?.original?.jobPost?.JobPostDetails?.employeeHourRate.toFixed(
              2
            )}`}
          </Typography>
        ),
      },
      {
        header: "Total Cost Per Hour",
        accessorKey: "jobPost.JobPostDetails.totalCostPerHour",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {`$ ${row?.original?.jobPost?.JobPostDetails?.totalCostPerHour.toFixed(
              2
            )}`}
          </Typography>
        ),
      },
      {
        header: "Applied",
        accessorKey: "isApplied",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p">
            {row.original.isApplied ? "Yes" : "No"}
          </Typography>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <IconButton
            sx={{ marginLeft: "auto" }}
            color="info"
            disableRipple
            onClick={() =>
              navigate(`/admin/jobsDetails/${row?.original?.jobPost?.id}`)
            }
          >
            <TooltipProvider title="Detail">
              <ChevronsRight />
            </TooltipProvider>
          </IconButton>
        ),
      },
    ],
    []
  );
  return (
    <>
      {!jobsLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={jobs?.items ? jobs.items : []}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UserHolidayTab;
