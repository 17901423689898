import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllNotification,
  getNOtificationSettings,
  updateNotificationSettings,
} from "store/services/notification";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";

export const GetNotificationSettings = createAsyncThunk(
  "notitfication/getNotificationSettings",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getNOtificationSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

// For get all notifications
export const GetAllNotification = createAsyncThunk(
  "notitfication/getAllNotification",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getAllNotification(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const UpdateNotificationSettings = createAsyncThunk(
  "notitfication/updateNotificationSettings",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateNotificationSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);
