import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import { useSelector } from "react-redux";
import { statusColorCode, statusText, statusTextColorCode } from "utils/directHireStatus";
// import PhoneIcon from "assets/images/SVG/Smartphone.svg";
const ApplicantCard = ({ item }) => {
  const { dynamicMenu } = useSelector((state) => state.dynamicMenu);
  const isHospitalMessage = dynamicMenu.find(
    (item) => item.name === "message"
  )?.isVisible;
  return (
    <MuiBox
      component={Link}
      to={`/hospital/direct-hire/${item?.jobId}`}
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        gap: 1,
      }}
    >
      <MuiBox sx={{ display: "flex", gap: "12px", }}>
        <MuiBox
          sx={{
            width: "81px",
            height: "81px",
            borderRadius: "9px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img src={item?.finalData?.profileImageUrl} alt="Applicant" className="img-cover" />
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxWidth: "17em",
          }}
        >
          <MuiTypography color="text.main" variant="baseFont" fontWeight="bold">
            {item?.finalData?.name}{" "}{item?.finalData?.lastName}
          </MuiTypography>
          <MuiTypography color="text.contrastText" fontSize="14px">
            {item?.finalData?.jobTitles?.title}
          </MuiTypography>
          <MuiBox
            sx={{
              width: "fit-content",
              p: "5px 12px",
              bgcolor: statusColorCode[item?.hireStatus],
              borderRadius: "30px",
            }}
          >
            <MuiTypography color={statusTextColorCode[item?.hireStatus]} fontSize="12px">
              {statusText[item?.hireStatus]}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          gap: "12px",
          width: "fit-content",
          flexDirection: "column",
        }}
      >
        {/* <IconButton
          aria-label="phone"
          sx={{
            height: "30px",
            width: "30px",
            bgcolor: "darkButton.main",
            transition: "all 0.3s",
            borderRadius: "6px",
            overflow: "hidden",
            p: "5px",
            "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
          }}
        >
          <img src={PhoneIcon} />
        </IconButton> */}
        {isHospitalMessage && <IconButton
          aria-label="phone"
          sx={{
            height: "30px",
            width: "30px",
            bgcolor: "darkButton.main",
            transition: "all 0.3s",
            borderRadius: "6px",
            overflow: "hidden",
            p: "5px",
            "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
          }}
        >
          <img src={ChatIcon} />
        </IconButton>}
      </MuiBox>
    </MuiBox>
  );
};

export default ApplicantCard;
