export default function getFullMonthName(shortForm) {
  const months = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };

  return months[shortForm.toLowerCase()] || "Invalid month";
}
