// import { MapModal } from "components/molecules";
// import { useDispatch } from "react-redux";
// import { openDrawer } from "store/slices/common/drawer.slice";
// import React from "react";

export const handleAddressClick = (temp) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      temp
    )}`,
    "_blank"
  );
};


