import { Button, FormControl, Slider } from "@mui/material";
import {
  WebInput,
  MuiBox,
  MuiTypography,
  useGooglePlaces,
} from "components/atoms";
import React, { useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Location from "assets/images/SVG/LocationIcon.svg";
import Navigator from "assets/images/SVG/Navigator.svg";
import Star from "assets/images/SVG/Star.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { useFormik } from "formik";

const ProfessionalFilterModal = ({ onClickFun, params, applicant = false, resetParams }) => {

  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.settings);

  const handleSubmit = (values) => {
    let tempObj = {
      filterByRatings: values?.filterByRatings ? values?.filterByRatings : null,
      filterByMiles: values?.filterByMiles || Number(items?.defaultDistance),
    };
    if (!applicant) {
      tempObj = {
        ...tempObj,
        filterByAscDesc: values?.filterByAscDesc,
        page: 1,
      };
    } else {
      tempObj = { ...tempObj, filterBy: values?.filterBy };
    }
    if (values.latitude && values.longitude) {
      tempObj = {
        ...tempObj,
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        filterByMiles: values.filterByMiles,
      };
    }
    onClickFun(tempObj);
  };

  const formik = useFormik({
    initialValues: {
      filterByMiles: params?.filterByMiles || Number(items?.defaultDistance),
      filterByRatings: params?.filterByRatings ? params?.filterByRatings : null,
      filterByAscDesc:
        applicant === false
          ? params?.filterByAscDesc
            ? params?.filterByAscDesc
            : "asc"
          : null,
      latitude: params?.latitude ? params?.latitude : null,
      longitude: params?.longitude ? params?.longitude : null,
      filterBy:
        applicant === true
          ? params?.filterBy
            ? params?.filterBy
            : "asc"
          : null,
    },
    validationSchema: null,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const reviews = ["1", "2", "3", "4", "5"];
  const sortBy = [
    { label: "Sort by A-Z", value: "asc" },
    { label: "Sort by Z-A", value: "desc" },
  ];

  const inputRef = useRef(null);
  const { initializePlaces } = useGooglePlaces();

  const handleChange = (placeDetails) => {
    // ---------------- set value -----------------//
    formik.setFieldValue("latitude", placeDetails.latLng.lat);
    formik.setFieldValue("longitude", placeDetails.latLng.lng);
    // ---------------- set value -----------------//
  };

  // useEffect(() => {
  //   formik.setFieldValue(
  //     "filterByMiles",
  //     params?.filterByMiles || Number(items?.defaultDistance)
  //   );
  // }, [params?.filterByMiles]);


  return (
    <MuiBox sx={{ p: { xs: "16px 10px", sm: "16px 32px" } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter By
      </MuiTypography>
      <MuiBox
        component={"form"}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        onSubmit={formik.handleSubmit}
      >
        <MuiBox
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: { xs: "12px 30px", sm: "19px" },
            mt: "32px",
            flexWrap: "wrap",
          }}
        >
          {sortBy?.map((item) => {
            return (
              <MuiBox
                key={item?.value}
                sx={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  bgcolor: "#fff",
                  p: "6px 15px",
                  borderRadius: "18px",
                  border: "1px solid transparent",
                  borderColor:
                    item.value === formik.values.filterByAscDesc ||
                      item.value === formik.values.filterBy
                      ? "#0ca8df"
                      : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (applicant) {
                    formik.setFieldValue("filterBy", item?.value);
                  } else {
                    formik.setFieldValue("filterByAscDesc", item?.value);
                  }
                }}
              >
                <MuiTypography variant="errorMsg" fontWeight={"bold"}>
                  {item?.label}
                </MuiTypography>
              </MuiBox>
            );
          })}
        </MuiBox>
        <FormControl>
          <WebInput
            variant="plain"
            placeholder={"Enter here"}
            label={"Location"}
            icon={<img src={Location} alt="Location" />}
            name={"location"}
            ref={inputRef}
            endIcon={<img src={Navigator} alt="Navigator" />}
            onChange={(e) => {
              formik.handleChange(e);
              initializePlaces(inputRef, handleChange);
            }}
            required={false}
            onBlur={formik.handleBlur}
            value={formik.values.location || ""}
            errorMsg={formik.errors.location}
            error={formik.touched.location && formik.errors.location}
          />
        </FormControl>
        <FormControl>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MuiTypography variant="baseFont" fontWeight={"bold"}>
              Distance
            </MuiTypography>
            <MuiBox
              sx={{
                width: "fit-content",
                borderRadius: "6px",
                border: "1px solid #000000",
                p: "0 6px",
              }}
            >
              <MuiTypography sx={{ fontSize: "14px" }}>
                {formik.values.filterByMiles}+ Miles
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox
            sx={{
              bgcolor: "#fff",
              p: "12px 25px 6px 25px",
              borderRadius: "12px",
              mt: "8px",
            }}
          >
            <Slider
              className="distance-slider"
              min={Number(items?.minimumDistance) || 0}
              max={Number(items?.maximumDistance) || 100}
              value={formik.values.filterByMiles || Number(items?.defaultDistance)}
              step={1}
              onChange={(event, newValue) => {
                formik.setFieldValue("filterByMiles", newValue);
              }}
            />


          </MuiBox>
        </FormControl>
        <FormControl>
          <MuiTypography variant="baseFont" fontWeight={"bold"}>
            Reviews
          </MuiTypography>
          <MuiBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: "12px 30px", sm: "19px" },
              mt: "8px",
              flexWrap: "wrap",
            }}
          >
            {reviews.map((item, index) => (
              <MuiBox
                key={index}
                sx={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  bgcolor: "#fff",
                  p: "6px 15px",
                  borderRadius: "12px",
                  border: "1px solid transparent",
                  borderColor:
                    item === formik.values.filterByRatings
                      ? "#0a1e41"
                      : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => {
                  formik.setFieldValue(
                    "filterByRatings",
                    item === formik.values.filterByRatings ? null : item
                  );
                }}
              >
                <img
                  src={Star}
                  style={{
                    height: "25px",
                    width: "25px",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
                <MuiTypography variant="subtitle1">{item}</MuiTypography>
              </MuiBox>
            ))}
          </MuiBox>
        </FormControl>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Button
              variant="darkButton"
              onClick={() => {
                formik.resetForm();
                resetParams();
                dispatch(closeDrawer());
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={12} sm={6}>
            <Button
              variant="lightButton"
              type="submit"
            // onClick={() => onClick({ filterByMiles: value })}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </MuiBox>
    </MuiBox>
  );
};

export default ProfessionalFilterModal;
