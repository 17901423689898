import { createAsyncThunk } from "@reduxjs/toolkit";
import { addExperience, deleteExperience, editExperience, getExperienceForWeb } from "store/services/experience";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

export const GetExperienceWebAction = createAsyncThunk(
  "experience/getExperienceWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getExperienceForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const AddExperienceAction = createAsyncThunk(
  "experience/addExperience",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addExperience(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const EditExperienceAction = createAsyncThunk(
  "experience/editExperience",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await editExperience(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);

export const DeleteExperienceAction = createAsyncThunk(
  "experience/deleteExperience",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteExperience(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer())
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({...payload});
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);
