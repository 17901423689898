import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionHistory,getAllAdminTransHistory, getUserAllTransHistory, getShiftTransactionDetails } from "store/services/transactions";
import { handleAuthError, handleAuthErrorAdmin } from "utils/handleAuthError";

export const GetTransactionHistory = createAsyncThunk(
  "transactionHistory/getTransactionHistory",
  async (payload, { rejectWithValue, getState, dispatch, fulfillWithValue }) => {
    try {
      const { me } = getState();
      const response = await getTransactionHistory({ role: me?.me?.role, ...payload });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
     dispatch(handleAuthError({status: error?.response?.status, isWeb: true}));
      return rejectWithValue(error);
    }
  }
);


// get all admin earning transction 
export const GetAllAdminEarnTransHistory = createAsyncThunk(
  "transactionHistory/getAllAdminEarnTransHistory",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getAllAdminTransHistory();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

// get all admin earning transction 
export const GetUserAllTransHistory = createAsyncThunk(
  "transactionHistory/getUserAllTransHistory",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getUserAllTransHistory(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);

// get all admin earning transction 
export const GetShiftTransactionDetailsAction = createAsyncThunk(
  "transactionHistory/getShiftTransactionDetailsAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getShiftTransactionDetails(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthErrorAdmin({status: error?.response?.status}));
      return rejectWithValue(error);
    }
  }
);
