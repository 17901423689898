import { MuiBox, MuiTypography, SearchInput } from "components/atoms";
import React, { Fragment, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { useFormik } from "formik";
import { Info } from "lucide-react";

const SelectionModal = ({
  onClick,
  selected,
  options,
  multiple,
  title = "Speciality",
  forCredential = false,
}) => {
  const [listStyle, setListStyle] = useState(selected ? selected : []);

  const [optionsToDisplay, setOptionsToDisplay] = useState(options);

  const handleSubmit = (values) => {
    if (values.search) {
      setOptionsToDisplay(
        options?.filter((item) =>
          item?.label.toLowerCase().includes(values.search.toLowerCase())
        )
      );
    } else {
      setOptionsToDisplay(options);
    }
  };

  const formik = useFormik({
    initialValues: { search: "" },
    enableReinitialize: true,
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  const dispatch = useDispatch();
  const handleToggleStyle = (title) => {
    let tempArray = listStyle;
    if (multiple) {
      if (listStyle?.includes(title)) {
        tempArray = listStyle?.filter((item) => item !== title);
      } else {
        tempArray = [...tempArray, title];
      }
    } else {
      tempArray = [title];
    }
    setListStyle(tempArray);
    onClick(tempArray);
    if (!multiple) {
      dispatch(closeDrawer());
    }
  };

  return (
    <MuiBox sx={{ px: "30px" }}>
      <MuiTypography
        variant="h6"
        fontWeight={"bold"}
        align={"center"}
        sx={{ mb: "15px" }}
      >
        {title}
      </MuiTypography>
      <form onSubmit={formik.handleSubmit}>
        <SearchInput
          type="text"
          variant="plain"
          name={"search"}
          value={formik.values.search}
          onChange={formik.handleChange}
          onBlur={formik.handleSubmit}
          placeholder="Search here..."
        />
      </form>
      <MuiBox sx={{ mt: 2 }}>
        <List>
          {optionsToDisplay?.map((title) => {
            return (
              <Fragment key={title?.value}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 0 }}
                    onClick={() => handleToggleStyle(title?.value)}
                  >
                    <ListItemText
                      primary={
                        <MuiBox
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {title?.label}{" "}
                          <Tooltip title={title?.description} placement="top">
                            <IconButton
                              sx={{
                                py: "0 !important",
                              }}
                              disableRipple
                            >
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </MuiBox>
                      }
                      sx={{
                        color: listStyle?.includes(title?.value)
                          ? forCredential
                            ? "background.dark"
                            : "text.dark"
                          : "button.light",
                        fontWeight: listStyle?.includes(title?.value)
                          ? "800 !important"
                          : "400 !important",
                        span: {
                          fontWeight: listStyle?.includes(title?.value)
                            ? "800 !important"
                            : "400 !important",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </MuiBox>
    </MuiBox>
  );
};

export default SelectionModal;
