import { MuiBox, MuiTypography } from "components/atoms";
import React, { Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Cap from "assets/images/SVG/Graduation cap.svg";
import Beg from "assets/images/SVG/begWhite.svg";
import { handleAddressClick } from "utils/handleAddressClick";

const ResumeDetails = ({ singleResume }) => {
  return (
    <Fragment>
      <MuiBox>
        <MuiTypography
          fontWeight="bold"
          variant="baseFont"
          component={"p"}
          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {singleResume?.Resume?.personalData?.name}
        </MuiTypography>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          gap: { xs: 2, sm: 2, md: 3 },
          alignItems: "flex-start",
          flexDirection: { xs: "column", lg: "row" },
          mt: 2,
        }}
      >
        <MuiBox>
          <Grid container spacing={4}>
            <Grid xs={12} md={6}>
              <MuiBox sx={{ mb: 4 }}>
                <MuiTypography
                  color="text.main"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  About You
                </MuiTypography>
                <MuiBox
                  sx={{
                    bgcolor: "#fff",
                    p: 2,
                    borderRadius: "12px",
                    mt: "5px",
                    border: "1px solid #D5D4D7",
                  }}
                >
                  <MuiTypography
                    fontSize="14px"
                    color="#0A1E41"
                    lineHeight="19.49px"
                    sx={{ overflow: "hidden" }}
                  >
                    {singleResume?.Resume?.personalData?.about}
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </Grid>
            <Grid xs={12} md={6}>
              <MuiBox sx={{ mb: 4 }}>
                <MuiTypography
                  color="text.main"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  Address
                </MuiTypography>
                <MuiBox
                  sx={{
                    bgcolor: "#fff",
                    p: 2,
                    borderRadius: "12px",
                    mt: "5px",
                    border: "1px solid #D5D4D7",
                  }}
                  onClick={() =>
                    handleAddressClick(
                      [
                        singleResume?.Resume?.personalData?.street,
                        singleResume?.Resume?.personalData?.city,
                        singleResume?.Resume?.personalData?.state,
                        singleResume?.Resume?.personalData?.country,
                        singleResume?.Resume?.personalData?.zipCode,
                      ]
                        .filter(Boolean)
                        .join(", ")
                    )
                  }
                >
                  <MuiTypography
                    fontSize="14px"
                    color="#0A1E41"
                    lineHeight="19.49px"
                    sx={{ overflow: "hidden" }}
                  >
                    {[
                      singleResume?.Resume?.personalData?.street,
                      singleResume?.Resume?.personalData?.city,
                      singleResume?.Resume?.personalData?.state,
                      singleResume?.Resume?.personalData?.country,
                      singleResume?.Resume?.personalData?.zipCode,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <MuiBox>
                <MuiBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MuiTypography
                    color="text.main"
                    fontSize="16px"
                    fontWeight="bold"
                  >
                    Education
                  </MuiTypography>
                </MuiBox>
                {singleResume?.Resume?.education?.map((item) => {
                  return (
                    <MuiBox
                      key={item?.id}
                      sx={{
                        p: "12px",
                        bgcolor: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                        mt: "5px",
                        border: "1px solid #D5D4D7",
                      }}
                    >
                      <MuiBox
                        sx={{
                          height: "41px",
                          width: "41px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          flexShrink: 0,
                          bgcolor: "#0CA8DF",
                          display: "flex",
                          placeContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={Cap}
                          style={{
                            height: "24px",
                            width: "24px",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                        />
                      </MuiBox>
                      <MuiBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          overflow: "hidden",
                        }}
                      >
                        <MuiTypography
                          variant="subtitle1"
                          fontWeight="bold"
                          lineHeight="19.49px"
                          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                        >
                          Studied at {item?.college}
                        </MuiTypography>
                        <MuiTypography
                          fontSize="14px"
                          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                          lineHeight="20px"
                          color="text.contrastText"
                        >
                          {item?.degree}
                        </MuiTypography>
                        <MuiTypography
                          fontSize="14px"
                          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                          lineHeight="20px"
                          color="text.contrastText"
                        >
                          Batch {item?.startMonth?.substring(0, 3)},
                          {item?.startYear} to {item?.endMonth?.substring(0, 3)}
                          ,{item?.endYear}
                        </MuiTypography>
                        <MuiTypography
                          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                          fontSize="16px"
                          lineHeight="20px"
                          color="text.contrastText"
                        >
                          {item?.description}
                        </MuiTypography>
                      </MuiBox>
                    </MuiBox>
                  );
                })}
              </MuiBox>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              {singleResume?.Resume?.experience?.length > 0 && (
                <MuiBox>
                  <MuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <MuiTypography
                      color="text.main"
                      fontSize="16px"
                      fontWeight="bold"
                    >
                      Experience
                    </MuiTypography>
                  </MuiBox>
                  {singleResume?.Resume?.experience?.map((item) => {
                    return (
                      <MuiBox
                        key={item?.id}
                        sx={{
                          p: "12px",
                          bgcolor: "#fff",
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 1,
                          mt: "5px",
                          border: "1px solid #D5D4D7",
                        }}
                      >
                        <MuiBox
                          sx={{
                            height: "41px",
                            width: "41px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            flexShrink: 0,
                            bgcolor: "#0CA8DF",
                            display: "flex",
                            placeContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={Beg}
                            style={{
                              height: "24px",
                              width: "24px",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </MuiBox>
                        <MuiBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            overflow: "hidden",
                          }}
                        >
                          <MuiTypography
                            variant="subtitle1"
                            fontWeight="bold"
                            lineHeight="19.49px"
                          >
                            {item?.hospitalName}
                          </MuiTypography>
                          <MuiTypography
                            fontSize="14px"
                            lineHeight="20px"
                            color="text.contrastText"
                            sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                          >
                            {item?.role}
                          </MuiTypography>
                          <MuiTypography
                            fontSize="14px"
                            lineHeight="20px"
                            color="text.contrastText"
                          >
                            {item?.startMonth?.substring(0, 3)},
                            {item?.startYear} to{" "}
                            {item?.toPresent
                              ? "Present"
                              : `${item?.endMonth?.substring(0, 3)},
                      ${item?.endYear}`}
                          </MuiTypography>
                          <MuiTypography
                            fontSize="16px"
                            lineHeight="20px"
                            color="text.contrastText"
                            sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                          >
                            {item?.address}
                          </MuiTypography>
                        </MuiBox>
                      </MuiBox>
                    );
                  })}
                </MuiBox>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <MuiTypography
                color="text.main"
                fontSize="16px"
                fontWeight="bold"
              >
                Skills
              </MuiTypography>
              <MuiBox
                sx={{
                  p: "12px",
                  bgcolor: "#fff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  mt: "5px",
                  flexWrap: "wrap",
                  border: "1px solid #D5D4D7",
                }}
              >
                {singleResume?.skill?.map((item, index) => (
                  <MuiBox
                    key={index}
                    sx={{
                      width: "fit-content",
                      p: "10px 18px",
                      bgcolor: "#CCEEFA",
                      borderRadius: "50px",
                    }}
                  >
                    <MuiTypography fontSize="14px" color="text.dark">
                      {item.name}
                    </MuiTypography>
                  </MuiBox>
                ))}
              </MuiBox>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <MuiTypography
                color="text.main"
                fontSize="16px"
                fontWeight="bold"
              >
                Language
              </MuiTypography>
              <MuiBox
                sx={{
                  p: "12px",
                  bgcolor: "#fff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  mt: "5px",
                  flexWrap: "wrap",
                  border: "1px solid #D5D4D7",
                }}
              >
                {singleResume?.language?.map((item, index) => (
                  <MuiBox
                    key={index}
                    sx={{
                      width: "fit-content",
                      p: "10px 18px",
                      bgcolor: "#CCEEFA",
                      borderRadius: "50px",
                    }}
                  >
                    <MuiTypography fontSize="14px" color="text.dark">
                      {item.language}
                    </MuiTypography>
                  </MuiBox>
                ))}
              </MuiBox>
            </Grid>
          </Grid>
        </MuiBox>
      </MuiBox>
    </Fragment>
  );
};

export default ResumeDetails;
