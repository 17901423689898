export const deductionTitleObj = {
  federalIncomeTax: "Federal Income Taxes",
  stateIncomeTax: "State Income Taxes",
  socialSecurityTax: "Social Security Taxes",
  medicareTax: "Medicare Taxes",
  fourZeroOneK: "401(k)",
  workersCompensation: "Workers’ Compensation",
  generalLiability: "General Liability",
  wiselyPaycardProcessing: "Wisely Paycard Processing",
  backgroundAndDrugTesting: "Background and Drug Testing",
  agencyFee: "Agency Fee",
  burden: "Burden Rate",
  totalDeductions: "Total Deductions",
  processingFee: "Processing Fee",
};
