import axiosClient from "utils/api";
const URI = "/admin";

// For get all jobs
export const getJobs = async (payload) => {
  let tempURL = payload
    ? `admin/jobs/hospitalId/${payload.id}`
    : "admin/jobs/jobs";
  try {
    const res = await axiosClient().request({
      url: tempURL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get All Jobs:", error.message);
    throw error;
  }
};

// For get professional jobs
export const getProfessionalJobs = async (payload) => {
  const URL = `${URI}/jobs/professionalId/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Professional Jobs:", error);
    throw error;
  }
};

// For get professional jobs
export const getJobDetailsAction = async (payload) => {
  const URL = `${URI}/jobs/jobId/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Job Detail:", error);
    throw error;
  }
};

// For get all jobs
export const getAllDirectHire = async (payload) => {
  let tempURL = `${URI}/jobs/directHireList`;
  try {
    const res = await axiosClient().request({
      url: tempURL,
      method: "GET",
      params: payload,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get All Direct Hire:", error.message);
    throw error;
  }
};

// For get all jobs
export const getDirectHireDetails = async (payload) => {
  let { id, ...rest} = payload 
  let tempURL = `${URI}/jobs/directHireList/${id}`;
  try {
    const res = await axiosClient().request({
      url: tempURL,
      method: "GET",
      params: rest,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Direct Hire By Users:", error.message);
    throw error;
  }
};

// For get all jobs
export const getDirectHireByUsers = async (payload) => {
  let { id, ...rest} = payload 
  let tempURL = `${URI}/jobs/directHireListByUsers/${id}`;
  try {
    const res = await axiosClient().request({
      url: tempURL,
      method: "GET",
      params: rest,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Direct Hire By Users:", error.message);
    throw error;
  }
};
