import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FullScreenImageModel from "../FullScreenImageModel";

import Clock from "assets/images/SVG/clockShift.svg";
import ClockBlack from "assets/images/SVG/clockBlack.svg";
import Beg from "assets/images/SVG/beg.svg";
import Star from "assets/images/SVG/Star.svg";
import Calender from "assets/images/SVG/Calendar.svg";

// Enable the custom parse format plugin
dayjs.extend(customParseFormat);
const ProfessionalShiftCard = ({ schedule }) => {
  return (
    <MuiBox
      component={Link}
      to={`/hospital/schedule/${schedule?.status?.toLowerCase()}/schedule-details/${schedule?.id}/${schedule?.dateScheduleId}`}
      sx={{
        background: "#fff",
        borderRadius: "12px",
        p: { xs: 1, md: 2 },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        height: "100%",
      }}
    >
      <MuiBox
        sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
        className="ongoing-schedule-card"
      >
        <MuiBox
          sx={{
            height: "99px",
            width: "99px",
            borderRadius: "6px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          {/* <img
            // src={schedule?.profileImageUrl ? schedule?.profileImageUrl : Shift}
            src={
              schedule?.profileImageUrl
                ? error
                  ? Shift
                  : schedule?.profileImageUrl
                : Shift
            }
            className={
              schedule?.profileImageUrl
                ? error
                  ? "img-contain"
                  : "img-cover"
                : "img-contain"
            }
            onError={() => {
              setError(true);
            }}
          /> */}
          <FullScreenImageModel
            imgSrc={schedule?.profileImageUrl}
          />
        </MuiBox>
        <MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexWrap: "wrap-reverse",
              justifyContent: "space-between",
            }}
          >
            <MuiBox
              sx={{ width: "auto", minWidth: "170px", maxWidth: "200px" }}
            >
              <MuiTypography
                color="text.main"
                variant="subtitle1"
                fontWeight="bold"
              >
                {schedule?.name} {schedule?.lastName}
              </MuiTypography>
              <MuiTypography
                color="text.contrastText"
                sx={{ fontSize: "14px", overflow: "hidden" }}
              >
                {schedule?.jobTitle}
              </MuiTypography>
            </MuiBox>
            {(schedule?.isClockIn || schedule?.isClockOut) && (
              <MuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  bgcolor: "#C0F8E5",
                  width: "fit-content",
                  p: "5px 10px",
                  borderRadius: "6px",
                }}
              >
                <img
                  src={Clock}
                  style={{
                    height: "20px",
                    width: "24px",
                    objectFit: "contain",
                  }}
                />
                <MuiTypography
                  color="#07B67C"
                  fontSize="12px"
                  sx={{ textWrap: "nowrap" }}
                >
                  {schedule?.isClockIn ? "Clocked in" : "Clocked Out"}
                </MuiTypography>
              </MuiBox>
            )}
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mt: "10px",
              flexWrap: "wrap",
            }}
          >
            <MuiBox
              sx={{
                width: "fit-content",
                bgcolor: "#E8F0F3",
                p: "5px 10px",
                borderRadius: "6px",
              }}
            >
              <MuiTypography
                color="text.dark"
                fontSize="14px"
                fontWeight="bold"
              >
                {schedule?.shiftId}
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MuiBox
                sx={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "6px",
                  bgcolor: "#E8F0F3",
                  p: "5px",
                }}
              >
                <img
                  src={Beg}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.main"
                fontWeight="bold"
                sx={{ fontSize: "14px" }}
              >
                {schedule?.totalYearsOfExperience} years
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MuiBox sx={{ width: "18px", height: "18px" }}>
                <img
                  src={Star}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.contrastText"
                sx={{ fontSize: "14px" }}
              >
                {Number(schedule.reviewRatings).toFixed(1)} ({schedule?.reviewCount})
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox sx={{ mt: "auto" }}>
        <Divider />
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
            py: "5px",
            gap: "5px",
            textWrap: "wrap",
          }}
        >
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img src={Calender} alt="date" />
            <MuiTypography fontSize="14px" color="text.dark">
              {dayjs(schedule?.scheduleCurrentDate).format("DD/MM/YYYY")}
            </MuiTypography>
          </MuiBox>
          <Divider orientation="vertical" flexItem />
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src={ClockBlack}
              alt="clock"
              style={{ height: "23px", width: "23px" }}
            />
            <MuiTypography
              fontSize="14px"
              color="text.dark"
              sx={{ textWrap: "nowrap" }}
            >
              {dayjs(schedule?.startTime).format("HH:mm")} -{" "}
              {dayjs(schedule?.endTime).format("HH:mm")}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ProfessionalShiftCard;
