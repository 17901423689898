import React, { useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CustomTimePicker,
  MuiBox,
  MuiTypography,
  useGooglePlaces,
  WebInput,
} from "components/atoms";
import * as Yup from "yup";
import { Button, FormControl, Slider } from "@mui/material";

import LocationIcon from "assets/images/SVG/LocationIcon.svg";
import { useFormik } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useSelector } from "react-redux";

dayjs.extend(utc);
dayjs.extend(timezone);
const ScheduleFilterModal = ({ params, onSubmit }) => {
  const [customErrors, setCustomErrors] = useState(false);
  const { items } = useSelector((state) => state.settings);
  const Addhospitalscema = Yup.object({
    startTime: "",
    endTime: "",
    distance: "",
  });

  let intitalVal = {
    startTime: "",
    endTime: "",
    distance: 0,
    latitude: "",
    longitude: "",
    address: "",
  };

  const handleSubmit = (values) => {
    if (!customErrors) {
      let tempObj = {
        endTime: values.endTime
          ? dayjs(values.endTime).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        startTime: values.startTime
          ? dayjs(values.startTime).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
      };
      if (values.latitude && values.longitude) {
        tempObj = {
          ...tempObj,
          address: values.address,
          latitude: values.latitude,
          longitude: values.longitude,
          distance: values.distance,
        };
      }
      onSubmit(tempObj);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...intitalVal,
      ...params,
      distance: Number(items?.defaultDistance),
      endTime: params?.endTime
        ? dayjs.utc(params.endTime).local().format("YYYY-MM-DDTHH:mm:ss")
        : null,
      startTime: params?.startTime
        ? dayjs.utc(params.startTime).local().format("YYYY-MM-DDTHH:mm:ss")
        : null,
    },
    validationSchema: Addhospitalscema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  const handleSliderChange = (event, newValue) => {
    if (newValue !== null) {
      formik.setFieldValue("distance", newValue);
    }
  };

  // ---------------- Google Place API -----------------//
  const inputRef = useRef(null);
  const { initializePlaces } = useGooglePlaces();

  const setMessage = (e) => {
    setCustomErrors(Boolean(e));
    let messages = "";
    // if (e === "maxTime" || e === "minTime") {
    //   messages = "Invalid Date Range outer";
    // } else {
    //   messages = "";
    // }
    return messages;
  };

  const handleChange = (placeDetails) => {
    // ---------------- set value -----------------//
    formik.setFieldValue("latitude", placeDetails.latLng.lat);
    formik.setFieldValue("longitude", placeDetails.latLng.lng);
    // ---------------- set value -----------------//
  };

  return (
    <MuiBox
      component={"form"}
      onSubmit={formik.handleSubmit}
      sx={{ px: { xs: "10px", sm: "20px", md: "30px" } }}
    >
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter By
      </MuiTypography>
      <MuiBox>
        <WebInput
          ref={inputRef}
          type="text"
          label="Address"
          name="address"
          required={false}
          placeholder="Enter Address"
          icon={<img src={LocationIcon} alt="address" />}
          onChange={(e) => {
            formik.handleChange(e);
            initializePlaces(inputRef, handleChange);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.address || ""}
          errorMsg={formik.errors.address}
          error={formik.touched.address && formik.errors.address}
        />
      </MuiBox>
      <MuiBox sx={{ mt: 4 }}>
        <FormControl sx={{ width: "100%" }}>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MuiTypography variant="baseFont" fontWeight={"bold"}>
              Radius
            </MuiTypography>
            <MuiBox
              sx={{
                width: "fit-content",
                borderRadius: "6px",
                border: "1px solid #000000",
                p: "0 6px",
              }}
            >
              <MuiTypography sx={{ fontSize: "14px" }}>
                {formik.values.distance}+ Miles
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox
            sx={{
              bgcolor: "#fff",
              p: "12px 25px 6px 25px",
              borderRadius: "12px",
              mt: "8px",
            }}
          >
            <Slider
              className="distance-slider"
              min={Number(items?.minimumDistance)}
              max={Number(items?.maximumDistance)}
              value={formik.values.distance}
              step={1}
              onChange={handleSliderChange}
              // onChangeCommitted={handleSliderChangeCommitted}
              aria-labelledby="distance-slider"
            />
          </MuiBox>
        </FormControl>
      </MuiBox>
      <MuiBox sx={{ mt: 2 }}>
        <Grid container spacing={"25px"} sx={{ mt: "20px" }}>
          <Grid xs={6}>
            <CustomTimePicker
              label={"Start Time"}
              name={`startTime`}
              value={
                formik.values?.startTime ? dayjs(formik.values.startTime) : null
              }
              onChange={(e) => {
                if (e) {
                  formik.setFieldValue(`startTime`, dayjs(e));
                  formik.setFieldTouched(`startTime`, true);
                } else {
                  formik.setFieldValue(`startTime`, null);
                  formik.setFieldTouched(`startTime`, true);
                }
                setCustomErrors(false);
              }}
              onError={(e) => {
                formik.setFieldError(`startTime`, setMessage(e));
                formik.setFieldTouched(`startTime`, true);
              }}
              error={formik.errors.startTime && formik.touched.startTime}
              errorMsg={formik.errors.startTime}
              maxTime={
                formik.values?.endTime
                  ? dayjs(formik.values.endTime, "HH:mm")
                  : null
              } // Prevent
            />
          </Grid>
          <Grid xs={6}>
            <CustomTimePicker
              label={"End Time"}
              name={`endTime`}
              onError={(e) => {
                console.log(e, "e");
                formik.setFieldError(`endTime`, setMessage(e));
                formik.setFieldTouched(`endTime`, true);
              }}
              error={formik.errors.endTime && formik.touched.endTime}
              errorMsg={formik.errors.endTime}
              value={
                formik.values?.endTime ? dayjs(formik.values.endTime) : null
              }
              onChange={(e) => {
                setCustomErrors(false);
                if (e) {
                  formik.setFieldValue(`endTime`, dayjs(e));
                  formik.setFieldTouched(`endTime`, true);
                } else {
                  formik.setFieldValue(`endTime`, null);
                  formik.setFieldTouched(`endTime`, true);
                }
              }}
              minTime={
                formik.values?.startTime
                  ? dayjs(formik.values.startTime, "HH:mm")
                  : null
              } // Prevents selecting a time earlier than the Start Time
            />
          </Grid>
        </Grid>
      </MuiBox>

      <MuiBox sx={{ mt: 5, display: "flex", gap: 3, justifyContent: "center" }}>
        <Button type="reset" variant="darkButton" sx={{ maxWidth: "200px" }}>
          Cancel
        </Button>
        <Button type="submit" variant="lightButton" sx={{ maxWidth: "200px" }}>
          Apply
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default ScheduleFilterModal;
