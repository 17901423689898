// import { Typography } from "@mui/material";
// import { CustomLoader, TableContainer } from "components/atoms";
import { NoDataFound } from "components/atoms";
import React from "react";
import FullScreenImageModel from "components/molecules/FullScreenImageModel";

const HospitalGalleryTab = ({ imgArray = [] }) => {
  return (
    <div className="Hospital-User-page h-full w-full">
      <div className="mt-5">
        {imgArray.length > 0 ? (
          <div className="grid grid-cols-3 gap-4 mt-5">
            {imgArray.map((item) => (
              <div className="card" key={item?.id}>
                <div className="">
                  <div className="relative overflow-hidden rounded-md group/gallery">
                    <div className="overflow-hidden">
                      <FullScreenImageModel
                        imgSrc={item?.imageUrl}
                        fullScreen={true}
                        alt="gallery"
                        style={{ height: "300px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

export default HospitalGalleryTab;
