import { TabContext, TabPanel } from "@mui/lab";
import { Popover, Tab, Tabs } from "@mui/material";
import {
  CustomLoader,
  MuiBox,
  MuiTypography,
  NoDataFoundCard,
} from "components/atoms";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetAllNotification } from "store/middlewares/notifications";
import { useDispatch, useSelector } from "react-redux";

// import Calender from "assets/images/SVG/CalendarBlue.svg";
import Clock from "assets/images/SVG/clock white.svg";
const NotificationPopover = ({ anchorEl, handleClose, open, hospitalId }) => {
  const dispatch = useDispatch();
  const { notificationsItems, notificationsItemsLoading } = useSelector(
    (state) => state.me
  );

  // For notification popover's tabs
  const [notificationActiveTab, setNotificationActiveTab] = useState("1");
  const handleNotificationTab = (event, newValue) => {
    setNotificationActiveTab(newValue);
  };
  useEffect(() => {
    if (open) {
      dispatch(GetAllNotification({ id: hospitalId }));
    }
  }, [dispatch, open]);

  return (
    <Popover
      className="notification-dropdown"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <TabContext value={notificationActiveTab}>
        <MuiBox
          sx={{
            bgcolor: "#E8F0F3",
            p: { xs: "15px", sm: "28px" },
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <MuiBox sx={{ bgcolor: "#fff", borderRadius: "12px", pt: 1 }}>
            <Tabs
              centered
              value={notificationActiveTab}
              onChange={handleNotificationTab}
              className="notification-tabs"
            >
              <Tab label="Jobs" className="notification-tab" value="1" />
              <Tab label="Information" className="notification-tab" value="2" />
            </Tabs>
          </MuiBox>

          <TabPanel value="1" sx={{ p: 0 }}>
            <MuiBox
              sx={{
                mt: "28px",
                minHeight: "105px",
                maxHeight: "345px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                overflowY: "auto",
              }}
              className="no-scrollbar"
            >
              {notificationsItemsLoading ? (
                <MuiBox>
                  <CustomLoader />
                </MuiBox>
              ) : (
                <>
                  {notificationsItems &&
                    notificationsItems?.map((item) => (
                      <MuiBox
                        key={item.id}
                        // component={Link}
                        sx={{
                          borderRadius: "12px",
                          bgcolor: "#fff",
                          p: { xs: "8px", sm: "12px" },
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          borderLeft: "2px solid #0CA8DF",
                        }}
                      >
                        <MuiTypography
                          variant="baseFont"
                          color="text.blue"
                          fontWeight="bold"
                        >
                          {item.message}
                        </MuiTypography>
                      </MuiBox>
                    ))}
                  {notificationsItems?.length === 0 && (
                    <NoDataFoundCard width="6em" padding={1} />
                  )}
                </>
              )}
            </MuiBox>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0 }}>
            <MuiBox
              sx={{
                mt: "28px",
                minHeight: "105px",
                maxHeight: "345px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                overflowY: "auto",
              }}
              className="no-scrollbar"
            >
              <MuiBox
                component={Link}
                sx={{
                  borderRadius: "12px",
                  bgcolor: "#fff",
                  p: { xs: "8px", sm: "12px" },
                  display: "flex",
                  gap: "10px",
                }}
              >
                <MuiBox
                  sx={{
                    width: "43px",
                    height: "43px",
                    bgcolor: "#0A1E41",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: "0",
                  }}
                >
                  <img src={Clock} style={{ height: "24px", width: "24px" }} />
                </MuiBox>
                <MuiBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <MuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap-reverse",
                    }}
                  >
                    <MuiBox sx={{ maxWidth: "16em" }}>
                      <MuiTypography
                        fontSize="14px"
                        color="text.main"
                        fontWeight="bold"
                      >
                        Appointment Reminder
                      </MuiTypography>
                    </MuiBox>
                    <MuiTypography fontSize="14px" color="text.contrastText">
                      9:41 AM
                    </MuiTypography>
                  </MuiBox>
                  <MuiBox sx={{ maxWidth: "16em" }}>
                    <MuiTypography fontSize="14px" color="text.contrastText">
                      {
                        "Don't forget your check-up tomorrow at 10 AM with Dr. Smith. See you then!"
                      }
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            </MuiBox>
          </TabPanel>
        </MuiBox>
      </TabContext>
    </Popover>
  );
};

export default NotificationPopover;
