import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllHospitalDirectHire,
  GetAllProfessionalDirectHire,
} from "store/middlewares/directHire";

// isDeleteMeFulFilled
export const directHireSlice = createSlice({
  name: "directHire",
  initialState: {
    directHireList: null,
    directHireListLoading: false,
    directHireDetails: null,
    directHireDetailsLoading: false,
    transactionDetails: null,
    transactionDetailsLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // For get all hospital direct hire listings
      .addCase(GetAllHospitalDirectHire.pending, (state) => {
        state.directHireListLoading = true;
        state.directHireList = null;
      })
      .addCase(GetAllHospitalDirectHire.fulfilled, (state, action) => {
        state.directHireListLoading = false;
        state.directHireList = action.payload.response;
      })
      .addCase(GetAllHospitalDirectHire.rejected, (state) => {
        state.directHireListLoading = false;
        state.directHireList = null;
      })

      // For get all professional direct hire listings
      .addCase(GetAllProfessionalDirectHire.pending, (state) => {
        state.directHireListLoading = true;
        state.directHireList = null;
      })
      .addCase(GetAllProfessionalDirectHire.fulfilled, (state, action) => {
        state.directHireListLoading = false;
        state.directHireList = action.payload.response;
      })
      .addCase(GetAllProfessionalDirectHire.rejected, (state) => {
        state.directHireListLoading = false;
        state.directHireList = null;
      })

  },
});

export default directHireSlice.reducer;
