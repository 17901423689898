import { openToast } from "store/slices/common/toast.slice";
import { getAllTax, updateTax } from "store/services/Tax";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleAuthErrorAdmin } from "utils/handleAuthError";

export const GetAllTaxAction = createAsyncThunk(
  "tax/getTax",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getAllTax(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

export const UpdateTaxAction = createAsyncThunk(
  "tax/updateTax",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateTax(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);
