import { Button, Radio } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { MuiBox, MuiTypography } from "components/atoms";
// import InfoIcon from "assets/images/SVG/info.svg";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddBadgesForWebAction } from "store/middlewares/badges";
import { closeDrawer } from "store/slices/common/drawer.slice";

const ModalChoosePayment = ({ designationId }) => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("checkOut");
  const { items } = useSelector((state) => state.settings);
  const { me } = useSelector((state) => state.me);

  const handleClick = () => {
    if (paymentMethod === "wallet") {
      dispatch(
        AddBadgesForWebAction({ designationId, paymentMethod: paymentMethod })
      );
    } else {
      dispatch(closeDrawer());
    }
  };

  const paymentMethods = [
    { label: "Wallet", value: "wallet" },
    { label: "Card", value: "checkOut" },
  ];
  return (
    <MuiBox sx={{ p: { xs: "16px 10px", sm: "16px 32px" } }}>
      <Grid
        container
        rowSpacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ gap: 3 }}
      >
        <Grid item xs={12}>
          <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
            Choose Payment Option
          </MuiTypography>
          {/* <MuiBox
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "flex-start",
              mt: "8px",
            }}
          >
            <img
              src={InfoIcon}
              style={{
                height: "24px",
                width: "24px",
                objectFit: "contain",
              }}
            />
            <MuiTypography variant="baseFont" color="sliderText.light">
              The new badge may cost them a price. The cost of the badge may be
              subtracted on their next pay period.
            </MuiTypography>
          </MuiBox> */}
        </Grid>
        {paymentMethods?.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor:
                  item?.value === paymentMethod
                    ? "background.dark"
                    : "sliderText.light",
                borderRadius: "12px",
                p: 2,
              }}
              key={item.value}
            >
              <Radio
                name="payment"
                id="payment"
                checked={item?.value === paymentMethod}
                value={item?.value}
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
                sx={{ p: 0 }}
                // icon={<img src={UncheckedIcon} />}
                // checkedIcon={<img src={CheckedIcon} />}
              />
              <MuiBox
                sx={{ display: "flex", justifyContent: "space-between", pl: 2 }}
              >
                <MuiTypography fontWeight={600}>{item?.label}</MuiTypography>
                {item?.value === "wallet" && (
                  <MuiTypography fontWeight={600}>
                    {items?.currencySymbol}
                    {me?.totalBalance}
                  </MuiTypography>
                )}
              </MuiBox>
            </Grid>
          );
        })}
        <Grid item xs={12} sx={{ width: { xs: "100%", sm: "352px" } }}>
          <Button onClick={handleClick} variant="lightButton">
            Pay & Update
          </Button>
        </Grid>
      </Grid>
    </MuiBox>
  );
};

export default ModalChoosePayment;
