import { Typography } from "@mui/material";
import { CustomLoader, TableContainer } from "components/atoms";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOnGoingShiftListAction } from "store/middlewares/users/schedule";
import OnGoingShiftFilter from "../Filters/OnGoingShiftFilter";
import FilterPopoverProvider from "context/PopoverContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
function DashboardOnGoingShiftList() {
  const dispatch = useDispatch();
  const { shift, shiftLoading, shiftFilterProps } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(GetOnGoingShiftListAction(shiftFilterProps ?? null));
  }, [dispatch,shiftFilterProps]);

  // useEffect(() => {
  //   const time = shift?.startTime;
  //   console.log(dayjs(time).format("YYYY-MM-DD"));
  // }, [shift]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Hospital Name",
        accessorKey: "hospitalTimeSchedule.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (info) => <span className="capitalize">{info.getValue()}</span>,
      },
      {
        header: "Professional Name",
        accessorKey: "professionalTimeSchedule.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (info) => <span className="capitalize">{info.getValue()}</span>,
      },
      {
        header: "Time",
        accessorKey: "startTime",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const startTime = dayjs(row.original.startTime);
          const endTime = dayjs(row.original.endTime);
          return (
            <div className="flex flex-col">
              <Typography variant="p">
                {startTime.format("DD-MM-YYYY")} -{" "}
                {endTime.format("DD-MM-YYYY")}
              </Typography>
              <Typography variant="p">
                {startTime.local().format("hh:mm A")} -{" "}
                {endTime.local().format("hh:mm A")}
              </Typography>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="Hospital-User-page">
      {!shiftLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={shift ?? []}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              extraButton={
                <div className="flex">
                  <FilterPopoverProvider>
                    <OnGoingShiftFilter />
                  </FilterPopoverProvider>
                </div>
              }
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </div>
  );
}

export default DashboardOnGoingShiftList;
