import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDrawer } from "store/slices/common/drawer.slice";
import ConfirmModal from "../ConfirmModal";

import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
import CalenderIcon from "assets/images/SVG/plain calendar black.svg";
import dayjs from "dayjs";
import AddHolidaysModal from "../AddHolidaysModal";
import { DeleteHolidaysAction } from "store/middlewares/holidays";
import { generateTimeStamp } from "utils/generateTimeStamp";

const HolidayCard = ({ holiday }) => {
  const dispatch = useDispatch();
  const now = generateTimeStamp()
  return (
    <MuiBox
      sx={{
        bgcolor: "#fff",
        borderRadius: "12px",
        p: { xs: "10px", sm: "15px" },
        borderLeft: "3px solid #0CA8DF",
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flexBasis: "100%",
          wordBreak: "break-all",
        }}
      >
        <MuiTypography
          variant="baseFont"
          fontWeight="bold"
          color="text.blue"
          sx={{ overflowWrap: "break-word" }}
        >
          {holiday?.reason}
        </MuiTypography>
        <MuiBox sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={CalenderIcon}
            style={{
              height: "19px",
              width: "19px",
              objectFit: "contain",
            }}
          />
          <MuiTypography fontSize="14px" lineHeight="20px" fontWeight="bold">
            {dayjs(holiday?.startDate).format("MMM DD, YYYY")}{" "}
            {holiday?.endDate &&
              !dayjs(holiday?.startDate).isSame(dayjs(holiday?.endDate)) &&
              `- ${dayjs(holiday?.endDate).format("MMM DD, YYYY")}`}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      {!now.isAfter(holiday?.startDate) && (
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <IconButton
            sx={{
              p: "6px",
              width: "30px",
              height: "30px",
              borderRadius: "8px",
              bgcolor: "#E8F0F3",
            }}
            onClick={() =>
              dispatch(
                openDrawer({ content: <AddHolidaysModal holiday={holiday} /> })
              )
            }
          >
            <img src={EditIcon} style={{ width: "16px", height: "16px" }} />
          </IconButton>
          <IconButton
            sx={{
              p: "6px",
              width: "30px",
              height: "30px",
              borderRadius: "8px",
              bgcolor: "#E8F0F3",
            }}
            onClick={() =>
              dispatch(
                openDrawer({
                  content: (
                    <ConfirmModal
                      type="delete"
                      title="Delete Holiday"
                      message={
                        <>
                          Are you sure you want to delete your Holiday? <br />
                          This action is permanent and cannot be undone.
                        </>
                      }
                      btnText={"Delete Holiday"}
                      onClick={() =>
                        dispatch(DeleteHolidaysAction({ id: holiday.id }))
                      }
                    />
                  ),
                })
              )
            }
          >
            <img src={DeleteIcon} style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default HolidayCard;
