import React, { useEffect } from "react";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllCredentialSocialSecurityAction,
  ManageAllCredentialSocialSecurityAction,
} from "store/middlewares/credential";
import {
  AddButton,
  CustomLoader,
  DeleteButton,
  NoDataFound,
  PDFViewer,
} from "components/atoms";
import Swal from "sweetalert2";
import checkAccess from "utils/checkAccess";
import FullScreenImageModel from "components/molecules/FullScreenImageModel";
import { checkExtension } from "utils/checkExtension";

// import checkAccess from "../../../../assets/images/SVG/";

function SocialSecurity({ userId }) {
  const dispatch = useDispatch();
  const { socialSecurity, socialSecurityLoading } = useSelector(
    (state) => state.credential
  );

  useEffect(() => {
    dispatch(GetAllCredentialSocialSecurityAction({ id: userId }));
  }, [dispatch, userId]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">
            Social Security Number :
          </h6>
          {!socialSecurityLoading ? (
            <>
              {socialSecurity && socialSecurity.length > 0 ? (
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                  {socialSecurity.map((item) => (
                    <div className="card" key={item?.id}>
                      <div className="card-body px-0">
                        {item?.imageUrl &&
                          <div className="relative overflow-hidden rounded-md group/gallery">
                            <div
                              className={
                                checkExtension(item, "pdf")
                                  ? "overflow-y-scroll overflow-x-hidden max-h-[400px] w-auto"
                                  : "overflow-hidden"
                              }
                            >
                              {checkExtension(item, "pdf") ? (
                                <div className="h-[436px] w-auto">
                                  <PDFViewer file={item?.imageUrl} width={533} />
                                </div>
                              ) : (
                                <FullScreenImageModel
                                  imgSrc={item?.imageUrl}
                                  fullScreen={true}
                                  alt="SocialSecurity"
                                  style={{ height: "300px" }}
                                />
                              )}
                            </div>
                          </div>
                        }
                        <div className="grid grid-cols-2 mt-5 gap-4">
                          <h6 className="text-base">
                            <span className="font-bold">
                              {" "}
                              Social Security No. :{" "}
                            </span>
                            {item?.number}
                          </h6>
                        </div>

                        {checkAccess("update_credentials") && (
                          <div className="text-end mt-2">
                            {item.status !== "accepted" && (
                              <AddButton
                                title="Accept"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to accept this social security?",
                                    imageUrl: ApprovedSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialSocialSecurityAction(
                                          {
                                            id: item.id,
                                            status: "accepted",
                                          }
                                        )
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                            {item.status !== "rejected" && (
                              <DeleteButton
                                title="Reject"
                                style="ml-2"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to reject this social security?",
                                    imageUrl: RejectSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialSocialSecurityAction(
                                          {
                                            id: item.id,
                                            status: "rejected",
                                          }
                                        )
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SocialSecurity;
