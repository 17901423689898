import React from "react";
import blankImg from "assets/images/blank.png";

function UserInfoCard({
  imgSrc = blankImg,
  uName = "default",
  uEmail = "default@gmail.com",
}) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="flex items-center gap-3">
          <div className="rounded-full bg-slate-100 shrink-0 h-14 w-14">
            <img
              src={imgSrc}
              alt={uName}
              className="rounded-full h-full w-full object-cover"
            />
          </div>
          <div className="grow">
            <h6 className="mb-1 text-15">{uName}</h6>
            <a href={`mailto:${uEmail}`}>{uEmail}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfoCard;
