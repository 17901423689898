import { MuiBox, MuiTypography, StatusPill } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { FormateMileage } from "utils/FormateMileage";
import {
  applicationStatusText,
  credentialStatusColorObj,
  credentialStatusTextObj,
} from "utils/credentialStatus";
import FullScreenImageModel from "../FullScreenImageModel";

import Beg from "assets/images/SVG/beg.svg";
import Star from "assets/images/SVG/Star.svg";
import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import { useSelector } from "react-redux";
import {
  availablityStatus,
  availablityTextStatus,
} from "utils/professionalAvailablityColor";
// import PhoneIcon from "assets/images/SVG/Smartphone.svg";
const ProfessionalCard = ({
  description = true,
  to = "/hospital/direct-hire/details",
  user,
  application = false,
  miles = true,
}) => {
  const { dynamicMenu } = useSelector((state) => state.dynamicMenu);
  const isMessage = dynamicMenu.find(
    (item) => item.name === "message"
  )?.isVisible;

  return (
    <MuiBox
      component={Link}
      to={to}
      sx={{
        position: "relative",
        height: "100%",
        background: "#fff",
        borderRadius: "12px",
        p: { xs: 1, sm: 2 },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {["rejected", "accepted", "pending"].includes(
        user?.applicationStatus
      ) && (
        <MuiBox
          sx={{
            width: "fit-content",
            p: "3px 10px",
            borderRadius: "8px",
            bgcolor: credentialStatusColorObj[user?.applicationStatus],
            position: "absolute",
            top: "5px",
            right: "30px",
            zIndex: 1,
          }}
        >
          <MuiTypography fontSize="12px" fontWeight="bold" color="#fff">
            {application
              ? applicationStatusText[user?.applicationStatus]
              : credentialStatusTextObj[user?.applicationStatus]}
          </MuiTypography>
        </MuiBox>
      )}
      <MuiBox
        sx={{
          borderRadius: "9px",
          overflow: "hidden",
          height: "206px",
          position: "relative",
        }}
      >
        <FullScreenImageModel
          imgSrc={user?.profileImageUrl}
          alt={`${user?.name} ${user?.lastName}`}
        />
        <MuiBox
          sx={{
            width: "fit-content",
            position: "absolute",
            top: application ? "16px" : "8px",
            right: "8px",
          }}
        >
          <StatusPill
            status={availablityStatus[user?.isAvailable]}
            statusText={availablityTextStatus[user?.isAvailable]}
            isWeb={true}
          />
        </MuiBox>
      </MuiBox>
      <MuiBox>
        <MuiTypography color="text.main" variant="subtitle1" fontWeight="bold">
          {user?.name} {user?.lastName}
        </MuiTypography>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <MuiTypography
            color="text.contrastText"
            sx={{ fontSize: "14px", width: "10em", overflow: "hidden" }}
          >
            {application ? user?.jobTitle : user?.jobTitleName}
          </MuiTypography>
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MuiBox
                sx={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "6px",
                  bgcolor: "#E8F0F3",
                  p: "5px",
                }}
              >
                <img
                  src={Beg}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.main"
                fontWeight="bold"
                sx={{ fontSize: "14px" }}
              >
                {user?.totalExperience ? user?.totalExperience : 0} years
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
                bgcolor: "#FFF7E7",
                borderRadius: "6px",
                p: "5px",
              }}
            >
              <MuiBox sx={{ width: "18px", height: "18px" }}>
                <img
                  src={Star}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.contrastText"
                sx={{ fontSize: "14px" }}
              >
                {user?.reviewRatingCount
                  ? Number(user?.reviewRatingCount).toFixed(1)
                  : "0.0"}
                {user?.totalReviewCount > 0
                  ? `(${user?.totalReviewCount})`
                  : `(0)`}
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      {description && (
        <MuiBox>
          <MuiTypography fontSize="14px" lineHeight="20px" color="text.dark">
            {user?.about}
          </MuiTypography>
        </MuiBox>
      )}
      {miles && (
        <MuiBox
          sx={{
            bgcolor: "#CCEEFA",
            borderRadius: "12px",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            mt: "auto",
          }}
        >
          <MuiBox sx={{ display: "flex", gap: 2, width: "fit-content" }}>
            {/* <IconButton
              aria-label="phone"
              sx={{
                bgcolor: "darkButton.main",
                transition: "all 0.3s",
                borderRadius: "6px",
                overflow: "hidden",
                "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
              }}
            >
              <img src={PhoneIcon} />
            </IconButton> */}
            {isMessage && (
              <IconButton
                aria-label="phone"
                sx={{
                  bgcolor: "darkButton.main",
                  transition: "all 0.3s",
                  borderRadius: "6px",
                  overflow: "hidden",
                  "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
                }}
              >
                <img src={ChatIcon} />
              </IconButton>
            )}
          </MuiBox>
          <MuiBox
            sx={{
              width: "fit-content",
              bgcolor: "#FFFFFF",
              p: "4px 6px",
              borderRadius: "12px",
            }}
          >
            <MuiTypography
              color="text.dark"
              fontWeight="bold"
              sx={{ fontSize: "14px" }}
            >
              {FormateMileage(user?.mileage)}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default ProfessionalCard;
