import React from "react";
import FullScreenImageModel from "../FullScreenImageModel";

function ModalBadgeDetail({ row }) {
  console.log(row);
  
  return (
    <>
      <div className="flex justify-center">
        <div className="size-36">
          <FullScreenImageModel imgSrc={row?.badgesDesignation?.imageUrl} fullScreen="true" />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-center text-base">
          <span className="font-bold text-16">Name :</span> {row?.badgesDesignation?.name}
        </p>
        <p className="text-center text-base">
          <span className="font-bold text-16">Price : </span> ${row?.price}
        </p>
      </div>
    </>
  );
}

export default ModalBadgeDetail;
