import React, { useEffect } from "react";
import { GetAllCredentialAddressAction } from "store/middlewares/credential";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomLoader,
  NoDataFound,
  PhysicalAddressItem,
} from "components/atoms";

function PhysicalAddress({ userId }) {
  const dispatch = useDispatch();
  const {
    credential: { physicalAddress, physicalAddressLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(GetAllCredentialAddressAction({ id: userId }));
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">
            Physical Address :
          </h6>
          {!physicalAddressLoading ? (
            <>
              {physicalAddress?.length > 0 ? (
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                  {physicalAddress?.map((address) => (
                    <PhysicalAddressItem address={address} key={address?.id} />
                  ))}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PhysicalAddress;
