import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import {
  getAccess,
  deleteAccess,
  updateAccess,
  addAccess,
} from "store/services/access";
import { openToast } from "store/slices/common/toast.slice";
import {  handleAuthErrorAdmin } from "utils/handleAuthError";

//Get All Cities
export const GetAllAccessAction = createAsyncThunk(
  "access/getAllAccess",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await getAccess();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//Delete City
export const DeleteAccessAction = createAsyncThunk(
  "access/deleteAccess",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteAccess(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//Update City
export const UpdateAccessAction = createAsyncThunk(
  "access/updateAccess",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateAccess(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);

//Add City
export const AddAccessAction = createAsyncThunk(
  "access/addAccess",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addAccess(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      dispatch(handleAuthErrorAdmin({ status: error?.response?.status }));
      return rejectWithValue(error);
    }
  }
);
