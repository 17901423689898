export const applicationStatusColor = {
  applied: "#0A1E41",
  rejected: "#FF5C5C",
  cancel: "#FF5C5C",
  approved: "#0A1E41",
};

export const statusText = {
  apply: "Apply",
  applied: "Withdraw Request",
  rejected: "Rejected",
  approved: "Approved",
  cancel: "Cancelled",
};
