import { MuiBox } from "components/atoms";
import React from "react";

const MapModal = () => {
  return (
    <MuiBox sx={{ height: "100%" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.793217504653!2d70.79106457589694!3d22.285821543419015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cbab3487cecf%3A0x44f6f39cb14aeb2c!2sAlmighty%20Infotech!5e0!3m2!1sen!2sin!4v1731925559115!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </MuiBox>
  );
};

export default MapModal;
